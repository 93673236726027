import axios from "axios";
import fakeData from "../../mocked-data/fakeData";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import IJob from "../../models/IJob";
import authHeaders from "../auth/authHeaders";

const getRelatedPositions = async (constants: ConstantsConfig, id: string): Promise<IJob[]> => {
  if (constants.useMockData) return fakeData.fakeRandomJobs;

  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/positions/${id}/related`, { headers: authHeaders(true) });
  return res.data;
};

export default getRelatedPositions;
