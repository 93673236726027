import axios from "axios";
import fakeData from "../../mocked-data/fakeData";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import authHeaders from "../auth/authHeaders";

const getPositionLogo = async (constants: ConstantsConfig, id: string): Promise<string> => {
  if (constants.useMockData) return fakeData.fakeRandomJobs.find(e => e.id === id)?.photo || "";

  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/positions/${id}/logo`, { headers: authHeaders(true) });
  return res.data;
};

export default getPositionLogo;
