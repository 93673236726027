import React, { FunctionComponent, ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import {
    Link
} from "react-router-dom";
import ButtonDefault from "../components/button/ButtonDefault";
import Card from "../components/card/Card";
import Icon from "../components/icon/Icon";
import TextField from "../components/input/TextField";
import HeaderLogo from "../components/logo/HeaderLogo";
import Modal from "../components/modal/Modal";
import { showModal } from "../components/modal/ModalListener";
import authService from "../services/auth";
import "./login.scss";

interface LoginState {
    email: string;
    password: string;
    confirmPassword: string;
    loading: boolean;
}

const ResetPassPage: FunctionComponent = (): ReactElement => {
    const [state, setState] = useState<LoginState>({ email: "", password: "", confirmPassword: "", loading: false });
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})");
    const history = useHistory();

    const change = (e: React.FormEvent) => {
        e.preventDefault();
        if (validPasswords(state.password, state.confirmPassword)) {
            setState({ ...state, loading: true });
            authService
                .changePassword(state.email, state.password, state.confirmPassword)
                .then(() => {
                    showModal(<Modal small>{successModal()}</Modal>)
                    setTimeout(() => {
                        setState({ ...state, loading: false });
                        history.push("/login")
                    }, 3000);
                })
                .catch(err => {
                    console.log(err);
                    showModal(<Modal small>{errorModal("error.reset")}</Modal>)
                    setState({ ...state, loading: false });
                })
        }
    };

    const validPasswords = (password: string, confirmPassword: string) => {
        let valid = false;
        if (password === confirmPassword) {
            if (strongRegex.test(password)) {
                valid = true;
            } else {
                showModal(<Modal small>{errorModal("error.notValidPassword")}</Modal>)
            }
        } else {
            showModal(<Modal small>{errorModal("error.passNotConfirmed")}</Modal>)
        }
        return valid;
    }

    const errorModal = (message: string) => {
        return (
            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                <FormattedMessage id={message} />
                <Icon name="exclamation-circle" big style={{ color: 'red', fontSize: '3rem', textAlign: 'center', margin: '2rem' }} />
            </div>
        )
    }

    const successModal = () => {
        return (
            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                <FormattedMessage id="label.reset-success" />
                <Icon name="check-circle" big style={{ color: '#52c41a', fontSize: '3rem', textAlign: 'center', margin: '2rem' }} />
            </div>
        )
    }

    return (
        <div className="login-background">
            <Card loginCard>
                {/* TODO: Refactorize logo components or add a new one "LoginLogo" */}
                <div className="login-logo-container">
                    <HeaderLogo login />
                </div>
                <form onSubmit={change} autoComplete="off" className="form-container">
                    <TextField
                        label="label.email"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e })}
                        type="email"
                        icon="at"
                    />
                    <TextField
                        label="label.password"
                        value={state.password}
                        onChange={e => setState({ ...state, password: e })}
                        type="password"
                        icon="lock"
                    />
                    <TextField
                        label="label.confirmpassword"
                        value={state.confirmPassword}
                        onChange={e => setState({ ...state, confirmPassword: e })}
                        type="password"
                        icon="lock"

                    />
                    <div className="login-actions">
                        <ButtonDefault
                            disabled={state.loading}
                            loading={state.loading}
                            onClick={change}
                            text="action.update"
                            color="secondary"
                            size="standard"
                            style={{ 'marginBottom': '0.5rem' }}
                        />
                        <Link to="/login"><FormattedMessage id="action.sign-in" /></Link>
                    </div>
                </form>
            </Card>
        </div>
    );
};

export default ResetPassPage;
