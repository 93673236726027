import axios from "axios";
import fakeData from "../../mocked-data/fakeData";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import IExperience from "../../models/IExperience";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";

const getExperiences = async (constants: ConstantsConfig, nudoss: string | number): Promise<IExperience[]> => {
  if (constants.useMockData) return fakeData.fakeRandomExperiences;
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/experiences?role=${userRole()}`, { headers: authHeaders() });
  return res.data;
};

export default getExperiences;
