import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { UserContext } from "../../context";
import useConfig from "../../custom-hooks/useConfig";
import employeeService from "../../services/employees";
import deletePosition from "../../services/position/deletePosition";
import savePosition from "../../services/position/savePosition";
import staffService from "../../services/staff";
import toastService from "../../services/toasts";
import throwSuccess from "../../services/toasts/throwSuccess";
import ButtonDefault from "../button/ButtonDefault";
import JobHeader from "../card-header/jobheader/JobHeader";
import Icon from "../icon/Icon";
import Loading from "../loading/Loading";
import AdditionalInfo from "./AdditionalInfo";
import "./jobCard.scss";
import SkillsInfo from "./SkillsInfo";

interface JobCardProps {
  id: string;
}

const JobCard: FunctionComponent<JobCardProps> = ({ id }): ReactElement => {
  const [state, setstate] = useState({ loading: false, isApplied: false, completedProfile: { complete: true } });
  const { constants } = useConfig();
  const { user } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (user) {
      setstate({ ...state, loading: true });

      const p1 = staffService.getPositionApplied(constants, id, user?.nudoss);
      const p2 = employeeService.getProfileCompleted(constants, user?.nudoss);

      Promise.all([p1, p2])
        .then(values => {
          setstate({ ...state, loading: false, isApplied: values[0], completedProfile: values[1] });
        })
        .catch(err => {
          console.log(err);
          setstate({ ...state, loading: false });
        });
      deletePosition();
    }
  }, [id, user]);

  const apply = () => {
    setstate({ ...state, loading: true });
    if (user) {
      staffService
        .postApplyPosition(constants, user.nudoss, id)
        .then(() => {
          console.log("Application successfull!");
          throwSuccess("label.candidatura-success");
          setstate({ ...state, loading: false, isApplied: true });
        })
        .catch(err => {
          console.log(err);
          setstate({ ...state, loading: false });
        });
    } else {
      history.push({
        pathname: "/login",
        state: { from: `jobs/${id}` }
      });
      savePosition(id);
    }
  };

  const cancel = () => {
    setstate({ ...state, loading: true });
    if (user) {
      staffService
        .deleteAppliedPosition(constants, user.nudoss, id)
        .then(() => {
          console.log("Application cancelled sucessfully");
          throwSuccess("label.del-candidatura-success");
          setstate({ ...state, loading: false, isApplied: false });
        })
        .catch(err => {
          console.log('err:', err);
          toastService.throwError(err.response.data.description);
          setstate({ ...state, loading: false });
        });
    } else {
      history.push({
        pathname: "/login",
        state: { from: `jobs/${id}` }
      })
    }
  };

  return (
    <div style={{ padding: '1.5rem' }}>
      <JobHeader idPosition={id} />
      {constants.employeeSkillsModuleEnabled &&
        <div className="job-container">
          <SkillsInfo idPosition={id} requiredSkills />
          {/* <AdditionalInfo idPosition={id} /> */}
          <SkillsInfo idPosition={id} />
        </div>}

      <div className="job-container">
        {/* <SkillsInfo idPosition={id} /> */}
        <AdditionalInfo idPosition={id} />
        <div className="buttons-container">
          {state.loading ? (
            <Loading />
          ) : (
            <React.Fragment>
              <ButtonDefault
                text={state.isApplied ? "action.cancel" : "action.apply"}
                color={state.isApplied ? "secondary" : "primary"}
                size="standard"
                disabled={!state.completedProfile.complete && !state.isApplied}
                onClick={state.isApplied ? cancel : apply}
              />
              {!state.completedProfile.complete && <p className="text-standard text-bold text-medium-size" style={{ display: 'flex', alignItems: 'baseline', color: '#c42030' }}>
                <Icon name={"user-shield"} withoutContainer />
                <a href="/hr-jobsite/my-profile-edit"
                  style={{ color: "#c42030" }}>
                  <FormattedMessage id={"label.cantapplied"} />
                </a>
              </p>}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobCard;
