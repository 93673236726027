import axios from "axios";
import constants from '../../constants';
import authHeaders from "../auth/authHeaders";

const getCities = async (id: string): Promise<string[]> => {
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/rules/itProvinces/${id}/cities`, { headers: authHeaders(true) });
  return res.data;
};

export default getCities;
