import React, { FunctionComponent, useEffect, useState } from "react";
import Icon from "../icon/Icon";
import { FormattedMessage } from "react-intl";
import useConfig from "../../custom-hooks/useConfig";
import positionService from "../../services/position";

interface SelectedPositionProps {
  isApplied?: boolean;
}

const SelectedPosition: FunctionComponent<SelectedPositionProps> = ({ isApplied }) => {
  const { constants } = useConfig();
  const [state, setState] = useState(0);

  return (
      <div style={{fontSize: '20px', position: 'absolute', top: '15px', right: '20px', color: '#018240'}}>
          {isApplied &&  <i className="far fa-check-circle"></i>}
      </div>
    
  );
};

export default SelectedPosition;
