/* eslint-disable no-restricted-globals */
import VIEW_SIZES from "./viewSizes";

const isDesktopView = (width: number): boolean => {
  if (width) {
    return width > VIEW_SIZES.TABLET;
  }

  const currWidth = screen.width;
  return currWidth > VIEW_SIZES.TABLET;
};

export default isDesktopView;
