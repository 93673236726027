import classnames from "classnames";
import React, { FunctionComponent, ReactElement, useContext } from "react";
import { useHistory } from "react-router";
import { WindowSizeContext } from "../../context";
import useSecurity from "../../custom-hooks/useSecurity";
import useUser from "../../custom-hooks/useUser";
import ButtonDefault from "../button/ButtonDefault";
import HeaderLogo from "../logo/HeaderLogo";
import "./toolbar.scss";
import UserCard from "./UserCard";

const Toolbar: FunctionComponent = (): ReactElement => {
  const history = useHistory();
  const { user } = useUser();
  const { isAuth } = useSecurity();
  const { isMobile } = useContext(WindowSizeContext);

  const handleAuthentication = () => {
    if (user && isAuth) {
      history.push("/my-submissions");
    } else {
      history.push("/login");
    }
  };

  return (
    <div className={classnames("card-header")}>
      <div className="header-left-section">
        <HeaderLogo />
      </div>
      <div className="header-links-container">
        {!isMobile && <ButtonDefault color="toolbar" text="label.explore-jobs" onClick={() => history.push("/feed")} size="small" />}
        {user && <ButtonDefault color="toolbar" text="label.my-submissions" onClick={handleAuthentication} size="small" />}
      </div>

      <div className="header-icons-container">
        {/* <div className="header-icons-sub-container">
          <Icon headerType light name="search" />
          <Icon headerType light name="bell" /> 
          </div> */}
        <UserCard nudoss={user ? user.nudoss : ""} label={user ? user.label : undefined} />
      </div>
    </div>
  );
};

Toolbar.defaultProps = {
  children: <React.Fragment />,
};

export default Toolbar;
