import axios from "axios";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import userRole from "../auth/userRole";
import storageService from "../storage";

const downloadBlob = (blob: any) => {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "cv";
  link.click();
  link.remove();
};

const getStaffCV = async (constants: ConstantsConfig, nudoss: string | number) => {
  // if (constants.useMockData) return "cv";

  const token = storageService.getFromSession(storageService.sessionKeys.AUTH_TOKEN_KEY);
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/cv?role=${userRole()}`, {
    headers: { Authorization: `Bearer ${token}` },
    responseType: "blob",
  });
  downloadBlob(res.data);
};

export default getStaffCV;
