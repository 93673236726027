import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import IJob from "../../models/IJob";
import positionService from "../../services/position";
import useConfig from "../../custom-hooks/useConfig";
import { FormattedMessage } from "react-intl";
import Loading from "../loading/Loading";
import JobListItem from "../JobListItem/JobListItem";

interface RelatedJobsListProps {
  idPosition?: string;
}

interface RelatedJobsListState {
  loading: boolean;
  relatedJobs: IJob[];
}

const RelatedJobsList: FunctionComponent<RelatedJobsListProps> = ({ idPosition }): ReactElement => {
  const { constants } = useConfig();
  const [state, setState] = useState<RelatedJobsListState>({ loading: false, relatedJobs: [] });

  useEffect(() => {
    if (idPosition) {
      setState({ ...state, loading: true });
      positionService
        .getRelatedPositions(constants, idPosition)
        .then(jobs => {
          setState({ loading: false, relatedJobs: jobs });
        })
        .catch((err) => {
          setState({ ...state, loading: false });
        })
    }
  }, [idPosition]);

  return (
    <div className="standard-small-container">
      <div className="joblist-title">
        <FormattedMessage id="label.similar-jobs" />
      </div>
      <div className="joblist-container">
        {state.loading
          ? <Loading />
          : state.relatedJobs.map((j: IJob) => <JobListItem {...j} />)
        }
      </div>
    </div>
  );
};

export default RelatedJobsList;
