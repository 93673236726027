/* global sessionStorage */
import TOPICS from './topics';
import constants from '../constants';
import storageService from "./storage";

export const DEFAULT_LANGUAGE = constants.SUPPORTED_LANGUAGES[0];

// a protective measure against the corruption of the language stored in the session
export const verifyLanguage = () => {
  const language = storageService.getFromSession(storageService.sessionKeys.LANGUAGE_KEY);
  if (language === undefined || language === null) {
    storageService.saveToSession(storageService.sessionKeys.LANGUAGE_KEY, DEFAULT_LANGUAGE);
  }
};

export const getLanguage = () => storageService.getFromSession(storageService.sessionKeys.LANGUAGE_KEY) || DEFAULT_LANGUAGE;

export const setLanguage = (language : string) => {
  storageService.saveToSession(storageService.sessionKeys.LANGUAGE_KEY, language);
  TOPICS.LANGUAGE_TOPIC.next(language);
};