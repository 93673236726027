import deleteSkill from "./deleteSkill";
import getSkill from "./getSkill";
import getSkills from "./getSkills";
import postSkill from "./postSkill";
import updateSkill from "./updateSkill";

const skillsService = {
  getSkills,
  getSkill,
  postSkill,
  updateSkill,
  deleteSkill,
};

export default skillsService;
