export interface ConstantsConfig {
  useMockData: boolean,
  SUPPORTED_LANGUAGES: string[],
  isLanguageSelectorEnabled: boolean,
  showMoreTextButtonEnabled: boolean,
  REACT_APP_WS_URL: string,
  REACT_APP_WS_REDIRECT: string,
  relatedPositionsModuleEnabled: boolean,
  employeeSkillsModuleEnabled: boolean,
  GOOGLE_AUTH_URL: string,
  LINKEDIN_AUTH_URL: string,
  MAX_CV_SIZE: string
}

export const defaultConfig: ConstantsConfig = {
  useMockData: false,
  SUPPORTED_LANGUAGES: ["it"],
  isLanguageSelectorEnabled: false,
  showMoreTextButtonEnabled: true,
  relatedPositionsModuleEnabled: false,
  employeeSkillsModuleEnabled: false,
  REACT_APP_WS_URL: `${process.env.REACT_APP_WS_URL}`,
  REACT_APP_WS_REDIRECT: `${process.env.REACT_APP_WS_REDIRECT}`,
  GOOGLE_AUTH_URL: `${process.env.REACT_APP_WS_URL}/hr-auth/oauth2/authorize/google?redirect_uri=${process.env.REACT_APP_WS_REDIRECT}/hr-jobsite/oauth2/redirect`,
  LINKEDIN_AUTH_URL: `${process.env.REACT_APP_WS_URL}/hr-auth/oauth2/authorize/linkedin?redirect_uri=${process.env.REACT_APP_WS_REDIRECT}/hr-jobsite/oauth2/redirect`,
  MAX_CV_SIZE: "2",
  //GOOGLE_AUTH_URL: "https://jobsiteqa.pam.local.com/hr-auth/oauth2/authorize/google?redirect_uri=http://172.16.20.77:8180/hr-jobsite/oauth2/redirect",
  //LINKEDIN_AUTH_URL: "http://172.16.20.77:8180/hr-auth/oauth2/authorize/linkedin?redirect_uri=http://172.16.20.77:8180/hr-jobsite/oauth2/redirect"
  //LINKEDIN_AUTH_URL: "http://172.16.23.111:8180/hr-auth/oauth2/authorize/linkedin?redirect_uri=http://172.16.23.111:8180/hr-jobsite/oauth2/redirect"
  //GOOGLE_AUTH_URL: "https://lavoraconnoi.gruppopam.it/hr-auth/oauth2/authorize/google?redirect_uri=https://lavoraconnoi.gruppopam.it/hr-jobsite/oauth2/redirect",
  //LINKEDIN_AUTH_URL: "https://lavoraconnoi.gruppopam.it/hr-auth/oauth2/authorize/linkedin?redirect_uri=https://lavoraconnoi.gruppopam.it/hr-jobsite/oauth2/redirect"
};
