import deleteStaffCV from "./deleteStaffCV";
import deleteStaffPhoto from "./deleteStaffPhoto";
import getStaffCV from "./getStaffCV";
import getStaffPhoto from "./getStaffPhoto";
import updateStaffCV from "./updateStaffCV";
import updateStaffPhoto from "./UpdateStaffPhoto";

const staffDocumentService = {
  getStaffCV,
  getStaffPhoto,
  updateStaffCV,
  updateStaffPhoto,
  deleteStaffPhoto,
  deleteStaffCV
};

export default staffDocumentService;
