/* eslint-disable @typescript-eslint/no-unused-vars */
/* global WS_URL */
import APP_CONFIG from "./external_config";
import { ConstantsConfig, defaultConfig } from "./models/ConstantsConfig";

const config: ConstantsConfig = {
  ...defaultConfig,
  ...APP_CONFIG()
};

export default config;

