import axios from "axios";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";
import storageService from "../storage";

const updateStaffPhoto = async (constants: ConstantsConfig, nudoss: string | number, data: any): Promise<string> => {
  if (constants.useMockData) return data;
  const userKey = storageService.getFromSession(storageService.sessionKeys.USER_KEY);

  const formData = new FormData();
  formData.append("file", data);

  const res = await axios.post(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/photo?role=${userRole()}`, formData, { headers: authHeaders() });
  return res.data;
};

export default updateStaffPhoto;
