import React, { FunctionComponent, ReactElement } from "react";
import FeedLayout from "../components/layout/FeedLayout";
import JobCard from "../components/job-card/JobCard";
import { useParams } from "react-router";
import RelatedJobsList from "../components/RelatedJobsList/RelatedJobsList";
import useConfig from "../custom-hooks/useConfig";

const JobPage: FunctionComponent = (): ReactElement => {
  const { id } = useParams();
  const { constants } = useConfig();

  return (
    <FeedLayout>
      <div>
        <JobCard id={id} />
        {constants.relatedPositionsModuleEnabled && <RelatedJobsList idPosition={id} />}
      </div>
    </FeedLayout>
  );
};

export default JobPage;
