import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import authService from "../auth";

const ChangePassHandler = () => {
  let location = useLocation();
  const token = getUrlParameter("token", location);

  if (token) {
    if (authService.validateToken("passValidation", token)) {
      console.log("VALIDATION " + authService.validateToken(token))
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: "/signup",
            state: {
              from: location,
            },
          }}
        />
      );
    }
  } else {
    return (
      <Redirect
        to={{
          pathname: "/signup",
          state: {
            from: location,
          },
        }}
      />
    );
  }
};

const getUrlParameter = (name, locations) => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");

  var results = regex.exec(locations.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export default ChangePassHandler;
