import axios from "axios";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";

const updateUserProfile = async (constants: ConstantsConfig, nudoss: string | number, data: Object): Promise<Object> => {
  if (constants.useMockData) return data;

  const res = await axios.put(`${constants.REACT_APP_WS_URL}/hr-talent/employees/${nudoss}?role=${userRole()}`, data, { headers: authHeaders() });
  return res.data;
};

export default updateUserProfile;
