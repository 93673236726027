import React, { FunctionComponent, ReactElement } from "react";
import ProfileCard from "../components/ProfileCard/ProfileCard";
import CustomLayout from "../components/layout/CustomLayout";
import { useParams } from "react-router";
import MySubmissionsJobList from "../components/MySubmissionsJobList/MySubmissionsJobList";

const ProfilePage: FunctionComponent = (): ReactElement => {
  const { id } = useParams();

  return (
    <CustomLayout>
      <div className="cards-container">
        <ProfileCard nudoss={id} />
        <MySubmissionsJobList />
      </div>
    </CustomLayout>
  );
};

export default ProfilePage;
