import React, { Fragment, useEffect, FunctionComponent, ReactElement } from "react";
import Icon from "../icon/Icon";
import "./snackBarToast.scss";
import classnames from "classnames";
import { AppNotification } from "../../models/AppNotification";
import { FormattedMessage } from "react-intl";

interface SnackBarToastProps {
  open: boolean;
  onClose: Function;
  notification?: AppNotification;
}

const SnackBarToast: FunctionComponent<SnackBarToastProps> = ({ open, onClose, notification }): ReactElement => {
  useEffect(() => {
    const timeout = setTimeout(() => onClose(), 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  return (
    <div className={classnames("snackbar-container", `${notification?.variant}`, { "snackbar-show": open })}>
      {notification?.message ? (
        <p className="snackbar-message">
          <FormattedMessage id={notification.message} />
        </p>
      ) : (
        <React.Fragment />
      )}
      <Icon onClick={() => onClose()} name="times" light />
    </div>
  );
};

SnackBarToast.defaultProps = {
  open: false,
  onClose: () => {},
};

export default SnackBarToast;
