import IUser from "../models/IUser";
import IJob from "../models/IJob";
import IExperience from "../models/IExperience";
import IEducation from "../models/IEducation";
import ISkill from "../models/ISkill";
import generateFakeUser from "./generateFakeUser";
import generateFakeJob from "./generateFakeJob";
import generateFakeExperience from "./generateFakeExperience";
import generateFakeEducation from "./generateFakeEducation";
import generateFakeSkill from "./generateFakeSkill";
import fakeUser from "./fakeUser";

interface FakeDataStruct {
  fakeRandomUsers: IUser[];
  fakeRandomJobs: IJob[];
  fakeRandomExperiences: IExperience[];
  fakeRandomEducations: IEducation[];
  fakeRandomSkills: ISkill[];
  fakeRandomSubmissions: IJob[];
}

// @ts-ignore
const fakeData: FakeDataStruct = {
  fakeRandomUsers: [fakeUser, ...[1, 2, 3, 4, 5, 6].map(() => generateFakeUser())],
  fakeRandomJobs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(() => generateFakeJob()),
  fakeRandomExperiences: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(() => generateFakeExperience()),
  fakeRandomEducations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(() => generateFakeEducation()),
  fakeRandomSkills: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(() => generateFakeSkill()),
  fakeRandomSubmissions: [1, 2, 3].map(() => generateFakeJob())
};

export default fakeData;
