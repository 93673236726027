import React, { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import useConfig from "../../custom-hooks/useConfig";
import employeeService from "../employees";
import getProfileCompleted from "../employees/getProfileCompleted";
import storageService from "../storage";

const OAuth2RedirectHandler = () => {
  const [loading, setLoading] = useState(true);
  const [path, setPath] = useState("/");
  let location = useLocation();
  const { constants } = useConfig();
  const token = getUrlParameter("token", location);
  const error = getUrlParameter("error", location);


  if (token) {
    storageService.saveToSession(storageService.sessionKeys.AUTH_TOKEN_KEY, token);
    employeeService.getMyInfo(constants)
      .then((u) => {
        if (u.nudoss) {
          getProfileCompleted(constants, u.nudoss)
            .then((data) => {
              if (!data.complete) {
                setPath("/my-profile-edit");
              }
              setLoading(false);
            })
            .catch(e => console.log(e));
        }
      })
      .catch(e => console.log(e));
    if (loading) {
      return (<div style={{ height: "100vh", display: "flex" }}>
        <Loading />
      </div>);
    }
    else {
      return (<Redirect
        to={{
          pathname: path,
          state: { from: location },
        }}
      />);
    }
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: {
            from: location,
            error: error,
          },
        }}
      />
    );
  }
};

const getUrlParameter = (name, locations) => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");

  var results = regex.exec(locations.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export default OAuth2RedirectHandler;
