import React, { ReactElement, FunctionComponent } from "react";
import classnames from "classnames";
import "./card.scss";

interface CardProps {
  fullWidth?: boolean;
  fullHeight?: boolean;
  fitContent?: boolean;
  loginCard?: boolean;
  contentCard?: boolean;
  children?: ReactElement | ReactElement[];
  size?: "small";
  alignHeader?: boolean;
  backgroundCard?: boolean;
  customCard?: boolean;
  feedCard?: boolean;
  type?: "login";
}

const Card: FunctionComponent<CardProps> = (props): ReactElement => {
  return (
    <div className={classnames("card-main", { "login-card": props.loginCard }, { "small-card": props.size }, { "background-card": props.backgroundCard }, { "custom-card": props.customCard }, { "feed-card": props.feedCard })}>{props.children}</div>
  );
};

export default Card;
