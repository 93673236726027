import React, { FunctionComponent, ReactElement, useRef } from "react";
import BackdropListener from "../backdrop/BackdropListener";
import Card from "../card/Card";
import FootNote from "../foot-note/FootNote";
import ModalListener from "../modal/ModalListener";
import ScrollToTop from "../scroll-to-top/ScrollToTop";
import Toolbar from "../toolbar/Toolbar";
interface CustomLayoutProps {
  usesRefToScroll?: boolean,
}

const CustomLayout: FunctionComponent<CustomLayoutProps> = (props): ReactElement => {
  const scrollRef = useRef(null);
  return (
    <React.Fragment>
      <ModalListener />
      <BackdropListener>
        <div className="custom-background">
          <Toolbar />
          <Card customCard >
            <div ref={props.usesRefToScroll ? scrollRef : null} style={{ height: "100%" }}>
              {props.usesRefToScroll && <ScrollToTop scrollRef={scrollRef} />}
              {props.children}
            </div>
          </Card>
          <FootNote email="servizioclienti@gruppopam.it" />
        </div>
      </BackdropListener>
    </React.Fragment>
  );
};

CustomLayout.defaultProps = {
  usesRefToScroll: false,
};

export default CustomLayout;
