const fileNames: string[] = ["actions-messages", "error-messages", "fields-messages"];

const combineLanguageMessages = async (lang: string , messagesList: string[]) => {
  const filesPromise: Promise<any>[] = messagesList.map(async e => {
    const langModule = await import(`./${lang}/${e}-${lang}`);
    return langModule.default;
  });
  return Promise.all([...filesPromise]).then(files =>
    files.reduce((acc, curr) => Object.assign({}, acc, curr), {}));
};

export const messages = (lang: string): Promise<any> => {
  return combineLanguageMessages(lang, fileNames);
};
