import React, { FunctionComponent, ReactElement, useState, useEffect, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import useConfig from "../../custom-hooks/useConfig";
import positionService from "../../services/position";
import IJob from "../../models/IJob";
import Subtitle from "../titles/Subtitle";

interface AdditionalInfoProps {
  idPosition: string;
}

interface AdditionalInfoState {
  loading: boolean;
  job: IJob;
}

const initialState: AdditionalInfoState = {
  loading: false,
  job: {
    areaLabel: "",
    id: "",
    name: "",
    description: "",
    company: "",
    companyLabel: "",
    ruleSystem: "",
    location: "",
    country: "",
    countryLabel: "",
    position: "",
    positionLabel: "",
    job: "",
    jobLabel: "",
    published: "",
    date: "",
    minSalary: 0,
    maxSalary: 0,
    currency: "",
    applicants: 0,
    startDate: "",
    endDate: "",
    photo: "",
    typeTime: ""
  },
};

const AdditionalInfo: FunctionComponent<AdditionalInfoProps> = ({ idPosition }): ReactElement => {
  const [state, setState] = useState<AdditionalInfoState>(initialState);
  const { constants } = useConfig();

  useEffect(() => {
    setState({ ...state, loading: true });
    positionService
      .getPositionDetails(constants, idPosition)
      .then((newJob: IJob) => {
        setState({ ...state, loading: false, job: newJob });
      })
      .catch(err => {
        console.log(err);
        setState({ ...state, loading: false });
      });
  }, [idPosition]);

  return (
    <React.Fragment>
      {state.job.responsability && state.job.maxSalary && state.job.minSalary &&  
      <div className="adittional-info">
        <Subtitle label="label.additional-information"/>
        <div className="adittional-info-container">
          {state.job.responsability && (
            <div className="text-double">
              <p className="text-standard">
                <FormattedMessage id="label.responsability-level" />:
              </p>
              <p className="text-standard text-grey">{state.job.responsability}</p>
            </div>
          )}

          {state.job.minSalary > 0 && (
            <div className="text-double">
              <p className="text-standard">
                <FormattedMessage id="label.min-salary" />:
              </p>
              <p className="text-standard text-grey">{state.job.minSalary}</p>
            </div>
          )}

          {state.job.maxSalary > 0 && (
            <div className="text-double">
              <p className="text-standard">
                <FormattedMessage id="label.max-salary" />:
              </p>
              <p className="text-standard text-grey">{state.job.maxSalary}</p>
            </div>
          )}
        </div>
      </div>}
    </React.Fragment> 

  );
};

export default AdditionalInfo;
