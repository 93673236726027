import axios from "axios";
import constants from '../../constants';
import IInterest from "../../models/IInterest";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";

const getUserInterest = async (nudoss: string | number): Promise<IInterest[]> => {
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/interests?role=${userRole()}`, { headers: authHeaders() });
  return res.data;
};

export default getUserInterest;
