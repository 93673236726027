import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import useConfig from "../../../custom-hooks/useConfig";
import IJob from "../../../models/IJob";
import positionService from "../../../services/position";
import CompanyLogo from "../../logo/CompanyLogo";
import ShowMoreText from "../../show-more-text/ShowMoreText";
import Subtitle from "../../titles/Subtitle";
import "../cardHeader.scss";
interface JobHeaderProps {
  idPosition: string;
}

interface JobHeaderState {
  loading: boolean;
  job?: IJob;
}

const JobHeader: FunctionComponent<JobHeaderProps> = ({ idPosition }): ReactElement => {
  const { constants } = useConfig();
  const [state, setState] = useState<JobHeaderState>({ loading: false });

  useEffect(() => {
    setState({ ...state, loading: true });
    positionService
      .getPositionDetails(constants, idPosition)
      .then((newJob: IJob) => {
        setState({ ...state, job: newJob });
      })
      .catch(err => {
        console.log(err);
        setState({ ...state, loading: false });
      });
  }, [idPosition]);

  return (
    <div className="card-header-container">
      <div className="card-header-first-container">
        <CompanyLogo id={idPosition} size="big" />
        <div className="card-header-data-container">
          <div className="card-header-first-data"  >
            <p className="text-standard text-bold text-title-size">{state.job?.name}</p>
            <p className="text-standard text-bold text-medium-size">{state.job?.companyLabel}</p>
            <p className="text-standard text-bold text-medium-size">{state.job?.location}</p>
          </div>
          <div className="card-header-second-data">
          </div>
        </div>
      </div>
      <div className="card-header-second-container">
        <Subtitle label="label.description" />
        <ShowMoreText longText={state.job?.description ? state.job.description : ""} classes={"text-standard text-grey text-ellipsis padding-top-small"} />
      </div>

      {state.job?.tasks &&
        <div className="margin-top full-width">
          <Subtitle label="label.tasks-description" />
          <ShowMoreText longText={state.job?.tasks ? state.job.tasks : ""} classes={"text-standard text-grey text-ellipsis padding-top-small"} />
        </div>}

      {state.job?.requirements &&
        <div className="margin-top full-width">
          <Subtitle label="label.requirements-description" />
          <ShowMoreText longText={state.job?.requirements ? state.job.requirements : ""} classes={"text-standard text-grey text-ellipsis padding-top-small"} />
        </div>}

      {state.job?.benefits &&
        <div className="margin-top full-width">
          <Subtitle label="label.benefits-description" />
          <ShowMoreText longText={state.job?.benefits ? state.job.benefits : ""} classes={"text-standard text-grey text-ellipsis padding-top-small"} />
        </div>}

    </div>
  );
};

export default JobHeader;
