import TextField from '@material-ui/core/TextField';
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import IExperience from "../../models/IExperience";
import toastService from "../../services/toasts";
import DatePicker from "../input/DatePicker";
import TextArea from "../input/TextArea";
import TextCheckBox from "../input/TextCheckBox";
import SelectSource from '../select-source/SelectSource';
import "./editable-experience-card.scss";
import { IEditableExperience } from "./interfaces/IEditable";

interface EditableExperienceCardProps {
  experience: IEditableExperience;
  onSave: (exp: IExperience) => void;
  onDelete: (nulign: number) => void;
}

const EditableExperienceCard: FunctionComponent<EditableExperienceCardProps> = ({ experience, onSave, onDelete }) => {
  const [localState, setState] = useState<IEditableExperience>(experience);
  const [hovering, setHovering] = useState(false);
  const [hideEndDate, setHideEndDate] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (experience) {
      let data: IEditableExperience = experience;
      if (experience.endDate === "31/12/2999") data.endDate = "";
      if (experience.endDate !== "") { setHideEndDate(false) } else { setHideEndDate(true) };
      setState(data);
    }
  }, [experience])

  const validateForm = () => {
    if (formRef.current?.reportValidity()) {
      if (hideEndDate) localState.endDate = "";
      if (localState.endDate === "") localState.endDate = "31/12/2999";

      const newSkill: IExperience = {
        nulign: localState.nulign,
        name: localState.name,
        company: localState.company,
        startDate: localState.startDate,
        endDate: localState.endDate,
        description: localState.description,
        sector: localState.sector,
        sectorLabel: localState.sectorLabel,
      };
      onSave(newSkill);
    } else {
      toastService.throwError("error.validation-error");
    }
  }

  if (localState.editing) {
    return (
      <div className="edit-exp-card">
        <form ref={formRef}>
          <div className="flex-row-space-between">
            <div className="edit-experience-card-section full-width">
              <p className="text-medium-size text-standard text-bold">
                <FormattedMessage id="label.responsability" />*
              </p>
              <TextField inputProps={{ maxLength: 60 }} id="standard-basic" value={localState.name} onChange={e => setState({ ...localState, name: e.target.value })} required />
            </div>
            <div className="edit-action">
              <div className="flex-row-start text-white cursor-pointer full-width full-height" onClick={validateForm}>
                <label className="standard-button primary-button flex-row-space-between">
                  <FormattedMessage id="action.save" />
                </label>
              </div>
              {/* <i className="fas fa-save cursor-pointer" onClick={validateForm} /> */}
              <i className="fas fa-trash cursor-pointer" onClick={() => (localState.nulign ? onDelete(localState.nulign) : null)} />
            </div>
          </div>

          <div className="flex-row-space-between">
            <div className="edit-experience-card-section full-width">
              <p className="text-medium-size text-standard text-bold">
                <FormattedMessage id="label.company" />*
              </p>
              <TextField inputProps={{ maxLength: 60 }} id="standard-basic" value={localState.company} onChange={e => setState({ ...localState, company: e.target.value })} required />
            </div>
          </div>

          <div className="flex-row-space-between">
            <div className="edit-experience-card-section full-width">
              <p className="text-medium-size text-standard text-bold">
                <FormattedMessage id="label.sector" />
              </p>
              <SelectSource value={localState.sector} reg={"PAM"} dir={"U4G"} onChange={e => setState({ ...localState, sector: e })} />
            </div>
          </div>

          <div className="flex-row-space-between">
            <div className="edit-experience-card-section full-width">
              <p className="text-medium-size text-standard text-bold">
                <FormattedMessage id="label.start-date" />*
              </p>
              <DatePicker withFormat value={localState.startDate} onChange={e => setState({ ...localState, startDate: e })} required />
            </div>
            <div className="edit-experience-card-section full-width">
              <TextCheckBox value={hideEndDate} label="label.current-job" onClick={() => setHideEndDate(!hideEndDate)} />
            </div>
          </div>
          {!hideEndDate &&
            <div className="flex-row-space-between">
              <div className="edit-experience-card-section full-width">
                <p className="text-medium-size text-standard text-bold">
                  <FormattedMessage id="label.end-date" />*
                </p>
                <DatePicker withFormat value={localState.endDate} onChange={e => setState({ ...localState, endDate: e })} required />
              </div>
            </div>}

          <div className="flex-row-space-between">
            <div className="edit-experience-card-section full-width">
              <p className="text-medium-size text-standard text-bold">
                <FormattedMessage id="label.description" />
              </p>
              <TextArea maxLength={3000} value={localState.description} fixedSize={true} onChange={e => setState({ ...localState, description: e })} />
            </div>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className="edit-exp-card" onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <div className="flex-row-space-between">
        <div className="edit-experience-card-section full-width">
          <p className="text-medium-size text-standard text-bold">
            <FormattedMessage id="label.responsability" />
          </p>
          <p className="text-medium-size text-standard">{localState.name}</p>
        </div>
        <div className="edit-action">
          {hovering && <i className="fas fa-pen cursor-pointer" onClick={() => setState({ ...localState, editing: true })} />}
          {hovering && <i className="fas fa-trash cursor-pointer" onClick={() => (localState.nulign ? onDelete(localState.nulign) : null)} />}
        </div>
      </div>

      <div className="flex-row-space-between">
        <div className="edit-experience-card-section full-width">
          <p className="text-medium-size text-standard text-bold">
            <FormattedMessage id="label.company" />
          </p>
          <p className="text-medium-size text-standard">{localState.company}</p>
        </div>
      </div>

      <div className="flex-row-space-between">
        <div className="edit-experience-card-section full-width">
          <p className="text-medium-size text-standard text-bold">
            <FormattedMessage id="label.sector" />
          </p>
          <p className="text-medium-size text-standard">{localState.sectorLabel}</p>
        </div>
      </div>

      <div className="flex-row-space-between">
        <div className="edit-experience-card-section full-width">
          <p className="text-medium-size text-standard text-bold">
            <FormattedMessage id="label.start-date" />
          </p>
          <p className="text-medium-size text-standard">{localState.startDate}</p>
        </div>
        {localState.endDate ?
          <div className="edit-experience-card-section full-width">
            <p className="text-medium-size text-standard text-bold">
              <FormattedMessage id="label.end-date" />
            </p>
            <p className="text-medium-size text-standard">{localState.endDate}</p>
          </div> :
          <div className="edit-experience-card-section full-width">
            <TextCheckBox readOnly label="label.current-job" value={true} />
          </div>}
      </div>

      <div>
        <p className="text-medium-size text-standard text-bold">
          <FormattedMessage id="label.description" />
        </p>
        <p className="text-standard text-medium-size text-large">{localState.description}</p>
      </div>
    </div>
  );
};

export default EditableExperienceCard;
