import React, { useCallback, ReactElement, FunctionComponent } from "react";
import Icon from "../icon/Icon";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";

interface LogoutButtonProps {
  withText: boolean;
}

const LoginButton: FunctionComponent<LogoutButtonProps> = ({ withText }): ReactElement => {
  const history = useHistory();

  const onClick = useCallback(() => {
    history.push("/login");
  }, []);

  return (
    <div className="flex-row-start text-white cursor-pointer" onClick={onClick}>
      <Icon dropDownType name="power-off" light />
      <FormattedMessage id="action.sign-in" />
    </div>
  );
};

LoginButton.defaultProps = {
  withText: false,
};

export default LoginButton;
