import React, { FunctionComponent, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import "./tooltip.scss";

interface TooltipProps {
  text: string;
  position?: "top" | "bottom";
  visible?: boolean;
  color?: "success" | "info" | "warning" | "danger" | "default";
  children: ReactElement
}

export const Tooltip: FunctionComponent<TooltipProps> = ({ text, position, children, visible, color }) => {
  const bgcolor = `background-${color}`;
  return (
    <div className="tooltip top">
      {children}
      <span className={`tiptext ${bgcolor}`} style={{ visibility: visible ? "visible" : "hidden" }}><FormattedMessage id={text} /></span>
    </div>
  )
}

Tooltip.defaultProps = {
  position: "top",
  color: "default",
  visible: false
}
