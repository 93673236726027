import axios from "axios";
import constants from '../../constants';
import IInterest from "../../models/IInterest";
import authHeaders from "../auth/authHeaders";

const getInterestsList = async (): Promise<IInterest[]> => {
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/rules/interestAreas`, { headers: authHeaders() });
  return res.data;
};

export default getInterestsList;
