import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
// import TextField from "../input/TextField";
import TextField from '@material-ui/core/TextField';
import _ from "lodash";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import useConfig from "../../custom-hooks/useConfig";
import IInterest from "../../models/IInterest";
import ILocations from "../../models/ILocations";
import IUser from "../../models/IUser";
import { SelectOptionDefault } from "../../models/SelectOption";
import employeeService from "../../services/employees";
import interestsService from "../../services/interests";
import locationsService from "../../services/locations";
import toastService from "../../services/toasts";
import { dateToMilliseconds, formatDateToStandard } from "../../utils/time/time";
import ButtonDefault from "../button/ButtonDefault";
import Icon from "../icon/Icon";
import DatePicker from "../input/DatePicker";
import TextCheckBox from '../input/TextCheckBox';
import MultipleAutoComplete from "../select/MultipleAutocomplete";
import SelectAutoComplete from "../select/SelectAutoComplete";
import EditUserCV from "./EditUserCV";
import EditUserImage from "./EditUserImage";
interface EditUserDataProps {
  nudoss: string | number;
  updated: Function;
  userData: Function;
  experiences?: any
}

interface EditUserDataState {
  loading: boolean;
  user: IUser;
  birthDate: string;
}

const EditUserData: FunctionComponent<EditUserDataProps> = ({ nudoss, updated, userData, experiences }) => {
  const theme = useTheme();
  const { constants } = useConfig();
  const history = useHistory();


  let [state, setState] = useState<EditUserDataState>({
    loading: false,
    user: { nudoss: nudoss, matcle: "", firstName: "", surname: "", domProvDescription: "", domProv: "", domCity: "", resProvDescription: "", firstJob: true, resProv: "", resCity: "", dateOfBirth: 0, gender: "", email: "", location: "", phone: "", description: "", userPrivacy: { acceptanceDate: "", accepted: false, protectedCategoryIsAccepted: false, application: "" } },
    birthDate: ""
  });

  const formRef = useRef<HTMLFormElement>(null);
  const currentDate = dateToMilliseconds(new Date());
  const birtDateToMillis = dateToMilliseconds(state.birthDate);

  const [priority, setPriority] = useState<number>(0);
  const [flag, setFlag] = useState<boolean>(false);
  const [provinceList, setProvinceList] = useState<SelectOptionDefault[]>([]);
  const [domCityList, setDomCityList] = useState<SelectOptionDefault[]>([]);
  const [resCityList, setResCityList] = useState<SelectOptionDefault[]>([]);

  let [interestVal, setInterestVal] = useState<IInterest[]>([]);
  const [interesList, setInteresList] = useState<IInterest[]>([]);

  if (birtDateToMillis == currentDate) state.birthDate = "";
  const updateUserData = () => {
    const newUser = { ...state.user, dateOfBirth: dateToMilliseconds(state.birthDate) };
    if (state.user) {
      employeeService
        .updateUserProfile(constants, nudoss, newUser)
        .then(res => {
          console.log("updateUserData res", res);
          toastService.throwSuccess();
          updated(true);
          userData(res);
        })
        .catch(err => {
          console.log("updateUserData err", err);
          toastService.throwError(err.response.data.description);
        });
    }
  };


  const deleteUserInterests = (data: IInterest[]) => {
    if (data[0].nulign) {
      interestsService.deleteUserInterest(nudoss, data[0].nulign)
        .then(res => {
          setFlag(prevState => !prevState);
          console.log("deleteInterest res", res)
        })
        .catch(err => {
          console.log("deleteInterest err", err);
        });
    }
  }

  const updateUserInterests = async (data: IInterest[]) => {
    let priority = data.length;
    if (_.size(data) < _.size(interestVal)) {
      let dif: IInterest[] = _.differenceWith(interestVal, data, _.isEqual);
      deleteUserInterests(dif);
      for (const element of data) {
        element.priority = priority;
        await interestsService
          .updateUserInterest(nudoss, element.nulign, element)
          .then(res => {
            console.log("updateInterest res", res);
          })
          .catch(err => {
            console.log("updateInterest err", err);
          });
        priority--;
      }
    } else {
      for (const element of data) {
        if (data.length == 1) {
          element.priority = 1;
          await interestsService
            .addUserInterest(nudoss, element)
            .then(res => {
              setFlag(prevState => !prevState);
              console.log("createInterest res", res);
            })
            .catch(err => {
              console.log("createInterest err", err);
            });
        } else {
          if (priority == 1) {
            element.priority = priority;
            await interestsService
              .addUserInterest(nudoss, element)
              .then(res => {
                setFlag(prevState => !prevState);
                console.log("createInterest res", res);
              })
              .catch(err => {
                console.log("createInterest err", err);
              });
          } else {
            element.priority = priority;
            await interestsService
              .updateUserInterest(nudoss, element.nulign, element)
              .then(res => {
                console.log("updateInterest res", res);
              })
              .catch(err => {
                console.log("updateInterest err", err);
              });
            priority--;
          }
        }
      }
    }
  }

  useEffect(() => {
    locationsService
      .getCities(state.user.domProv).then((c: string[]) => {
        let list: SelectOptionDefault[] = [];
        c.forEach((e) => {
          // const label = e.charAt(0) + e.slice(1).toLowerCase();
          list.push({ value: e, label: e });
        })
        setDomCityList(list);
      })
    locationsService
      .getCities(state.user.resProv).then((c: string[]) => {
        let list: SelectOptionDefault[] = [];
        c.forEach((e) => {
          // const label = e.charAt(0) + e.slice(1).toLowerCase();
          list.push({ value: e, label: e });
        })
        setResCityList(list);
      })
  }, [state.user.domProv, state.user.resProv]);

  useEffect(() => {
    interestVal.length = 0;
    interestsService
      .getUserInterest(nudoss)
      .then((i: IInterest[]) => {
        i.forEach((e: IInterest, index) => {
          interestVal.push({ nudoss: e.nudoss, nulign: e.nulign, area: e.area, areaLabel: e.areaLabel, priority: e.priority });
        })
        setPriority(interestVal.length);
      })
      .catch(err => {
        console.log(err);
        setState({ ...state, loading: false });
      });
  }, [flag]);

  useEffect(() => {
    setState({ ...state, loading: true });
    employeeService
      .getProfileInformation(constants, nudoss)
      .then((u: IUser) => {
        locationsService
          .getCities(u.domProv).then((c: string[]) => {
            let list: SelectOptionDefault[] = [];
            c.forEach((e) => {
              // const label = e.charAt(0) + e.slice(1).toLowerCase();
              list.push({ value: e, label: e });
            })
            setDomCityList(list);
          })
        locationsService
          .getCities(u.resProv).then((c: string[]) => {
            let list: SelectOptionDefault[] = [];
            c.forEach((e) => {
              // const label = e.charAt(0) + e.slice(1).toLowerCase();
              list.push({ value: e, label: e });
            })
            setResCityList(list);
          })
        userData(u);
        setState({ ...state, loading: false, user: u, birthDate: formatDateToStandard(u.dateOfBirth).toString() });
      })
      .catch(err => {
        console.log(err);
        setState({ ...state, loading: false });
      });
    interestsService
      .getInterestsList()
      .then((i: IInterest[]) => {
        let list: IInterest[] = [];
        i.forEach((e: IInterest) => {
          list.push({ nudoss: nudoss, nulign: e.nulign, area: e.area, areaLabel: e.areaLabel, priority: e.priority })
        })
        setInteresList(list);
      })
      .catch(err => {
        console.log(err);
        setState({ ...state, loading: false });
      });
    locationsService
      .getProvinces()
      .then((p: ILocations[]) => {
        let list: SelectOptionDefault[] = [];
        p.forEach(e => {
          if (e.province) list.push({ value: e.province, label: e.description });
        })
        setProvinceList(list)
      })
      .catch(err => {
        console.log(err);
        setState({ ...state, loading: false });
      });
  }, []);

  const validateForm = () => {
    if (formRef.current?.reportValidity()) {
      updateUserData();
    }
    else {
      toastService.throwError("error.validation-error");
    }
  }

  const genderLabel = (value: string) => {
    let label = "";
    if (value === "") {
      label = "";
    }
    else if (value === "1") {
      label = "Maschile";
    }
    else if (value === "2") {
      label = "Femminile";
    }
    return label;
  }

  return (
    <div className="flex-column-center">
      <div className="edit-user-data-container">
        <div className="edit-user-data-subcontainer">
          <div className="user-edit-image-section">
            <EditUserImage nudoss={nudoss} />
          </div>
          <div className="user-edit-cv-section">
            <EditUserCV nudoss={nudoss} updated={updated} />
          </div>
          <div className="margin-top" >
            <div className="flex-row-start text-white cursor-pointer full-width full-height" onClick={() => history.push("/my-profile")}>
              <label className="standard-button primary-button flex-row-space-between">
                <FormattedMessage id="action.back-to-profile" />
                <i className="fas fa-user margin-left" />
              </label>
            </div>
          </div>
        </div>

        <div className="edit-page-subcontainer" >
          <div className="user-edit-basic-info">
            <form ref={formRef}>
              <div className="user-data-form">
                <TextField inputProps={{ maxLength: 30 }} required id="standard-basic" label={<FormattedMessage id={"label.name"} />} value={state.user?.firstName || ""} onChange={e => setState({ ...state, user: { ...state.user, firstName: e.target.value } })} InputProps={{
                  endAdornment: <InputAdornment position="end"><Icon name="user-circle" withoutContainer /></InputAdornment>,
                }} />

                <TextField inputProps={{ maxLength: 40 }} required id="standard-basic" label={<FormattedMessage id={"label.surname"} />} value={state.user?.surname || ""} onChange={e => setState({ ...state, user: { ...state.user, surname: e.target.value } })} InputProps={{
                  endAdornment: <InputAdornment position="end"><Icon name="user-circle" withoutContainer /></InputAdornment>,
                }} />

                <SelectAutoComplete
                  options={provinceList}
                  name="label.residence"
                  value={state.user.resProvDescription}
                  onChange={e =>
                    setState({ ...state, user: { ...state.user, resProvDescription: e.label, resProv: e.value } })
                  }
                  required
                />

                <SelectAutoComplete
                  options={resCityList}
                  name="label.residencecity"
                  value={state.user.resCity}
                  onChange={e => setState({ ...state, user: { ...state.user, resCity: e.label } })}
                  required
                />

                <SelectAutoComplete
                  options={provinceList}
                  name="label.address"
                  value={state.user.domProvDescription}
                  onChange={e =>
                    setState({ ...state, user: { ...state.user, domProvDescription: e.label, domProv: e.value } })
                  }
                  required
                />

                <SelectAutoComplete
                  options={domCityList}
                  name="label.cityaddress"
                  value={state.user.domCity}
                  onChange={e => setState({ ...state, user: { ...state.user, domCity: e.label } })}
                  required
                />

                <SelectAutoComplete
                  options={[{ value: "", label: "" }, { value: "1", label: "Maschile" }, { value: "2", label: "Femminile" }]}
                  name="label.gender"
                  value={genderLabel(state.user.gender)}
                  onChange={e => setState({ ...state, user: { ...state.user, gender: e.value } })}
                  translated
                  required
                />

                <DatePicker required label="label.date-of-birth" value={state.birthDate || ""} onChange={e => setState({ ...state, birthDate: e })} />

                <TextField required id="standard-basic" label={<FormattedMessage id={"label.email"} />} value={state.user?.email || ""} onChange={e => setState({ ...state, user: { ...state.user, email: e.target.value } })} InputProps={{
                  endAdornment: <InputAdornment position="end"><Icon name="envelope" withoutContainer /></InputAdornment>,
                }} />

                <TextField type="number" required id="standard-basic" label={<FormattedMessage id={"label.phone"} />} value={state.user?.phone || ""} onChange={e => setState({ ...state, user: { ...state.user, phone: e.target.value } })} InputProps={{
                  endAdornment: <InputAdornment position="end"><Icon name="phone" withoutContainer /></InputAdornment>,
                }} />
              </div>
              <div className="user-data-form-single">
                {interestVal && <MultipleAutoComplete options={interesList} value={interestVal} onUpdate={e => updateUserInterests(e)} priority={priority} />}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div style={{ width: "95%" }}>
        {/*<TextArea label="label.aboutyou" value={state.user?.description} fixedSize={true} onChange={e => setState({ ...state, user: { ...state.user, description: e } })} maxLength={3500} bold />*/}
        <div style={{ display: "flex", justifyContent: "center", margin: "1rem" }}>
          <TextCheckBox value={state.user.firstJob} label="label.first-job"
            onClick={(e: any) => setState({ ...state, user: { ...state.user, firstJob: e } })}
            readOnly={experiences && experiences?.length > 0}
          />
        </div>
      </div>
      <ButtonDefault onClick={validateForm} text="action.save" color="primary" size="standard" />
    </div >
  );
};

export default EditUserData;
