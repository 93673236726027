import axios from "axios";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";
import storageService from "../storage";

const postApplyPosition = async (constants: ConstantsConfig, nudoss: string | number, id: string): Promise<boolean> => {
  if (constants.useMockData) return true;
  const userKey = storageService.getFromSession(storageService.sessionKeys.USER_KEY);

  const res = await axios.post(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/positions/${id}?role=${userRole()}`, {}, { headers: authHeaders() });
  return res.status === 200;
};

export default postApplyPosition;
