import axios from "axios";
import generateFakeEducation from "../../mocked-data/generateFakeEducation";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import IEducation from "../../models/IEducation";
import authHeaders from "../auth/authHeaders";

const getEducation = async (constants: ConstantsConfig, nudoss: string | number, nulign: string): Promise<IEducation> => {
  if (constants.useMockData) return generateFakeEducation();
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/educations/${nulign}`, { headers: authHeaders() });
  return res.data;
};

export default getEducation;
