import axios from "axios";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";

const deleteSkill = async (constants: ConstantsConfig, nudoss: string | number, nulign: string | number): Promise<boolean> => {
  if (constants.useMockData) return true;
  const res = await axios.delete(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/skills/${nulign}?role=${userRole()}`, { headers: authHeaders() });
  return res.status === 200;
};

export default deleteSkill;
