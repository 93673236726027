import acceptLanguageGenerator from "../../infrastructure/acceptLanguageGenerator";
import storageService from "../storage";

const authHeaders = (publicService?: boolean, multipart?: boolean) => {
  const token = storageService.getFromSession(storageService.sessionKeys.AUTH_TOKEN_KEY);
  const authHeader = !publicService ? `Bearer ${token}`: null;
  const contentTypeHeader = multipart ? "multipart/form-data" : "application/json";

  const headers = {
    Authorization: authHeader,
    "Content-Type": contentTypeHeader,
    Accept: "application/json",
    "Accept-Language": acceptLanguageGenerator()  
  };

  return headers;
};

export default authHeaders;
