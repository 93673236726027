import React, { FunctionComponent, useState, useEffect } from "react";
import "./show-more-text-styles.scss";
import classnames from "classnames";
import Icon from "../icon/Icon";
import { constants } from "buffer";
import useConfig from "../../custom-hooks/useConfig";

interface ShowMoreTextProps {
  longText:string;
  numberOfLines?: number;
  classes:string;
}

const ShowMoreText: FunctionComponent<ShowMoreTextProps> = ({ longText, numberOfLines, classes }) => {
  const [showMore, setShowMore] = useState(false);
  const [buttonText, setButtonText] = useState(<Icon name="ellipsis-h" style={{color: "var(--colour-primary)"}}/>);
  const [text, setText] = useState('');
  const { constants } = useConfig();

  if (numberOfLines == undefined) numberOfLines = longText.length;
  const showButton = longText.length >= numberOfLines * 100;

  const changeTextLength = () => {
    if (showMore) {
      setText(longText);
      setButtonText(<Icon name="ellipsis-h" style={{color: "grey"}}/>);
    } else {
      const maxLines = numberOfLines ? numberOfLines * 100 : longText.length;
      setText(`${longText.substring(0, maxLines)}...`);
      setButtonText(<Icon name="ellipsis-h" style={{color: "var(--colour-primary)"}} /> );
    }
  };

  useEffect(() => {
    if (showButton) changeTextLength();
    else setText(longText)
  }, [showMore,longText]);
  
  return (
    <div style={{    whiteSpace: "pre-wrap"    }}>
      <p className={classnames(classes)}>{text}</p>
      {constants.showMoreTextButtonEnabled && showButton && (
        <a className="showMoreBtn" onClick={() => setShowMore(!showMore)}>
          {buttonText}
        </a>
      )}
    </div>
  );
};

export default ShowMoreText;
