import getCurrentView from "./getCurrentView";
import isDesktopView from "./isDesktopView";
import isMobileView from "./isMobileView";
import isTabletView from "./isTabletView";
import VIEW_NAMES from "./viewNames";
import VIEW_SIZES from "./viewSizes";

const ViewDetection = {
  getCurrentView,
  isDesktopView,
  isMobileView,
  isTabletView,
  VIEW_NAMES,
  VIEW_SIZES
};

export default ViewDetection;
