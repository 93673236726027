import React, { FunctionComponent, ReactElement, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import {
  Link
} from "react-router-dom";
import ButtonDefault from "../components/button/ButtonDefault";
import Card from "../components/card/Card";
import FootNote from "../components/foot-note/FootNote";
import TextField from "../components/input/TextField";
import HeaderLogo from "../components/logo/HeaderLogo";
import { AuthContext, UserContext } from "../context";
import useConfig from "../custom-hooks/useConfig";
import googleLogo from '../media/google-logo.png';
import linkedinLogo from '../media/linkedin-logo.png';
import IUser from "../models/IUser";
import authService from "../services/auth";
import employeeService from "../services/employees";
import getProfileCompleted from "../services/employees/getProfileCompleted";
import toastService from "../services/toasts";
import "./login.scss";
interface LoginState {
  username: string;
  password: string;
  loading: boolean;
}

const LoginPage: FunctionComponent = (): ReactElement => {
  const history = useHistory()
  const { constants } = useConfig();
  const { setAuthContextState } = useContext(AuthContext);
  const { setUserContextState } = useContext(UserContext);
  const [state, setState] = useState<LoginState>({ username: "", password: "", loading: false });
  const [module, setLogin] = useState(false);

  const login = (e: React.FormEvent) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    authService
      // LdapLoginModule
      // UC10LoginModule
      // UC10 sólo para localhost, al subir tiene que ser ldap
      .login(constants, state.username, state.password, "LdapLoginModule")
      .then(() => {
        employeeService.getMyInfo(constants)
          .then(user => {
            employeeService
              .getProfileInformation(constants, user.nudoss)
              .then((u: IUser) => {
                if (u.userPrivacy.accepted) {
                  setAuthContextState?.({ checked: true, isAuth: true });
                  setTimeout(() => {
                    setState({ ...state, loading: false });
                    const jobId = localStorage.getItem('jobPositionID');
                    if (jobId) {
                      history.push(`/jobs/${jobId}`)
                    }
                    else {
                      history.push("/");
                      if (u.nudoss) {
                        getProfileCompleted(constants, u.nudoss)
                          .then((data) => {
                            if (data.complete) {
                              history.push("/");
                            }
                            else {
                              history.push("/my-profile-edit")
                            }
                          })
                      }
                    }
                  }, 2000);
                } else {
                  setAuthContextState?.({ checked: true, isAuth: false });
                  history.push("/privacy-policy/info");
                }
              })
              .catch(err => {
                console.log(err);
              });
            setUserContextState?.({ user })
          })
          .catch(err => {
            console.log(err);
            setState({ ...state, loading: false });
          })
      })
      .catch(err => {
        console.log("updateStaffCV err", err);
        if (err.response)
          toastService.throwError(err.response.data.description);

        setState({ ...state, loading: false });
        authService.logout().then(() => {
          setAuthContextState?.({ checked: true, isAuth: false });
          setUserContextState?.({ user: undefined });
          history.push("/");
        });
      })
  };

  return (
    <div className="login-background">
      <Card loginCard>
        {/* TODO: Refactorize logo components or add a new one "LoginLogo" */}
        <div className="login-logo-container">
          <HeaderLogo login />
        </div>
        <form onSubmit={login} autoComplete="off" className="form-container">
          <TextField
            label="label.username"
            value={state.username}
            onChange={e => setState({ ...state, username: e })}
            type="text"
            icon="user-circle"
          />
          <TextField
            label="label.password"
            value={state.password}
            onChange={e => setState({ ...state, password: e })}
            type="password"
            icon="lock"
          />
          <Link style={{ marginRight: '3.5rem', marginTop: '1rem' }} to="/reset-password"><FormattedMessage id="label.reset-password" /></Link>
          <div className="login-actions">
            <ButtonDefault
              disabled={state.loading}
              loading={state.loading}
              onClick={login}
              text="action.sign-in"
              color="primary"
              size="standard"
              style={{ 'marginBottom': '1rem' }}
            />
            <div className="flex-row-space-between">
              <Link style={{ marginRight: '0.5rem' }} to="/signup"><FormattedMessage id="action.sign-up" /></Link>
            </div>
            <div className="social-login">
              <a className="btn social-btn google" href={constants.GOOGLE_AUTH_URL}>
                <img src={googleLogo} alt="Google" /><FormattedMessage id="action.sign-in-google" /></a>
              <a className="btn social-btn github" href={constants.LINKEDIN_AUTH_URL}>
                <img src={linkedinLogo} alt="Github" /><FormattedMessage id="action.sign-in-linkedin" /></a>
            </div>
          </div>
        </form>
      </Card>
      <FootNote email="servizioclienti@gruppopam.it" style={{ position: "absolute", bottom: 0 }} />
    </div>
  );
};

export default LoginPage;
