import getPositionApplied from "./getPositionApplied";
import postApplyPosition from "./postApplyPosition";
import deleteAppliedPosition from "./deleteAppliedPosition";
import getStaffPositionApplies from "./getStaffPositionApplies";

const staffService = {
  getStaffPositionApplies,
  getPositionApplied,
  postApplyPosition,
  deleteAppliedPosition,
};

export default staffService;
