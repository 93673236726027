import React, { FunctionComponent, ReactElement, useState, useEffect, useCallback, useContext } from "react";
import MainLayout from "../components/layout/MainLayout";
import pdf from "../media/terminos-condiciones-app.pdf";
import { showModal } from "../components/modal/ModalListener";
import Modal from "../components/modal/Modal";
import Icon from "../components/icon/Icon";
import ButtonDefault from "../components/button/ButtonDefault";
import employeeService from "../services/employees";
import useConfig from "../custom-hooks/useConfig";
import { AuthContext, UserContext } from "../context";
import { useHistory } from "react-router";

const TermsOfUsePage: FunctionComponent = (): ReactElement => {

    const [state, setstate] = useState({ loading: false, isApplied: false });
    const { setUserContextState } = useContext(UserContext);
    const { setAuthContextState } = useContext(AuthContext);
    const { constants } = useConfig();

    const history = useHistory();

    const onClick = useCallback(() => {
        setstate({ ...state, loading: false, isApplied: true });
        employeeService.getMyInfo(constants)
        .then(user => {
          console.log('user:', user)
          setAuthContextState?.({ checked: true, isAuth: true});
          setTimeout(() => {
            history.push("/")
          }, 2000);
      })
    }, []);

    const showTermsModal = () => {
        showModal(<Modal><object style={{ width: '100%', height: '100vh' }} data={pdf} type="application/pdf"></object></Modal>);
    };
    return (
        <MainLayout>
            <div style={{ margin: "3rem" }}>
                <p className="margin-bottom">
                    Il trattamento dei Suoi dati:
                </p>
                <div>
                    <li>
                        per i dati comuni, avverrà per la gestione della sua candidatura senza necessità del Suo consenso, ai
                        sensi dell’art. 6, comma 1, lett. c) del Regolamento
                    </li>
                    <li>
                        per le categorie particolari di dati personali, si baserà sul consenso da Lei fornito, in conformità
                        all’art. 9, comma 2, lett. a) del Regolamento
                    </li>
                </div>
                <p className="margin-top">
                    In ogni caso il trattamento avverrà in conformità alle prescrizioni di cui di cui alla vigente normativa in
                    materia di diritto del lavoro e della sicurezza e protezione sociale, medicina del lavoro, in esecuzione del
                    contratto di lavoro con Lei concluso, nonché nel rispetto dei provvedimenti vigenti – e delle loro successive
                    modifiche e/o integrazioni - emessi in materia dall’Autorità Garante per la Protezione dei dati personali (fra i
                    quali Linee guida in materia di trattamento di dati personali di lavoratori per finalità di gestione del
                    rapporto di lavoro alle dipendenze di datori di lavoro privati) e dal Gruppo di lavoro costituito ex Articolo 29
                    della Direttiva 95/46 CE (tra cui Opinion 2/2017 on data processing at work).
                </p>
            </div>
            <div style={{ margin: "3rem" }} className="flex-row-start">
                <p>Controlla la documentazione</p>
                <Icon big name="file-pdf" onClick={() => showTermsModal()} />
            </div>
            <div style={{ margin: "3rem" }}>
                <p className="margin-top">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
            </p>
            </div>
            <div style={{ margin: "3rem" }} className="flex-row-start">
                <p>Controlla la documentazione</p>
                <Icon big name="file-pdf" onClick={() => showTermsModal()} />
            </div>
            <div style={{ margin: "3rem" }} className="flex-row-end">
                <ButtonDefault text={state.isApplied ? "action.applied" : "action.apply"} onClick={onClick} color={"primary"} size="standard" disabled={state.isApplied} />
            </div>
        </MainLayout>
    )
}

export default TermsOfUsePage;
