import classnames from "classnames";
import React, { Fragment, FunctionComponent, ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import "./Input.scss";

interface TextAreaProps {
  id?: string;
  readOnly?: boolean;
  required?: boolean;
  value?: string;
  onChange: (e: string) => void;
  onClick?: Function;
  label?: string;
  backgroundDark?: boolean;
  aligned?: boolean;
  smallSize?: boolean;
  bold?: boolean;
  first?: boolean;
  withoutBorder?: boolean;
  fixedSize?: boolean;
  maxLength?: number;
}

const TextArea: FunctionComponent<TextAreaProps> = ({
  id,
  value,
  readOnly,
  onChange,
  required,
  onClick,
  label,
  backgroundDark,
  aligned,
  smallSize,
  bold,
  first,
  withoutBorder,
  fixedSize,
  maxLength
}): ReactElement => {
  let numberOfLineBreaks = 1;
  if (value) {
    numberOfLineBreaks = value.split("\n").length > 0 ? value.split("\n").length : 1;
  }

  return (
    <div className="full-width margin-bottom">
      {label ? (
        <label className={classnames("input-label",
          { "text-disabled": readOnly || !backgroundDark },
          { "text-standard text-bold": bold },
          { "text-white": backgroundDark })}
          htmlFor={id}
        >
          <FormattedMessage id={label} />
        </label>
      ) : (
        <Fragment />
      )}
      <div
        className={classnames(
          "input-container",
          "textarea-container",
          { inline: aligned },
          { "full-width": !smallSize && !aligned },
          { "third-width": smallSize || aligned },
          { "text-bold": bold },
          { "": first }
        )}
        onClick={onClick && !readOnly ? () => onClick() : () => { }}
      >
        <div
          className={classnames(
            { "input-fields-disabled": readOnly },
            { "text-disabled": readOnly && !onClick },
            { "input-fields text-white": backgroundDark && !readOnly },
            { "input-fields text-black": !backgroundDark && !readOnly },
            { "input-without-border": withoutBorder },
            { "text-disabled-without-cursor": readOnly && onClick }
          )}
        >
          <textarea
            className={classnames("text-area-input", { "cursor-pointer": onClick && value !== undefined && value !== "" })}
            style={fixedSize ? { resize: "none", fontSize: '15px', border: "none" } : { resize: "vertical" }}
            placeholder="  "
            readOnly={readOnly}
            disabled={readOnly}
            rows={10}
            cols={34}
            maxLength={maxLength}
            value={value}
            onChange={e => (onChange ? onChange(e.target.value) : () => { })}
            onClick={onClick && !readOnly ? () => onClick() : () => { }}
            id={id}
            required={required}
            autoComplete="off"
          />
        </div>
      </div>
    </div>
  );
};

TextArea.defaultProps = {
  id: undefined,
  readOnly: false,
  required: false,
  onClick: undefined,
  label: undefined,
  backgroundDark: false,
  aligned: false,
  smallSize: false,
  bold: false,
  first: false,
  withoutBorder: false,
  fixedSize: false,
  maxLength: 250
};

export default TextArea;
