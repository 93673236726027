/* eslint-disable no-restricted-globals */
import VIEW_SIZES from "./viewSizes";

const isMobileView = (width : number): boolean => {
  if (width) {
    return width <= VIEW_SIZES.MOBILE;
  }
  return screen.width <= VIEW_SIZES.MOBILE;
}

export default isMobileView;
