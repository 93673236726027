import axios from "axios";
import fakeLoggingUser from "../../mocked-data/fakeLoggingUser";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import ILoggingUser from "../../models/ILoggingUser";
import authHeaders from "../auth/authHeaders";
import storageService from "../storage";

const getMyInfo = async (constants: ConstantsConfig): Promise<ILoggingUser> => {
  if (constants.useMockData) {
    storageService.saveToSession(storageService.sessionKeys.USER_KEY, fakeLoggingUser);
    return fakeLoggingUser;
  }
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/employees/my-user`, { headers: authHeaders() });
  storageService.saveToSession(storageService.sessionKeys.USER_KEY, res.data);
  return res.data;
};

export default getMyInfo;
