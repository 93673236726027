import { ChartSerie } from "../interfaces/ChartSerie";
import { ChartOptions } from "../interfaces/ChartOptions";
import ISkill from "../../../models/ISkill";

const generateChartSeriesAndOptions = (
  offerSkills: ISkill[],
  userSkills: ISkill[],
  colors: "primary" | "secondary",
  requiredSkills?: boolean
): [ChartSerie[], ChartOptions] => {
  const categories: any[] = [];
  const offerValues: number[] = [];
  const userValues: number[] = [];
  let nameSerie: string = requiredSkills ? "Required Level" : "Desired Level";
  let series: ChartSerie[];

  if (offerValues) {
  //"Phrases loop"
    for (let i = 0; i < offerSkills.length; i++) {
      const s = offerSkills[i];
      //already added validation
      const alreadyAdded = categories.find(e => e === s.label);
      if (!alreadyAdded && parseInt(s.level, 0) > 0) {

        //Long words formatter 
        const newLabels: string[] = s.label.trim().split(" ");
        if (newLabels.length >= 3){          
          const firstWord: string[] = [];
          const secondWord: string[] =[];

          //"Words loop" - Look for the mid-phrase word in order to iterate the parent loop ("Phrase loop")
          for (let j = 0; j < newLabels.length; j++) {
            //Check which word of the phrase is
            if (j < (newLabels.length/2)) {
              // newWord.push(newLabels[j]);
              firstWord.push(newLabels[j]);
            } else {
              secondWord.push(newLabels[j]);
            }
          }
          //long word push
          categories.push([firstWord.toString().replace(",", " "),secondWord.toString().replace(",", " ")]);
        } else {
          //short word push 
          categories.push(s.label);
        }

        //offerSkills push
        offerValues.push(parseInt(s.level, 0));

        //user skills add-on (only if its found on the position list)
        const userS = userSkills.find(e => e.label === s.label);
        if (userS) {
          userValues.push(parseInt(userS.level, 0));
        } else {
          userValues.push(0);
        }
      }
    }
  }
  const newCategories: string[] = ["Lol", "yeeees", "test", "test 1", "test 2"];

  // if (userValues.find(e => e !== 0)) {
  series = [
    { name: nameSerie, data: offerValues },
    { name: "Your Level", data: userValues },
  ];

  // const options: ChartOptions = {
  //   chart: {
  //     type: "bar",
  //     // height: "100%",
  //     // width: "100%",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   colors: colors === "primary" ? ["var(--colour-secondary)", "var(--colour-primary)"] : ["var(--colour-info)", "var(--colour-success)"],
  //   plotOptions: {
  //     bar: {
  //       horizontal: true,
  //       endingShape: "rounded",
  //       // barHeight: "30%",
  //       dataLabels: {
  //         show: false,
  //       },
  //     },
  //   },
  //   xaxis: {
  //     categories: categories,
  //     labels: {
  //       show: false,
  //     },
  //   },
  //   yaxis: {
  //     labels: {
  //       style: {
  //         color: "var(--colour-black)",
  //         fontSize: "1rem",
  //         fontFamily: "Segoe UI",
  //       },
  //     },
  //   },
  //   grid: {
  //     show: false,
  //   },
  //  
  // };

  const lolOptions: ChartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false
      }
    },
    colors: colors === "primary" ? ["var(--colour-secondary)", "var(--colour-primary)"] : ["var(--colour-info)", "var(--colour-success)"],
    xaxis: {
      categories: categories,
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          color: "var(--colour-black)",
          fontSize: "16px",
          fontFamily: "Segoe UI",
          fontWeight: 500,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        endingShape: "rounded",
        barHeight: "50%",
        dataLabels: {
          show: false,
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
    },
  };

  return [series, lolOptions];
};

export default generateChartSeriesAndOptions;
