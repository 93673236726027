import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef } from "react";
import { WindowSizeContext } from "../../context";
import BackdropListener from "../backdrop/BackdropListener";
import HeaderCardImage from "../background-card/HeaderCardImage";
import Card from "../card/Card";
import FootNote from "../foot-note/FootNote";
import ModalListener from "../modal/ModalListener";
import ScrollToTop from "../scroll-to-top/ScrollToTop";
import Toolbar from "../toolbar/Toolbar";
interface MainLayoutProps {
  usesRefToScroll?: boolean,
  withCustomBackground?: boolean,
  reference?: Function
}

const MainLayout: FunctionComponent<MainLayoutProps> = (props): ReactElement => {
  const scrollRef = useRef(null);
  const { isMobile } = useContext(WindowSizeContext);

  useEffect(() => {
    if (props.reference) props.reference(scrollRef)
  }, [scrollRef])

  return (
    <React.Fragment>
      <ModalListener />
      <BackdropListener>
        <div className={props.withCustomBackground ? "custom-background" : "standard-background"}>
          <Toolbar />
          <Card backgroundCard >
            <div className="card-content" ref={props.usesRefToScroll ? scrollRef : null}>
              {!props.withCustomBackground && <HeaderCardImage />}
              {props.usesRefToScroll && !isMobile && <ScrollToTop scrollRef={scrollRef} />}
              {props.children}
            </div>
          </Card>
          <FootNote email="servizioclienti@gruppopam.it" />
        </div>
      </BackdropListener>
    </React.Fragment>
  );
};

MainLayout.defaultProps = {
  usesRefToScroll: false,
};

export default MainLayout;
