import changePassword from "./changePassword";
import checkLoggedIn from "./checkLoggedIn";
import login from "./login";
import loginWithGoogle from "./loginWithGoogle";
import logout from "./logout";
import register from "./register";
import validateToken from "./validateToken";

const authService = {
  changePassword,
  checkLoggedIn,
  login,
  loginWithGoogle,
  logout,
  register,
  validateToken
};

export default authService;
