import deactivateUser from "./deactivateUser";
import getMyInfo from "./getMyInfo";
import getProfileCompleted from "./getProfileCompleted";
import getProfileInformation from "./getProfileInformation";
import updateUserProfile from "./updateUserProfile";

const employeeService = {
  deactivateUser,
  getProfileInformation,
  getMyInfo,
  updateUserProfile,
  getProfileCompleted
};

export default employeeService;
