import React, { FunctionComponent, ReactElement } from "react";
// import logo from "../../media/logo_sopra_blanc_x2.png";
import standardLogo from "../../media/company-logo.png";
import loginLogo from "../../media/company-logo_1.png";
import { useHistory } from "react-router";

interface HeaderLogoProps {
  login?: boolean;
} 

const HeaderLogo: FunctionComponent<HeaderLogoProps> = ({ login }): ReactElement => {
  const history = useHistory();

  let currentLogo = standardLogo; 
  login && (currentLogo = loginLogo);

  return (
    <React.Fragment>
      <div className="header-logo-container">
        <img className="header-logo" src={currentLogo} alt="Company Logo" onClick={() => history.push("/")} />
      </div>
    </React.Fragment>
  );
};

export default HeaderLogo;
