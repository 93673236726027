import axios from "axios";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";

const getPositionApplied = async (constants: ConstantsConfig, idPosition: string, nudoss: string | number): Promise<boolean> => {
  if (constants.useMockData) return true;
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/positions/${idPosition}?role=${userRole()}`, { headers: authHeaders() });
  return res.data;
};

export default getPositionApplied;
