import axios from "axios";
import generateFakeSkill from "../../mocked-data/generateFakeSkill";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import ISkill from "../../models/ISkill";
import authHeaders from "../auth/authHeaders";

const getSkill = async (constants: ConstantsConfig, nudoss: string | number, nulign: string | number): Promise<ISkill> => {
  if (constants.useMockData) return generateFakeSkill();
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/skills/${nulign}`, { headers: authHeaders() });
  return res.data;
};

export default getSkill;
