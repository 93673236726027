import classnames from "classnames";
import React, { CSSProperties, ReactNode } from "react";
import TOPICS from "../../services/topics";
import Icon from "../icon/Icon";
import "./Modal.scss";

interface ModalProps {
  small?: boolean
  medium?: boolean,
  children: ReactNode,
  dossierModal?: boolean,
  style?: CSSProperties
}

const Modal = ({ medium, small, children, dossierModal, style }: ModalProps) => {
  return (
    <div className={classnames("modal", { "modal-default": !small || !medium }, { "modal-medium": medium }, { "modal-small": small }, { "full-height": dossierModal })} style={style}>
      <div className={classnames("full-width", { "full-height": !dossierModal }, { "position-relative": dossierModal })}>
        <Icon dropDownType name="times" onClick={() => TOPICS.MODAL_TOPIC_CLOSE.next(false)} />
        {children}
      </div>
    </div>
  );
};

Modal.defaultProps = {
  small: false,
  medium: false,
  children: <React.Fragment />,
  dossierModal: false
};

export default Modal;
