import axios from "axios";
import fakeData from "../../mocked-data/fakeData";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import ISkill from "../../models/ISkill";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";

const getSkills = async (constants: ConstantsConfig, nudoss: string | number): Promise<ISkill[]> => {
  if (constants.useMockData) return fakeData.fakeRandomSkills;
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/skills?role=${userRole()}`, { headers: authHeaders() });
  return res.data;
};

export default getSkills;
