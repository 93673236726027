import React, { FunctionComponent, useEffect, useState } from "react";
import useConfig from "../../custom-hooks/useConfig";
import ISkill from "../../models/ISkill";
import skillsService from "../../services/skills";
import toastService from "../../services/toasts";
import ButtonDefault from "../button/ButtonDefault";
import Subtitle from "../titles/Subtitle";
import EditableSkillCard from "./EditableSkillCard";
import { EditSectionProps } from "./interfaces/EditSectionProps";
import { IEditableSkill } from "./interfaces/IEditable";

interface SkillsEditSectionState {
  skills: IEditableSkill[];
  loading: boolean;
}

const SkillsEditSection: FunctionComponent<EditSectionProps<ISkill>> = ({ nudoss }) => {
  const { constants } = useConfig();
  const [state, setState] = useState<SkillsEditSectionState>({ skills: [], loading: false });

  useEffect(() => {
    if (nudoss) {
      setState({ ...state, loading: true });
      skillsService
        .getSkills(constants, nudoss)
        .then((newSkills: ISkill[]) => {
          setState({ ...state, loading: false, skills: newSkills.map(e => ({ ...e, editing: false })) });
        })
        .catch(err => {
          console.log(err);
          setState({ ...state, loading: false });
        });
    }
  }, [nudoss]);

  const addSkill = () => {
    const newSkill: IEditableSkill = {
      nulign: -1,
      label: "",
      code: "",
      level: "",
      levelLabel: "",
      editing: true,
    };
    setState({ loading: false, skills: [...state.skills, newSkill] });
  };

  const onDeleteSkill = (nulign: number) => {
    if (nudoss) {
      const deletedSkill: IEditableSkill | undefined = state.skills.find(e => e.nulign === nulign);
      if (deletedSkill) {
        skillsService
          .deleteSkill(constants, nudoss, deletedSkill.nulign)
          .then(res => {
            console.log("deleteSkill res", res);
            toastService.throwSuccess();
            const newSkills: IEditableSkill[] = state.skills.filter(e => e.nulign !== deletedSkill.nulign);
            setState({ ...state, skills: newSkills });
          })
          .catch(err => {
            console.log("deleteSkill err", err);
            toastService.throwError(err.response.data.description);
          });
      }
    }
  };

  const onSaveSkill = (skill: ISkill) => {
    if (nudoss) {
      const editedSkill: ISkill | undefined = state.skills.find(e => e.nulign === skill.nulign);
      if (editedSkill) {
        editedSkill.nulign = skill.nulign;
        editedSkill.level = skill.level;
        editedSkill.levelLabel = skill.levelLabel;
        editedSkill.label = skill.label;
        editedSkill.code = skill.code;

        if (skill.nulign === -1) {
          skillsService
            .postSkill(constants, nudoss, editedSkill)
            .then(res => {
              console.log("createSkill res", res);
              toastService.throwSuccess();
              const newSkills: IEditableSkill[] = state.skills.map(e => (e.nulign === editedSkill.nulign ? { ...e, editing: false, nulign: res.nulign } : { ...e }));
              setState({ ...state, skills: newSkills });
            })
            .catch(err => {
              console.log("createSkill err", err);
              toastService.throwError(err.response.data.description);
            });
        } else {
          skillsService
            .updateSkill(constants, nudoss, editedSkill.nulign, editedSkill)
            .then(res => {
              console.log("updateSkill res", res);
              toastService.throwSuccess();
              const editedSkills: IEditableSkill[] = state.skills.map(e => (e.nulign === editedSkill.nulign ? { ...e, editing: false } : { ...e }));
              setState({ ...state, skills: editedSkills });
            })
            .catch(err => {
              console.log("updateSkill err", err);
              toastService.throwError(err.response.data.description);
            });
        }
      }
    }
  };

  return (
    <div className="edit-section-container">
      <div className="first-subsection">
        <Subtitle label="label.my-skills" />
        <ButtonDefault icon="plus" onClick={addSkill} color="secondary" size="small" />
      </div>

      <div className="edit-skills-grid">
        {state.skills.map(e => (
          <EditableSkillCard skill={e} onSave={onSaveSkill} onDelete={onDeleteSkill} />
        ))}
      </div>
    </div>
  );
};

export default SkillsEditSection;
