import axios from "axios";
import constants from '../../constants';

const validateToken = async (endpoint: string, token: string): Promise<boolean> => {
  console.log('endpoint:', endpoint)
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-auth/${endpoint}/${token}`);
  if (res.status === 200) {
    return true;
  } else {
    return false;
  }
};

export default validateToken;
