import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import FeedLayout from "../components/layout/FeedLayout";
import staffService from "../services/staff";
import IJob from "../models/IJob";
import Loading from "../components/loading/Loading";
import FeedJobItem from "../components/FeedJobItem/FeedJobItem";
import ButtonDefault from "../components/button/ButtonDefault";
import TextField from '@material-ui/core/TextField';
import { parse, compareAsc, compareDesc } from "date-fns";
import useConfig from "../custom-hooks/useConfig";
import useUser from "../custom-hooks/useUser";
import { dateToLocale } from "../utils/time/time";
import { SelectOptionDefault } from "../models/SelectOption";
import useReduce from "../custom-hooks/useReduce";
import SelectAutoComplete from "../components/select/SelectAutoComplete";
import { FormattedMessage } from "react-intl";
import isEmpty from "../validators/isEmpty";

interface FilterState {
  name: string;
  time: string;
  country: string;
  area: string;
}

interface SubmissionsPageState {
  loading: boolean;
  jobs: IJob[];
  filteredJobs: IJob[];
  filter: FilterState;
}

const initialState: SubmissionsPageState = {
  loading: false,
  jobs: [],
  filteredJobs: [],
  filter: {
    name: "",
    time: "",
    area: "",
    country: "",
  },
};

const MySubmissionsPage: FunctionComponent = (): ReactElement => {
  const { constants } = useConfig();
  const { user } = useUser();
  const [state, setState] = useState<SubmissionsPageState>(initialState);

  //Localizations select
  let localizations: SelectOptionDefault[] = [];
  state.jobs.forEach(e => {
    if (e.location) localizations.push({ value: e.location, label: e.location });
  })

  localizations = useReduce(localizations);
  const onFilterResults = () => {
    const filtered: IJob[] = state.jobs
      .filter(e => e.areaLabel.toLowerCase().indexOf(state.filter.area.toLowerCase().trim()) > -1)
      .filter(e => e.location.toLowerCase().indexOf(state.filter.country.toLowerCase().trim()) > -1)
      .filter(e => e.typeTime.toLowerCase().indexOf(state.filter.time.toLowerCase().trim()) > -1)
    setState({ ...state, filteredJobs: filtered });
  };

   //Areas select
   let areas: SelectOptionDefault[] = [];
   state.jobs.forEach((e: IJob) => {
     if (e.areaLabel) areas.push({ value: e.areaLabel, label: e.areaLabel });
   });
   areas = useReduce(areas);

  useEffect(() => {
    if (user && user.nudoss) {
      setState({ ...state, loading: true });
      staffService
        .getStaffPositionApplies(constants, user.nudoss)
        .then((jobs: IJob[]) => {
          setState({ ...state, loading: false, jobs, filteredJobs: jobs });
        })
        .catch(err => {
          setState({ ...state, loading: false });
        });
    }

    setState({ ...state, loading: false });
  }, []);

  return (
    <FeedLayout>
      <div className="grid-filters-container">    
        <SelectAutoComplete
          options={areas}
          name="label.area"
          value={state.filter.area}
          onChange={e => setState({ ...state, filter: { ...state.filter, area:  !isEmpty(e.label) ? e.label : ""  } })}
          withSearch
          outlined
        />
        <SelectAutoComplete
          options={localizations}
          name="label.location"
          value={state.filter.country}
          onChange={e => setState({ ...state, filter: { ...state.filter, country:  !isEmpty(e.label) ? e.label : ""  } })}
          withSearch
          outlined
        />
        <SelectAutoComplete
          options={[{ value: "F", label: "Tempo pieno" }, { value: "P", label: "Tempo parziale" }]}
          name="label.timetable"
          value={state.filter.time}
          onChange={e => setState({ ...state, filter: { ...state.filter, time:  !isEmpty(e.label) ? e.label : "" } })}
          translated
          outlined
        />
        <ButtonDefault text="action.filter" fullWidth marginTop onClick={onFilterResults} color="primary" size="standard" />
      </div>
      {state.loading ? (
        <Loading />
      ) : (
        <div className="grid-card-container">
          {state.filteredJobs.map(e => (
            <FeedJobItem id={e.id} name={e.name} company={e.companyLabel} startDate={e.startDate} endDate={e.endDate} country={e.countryLabel} location={e.location} description={e.description} />
          ))}
        </div>
      )}
    </FeedLayout>
  );
};

export default MySubmissionsPage;
