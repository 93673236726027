import React, { ReactNode } from "react";
import classnames from 'classnames';
import "./Backdrop.scss";

interface BackdropProps {
  showing: boolean,
  children: ReactNode,
  onClick: Function,
}

const Backdrop = ({ showing, children, onClick }: BackdropProps) => (
  <React.Fragment>
    <div className={classnames(
      "backdrop",
      { "display-block": showing },
      { "display-none": !showing },
    )}
      onClick={() => onClick()}
    />
    <div className={showing ? "backdrop-blur" : ""}>
      {children}
    </div>
  </React.Fragment>
);

Backdrop.defaultProps = {
  showing: false,
  children: <React.Fragment />,
  onClick: () => {},
};

export default Backdrop;
