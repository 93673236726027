import classnames from 'classnames';
import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

interface TextCheckBoxProps {
    label?: string,
    readOnly?: boolean,
    onClick?: Function;
    value: boolean;
    light?: boolean
}

interface TextCheckBoxState {
    checked: boolean,
}

const TextCheckBox: FunctionComponent<TextCheckBoxProps> = ({ value, label, readOnly, onClick, light }): ReactElement => {
    const [state, setState] = useState<TextCheckBoxState>({ checked: value })

    const handleCheckboxChange = () => {
        if (!readOnly && onClick) {
            setState({ checked: !state.checked });
            onClick(!state.checked);
        }
    }

    useEffect(() => {
        setState({ checked: value })
    }, [value])

    return (
        <div className="flex-row-center margin-right">
            <div className=" check-box-container">
                <input type="checkbox" checked={state.checked}
                    disabled={readOnly} onChange={e => handleCheckboxChange()} />
            </div>
            {label && (
                <p className={classnames("text-standard",
                    { "text-bold": !light },
                    { "text-standard text-grey text-italic": light },
                    "text-medium-size")}>
                    <FormattedMessage id={label} />
                </p>)
            }
        </div >
    )
}

export default TextCheckBox;
