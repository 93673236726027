import React, { ReactElement, FunctionComponent } from "react";
import classnames from "classnames";
import "./backgroundCardImage.scss";

interface BackgroundCardImageProps {
  children?: ReactElement | ReactElement[];
}

const BackgroundCardImage: FunctionComponent<BackgroundCardImageProps> = (props): ReactElement => {
  return <div className={classnames("background-card-image")}>{props.children}</div>;
};


export default BackgroundCardImage;
