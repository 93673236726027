import React, { FunctionComponent, useEffect, useState } from "react";
import useConfig from "../../custom-hooks/useConfig";
import IEducation from "../../models/IEducation";
import educationsService from "../../services/educations";
import toastService from "../../services/toasts";
import { formatDate, formatDateToStandard } from "../../utils/time/time";
import Subtitle from "../titles/Subtitle";
import EditableEducationCard from "./EditableEducationCard";
import { EditSectionProps } from "./interfaces/EditSectionProps";
import { IEditableEducation } from "./interfaces/IEditable";

interface EducationsEditSectionState {
  educations: IEditableEducation[];
}

const EducationsEditSection: FunctionComponent<EditSectionProps<IEducation>> = ({ nudoss, updated }) => {
  const { constants } = useConfig();
  const [state, setState] = useState<EducationsEditSectionState>({ educations: [] });
  const [loading, setLoading] = useState(false);
  const [newEducation, setNewEducation] = useState<EducationsEditSectionState>({ educations: [] });


  useEffect(() => {
    if (nudoss) {
      educationsService
        .getEducations(constants, nudoss)
        .then((newEdu: IEducation[]) => {
          // newEdu = _.orderBy(newEdu, ['nulign'], ['desc']);
          setState({ ...state, educations: newEdu.map(e => ({ ...e, editing: false, startDate: formatDateToStandard(e.startDate), endDate: formatDateToStandard(e.endDate) })) });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [nudoss, loading]);

  const addEdu = () => {
    const newEdu: IEditableEducation = {
      nulign: -1,
      degree: "",
      degreeLabel: "",
      titleName: "",
      majorDomain: "",
      majorDomainArea: "",
      majorDomainAreaLabel: "",
      minorDomain: "",
      minorDomainArea: "",
      minorDomainAreaLabel: "",
      startDate: "",
      state: "",
      stateLabel: "",
      endDate: "",
      editing: true,
    };
    // setNewEducation({ educations: [...newEducation.educations, newEdu] });
    setNewEducation({ educations: [newEdu] });
  };

  useEffect(() => {
    addEdu();
  }, []);

  const onDeleteEdu = (nulign: number) => {
    setLoading(true)
    if (nudoss) {
      if (nulign === -1) {
        setNewEducation({ educations: [] });
      } else {
        const deletedEducation: IEditableEducation | undefined = state.educations.find(e => e.nulign === nulign);
        if (deletedEducation) {
          educationsService
            .deleteEducation(constants, nudoss, deletedEducation.nulign)
            .then(res => {
              console.log("deleteEducation res", res);
              toastService.throwSuccess();
              updated(true);
              setLoading(false);
            })
            .catch(err => {
              console.log("deleteEducation err", err);
              toastService.throwError(err.response.data.description);
            });
        }
      }
    }

  };

  const onSaveEdu = (edu: IEducation) => {
    if (nudoss) {
      setLoading(true)
      const editedEducation: IEducation | undefined = state.educations.find(e => e.nulign === edu.nulign);
      if (editedEducation) {
        editedEducation.nulign = edu.nulign;
        editedEducation.degree = edu.degree;
        editedEducation.degreeLabel = edu.degreeLabel;
        editedEducation.titleName = edu.titleName;
        editedEducation.majorDomain = edu.majorDomain;
        editedEducation.majorDomainArea = edu.majorDomainArea;
        editedEducation.majorDomainAreaLabel = edu.majorDomainAreaLabel;
        editedEducation.minorDomain = edu.minorDomain;
        editedEducation.minorDomainArea = edu.minorDomainArea;
        editedEducation.minorDomainAreaLabel = edu.minorDomainAreaLabel;
        editedEducation.startDate = formatDate(edu.startDate);
        editedEducation.state = edu.state;
        editedEducation.stateLabel = edu.stateLabel;
        editedEducation.endDate = formatDate(edu.endDate);


        educationsService
          .updateEducation(constants, nudoss, editedEducation.nulign, editedEducation)
          .then(res => {
            console.log("updateEducation res", res);
            toastService.throwSuccess();
            updated(true);
            setLoading(false);
          })
          .catch(err => {
            console.log("updateEducation err", err);
            toastService.throwError(err.response.data.description);
          });

      }
    }
  }

  const onSaveNewEdu = (edu: IEducation) => {
    if (nudoss) {
      setLoading(true)
      const newEdu: IEducation | undefined = newEducation.educations.find(e => e.nulign === edu.nulign);
      if (newEdu) {
        newEdu.nulign = edu.nulign;
        newEdu.degree = edu.degree;
        newEdu.degreeLabel = edu.degreeLabel;
        newEdu.titleName = edu.titleName;
        newEdu.majorDomain = edu.majorDomain;
        newEdu.majorDomainArea = edu.majorDomainArea;
        newEdu.majorDomainAreaLabel = edu.majorDomainAreaLabel;
        newEdu.minorDomain = edu.minorDomain;
        newEdu.minorDomainArea = edu.minorDomainArea;
        newEdu.minorDomainAreaLabel = edu.minorDomainAreaLabel;
        newEdu.startDate = formatDate(edu.startDate);
        newEdu.state = edu.state;
        newEdu.stateLabel = edu.stateLabel;
        newEdu.endDate = formatDate(edu.endDate);

        educationsService
          .postEducation(constants, nudoss, newEdu)
          .then(res => {
            console.log("createEducation res", res);
            toastService.throwSuccess();
            updated(true);
            setLoading(false);
            // setTimeout(() => {
            //   setNewEducation({ educations: [] });
            // }, 500);
            addEdu();
          })
          .catch(err => {
            console.log("createEducation err", err);
            toastService.throwError(err.response.data.description);
          });
      }

    }
  }

  return (
    <div className="edit-section-container">
      <div className="first-subsection">
        <Subtitle label="label.education" mandatory />
        {/* <ButtonDefault icon="plus" onClick={addEdu} color="secondary" size="small" /> */}
      </div>
      {state.educations.length === 0 && newEducation.educations.map(e => (
        <EditableEducationCard education={e} onSave={onSaveNewEdu} />
      ))}
      {state.educations.map(e => (
        <EditableEducationCard education={e} onSave={onSaveEdu} onDelete={onDeleteEdu} />
      ))}
    </div>
  );
};

export default EducationsEditSection;
