
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { AuthContext } from "../../context";
import useConfig from "../../custom-hooks/useConfig";
import IUser from "../../models/IUser";
import employeeService from "../../services/employees";
import toastService from "../../services/toasts";
import { formatDateToStandard } from "../../utils/time/time";
import TextCheckBox from "../input/TextCheckBox";
import Modal from "../modal/Modal";
import { showModal } from "../modal/ModalListener";
import './PrivacyPolicy.scss';

const showTermsModal = () => {
    showModal(<Modal>
        <div className="flex-column-start margin-top" style={{ fontFamily: "Geneva" }}>
            {/* <Subtitle label="label.privacy-policy" /> */}
            <div className="margin-bottom">
                <p>
                    Art. 13 del Regolamento UE 679/2016 Regolamento generale sulla protezione dei dati
                </p>
            </div>

            <div className="margin-bottom">
                <p className="text-bold">
                    a) Chi tratta i Suoi dati personali:
                </p>
                <p>
                    le società di gruppo pam: sistema inserendo queste società
                </p>
                <ul style={{ margin: "1rem 0" }}>
                    <li>1) Gecos S.p.A. con sede legale in Venezia, San Marco 5278 e sede operativa in Spinea (VE), Via del Commercio</li>
                    <li>2) Gruppo PAM S.p.A. con sede legale in Venezia, San Marco 5278 e sedi operative in Spinea (VE), Via delle Industrie e Via del Commercio </li>
                    <li>3) Pam Panorama S.p.A., con sede legale in Venezia, San Marco 5278</li>
                    <li>4) IN's Mercato S.p.a., con sede legale in Venezia (VE), via Santa Maria della Pietà, n. 6</li>
                    <li>5) Pam Franchising S.p.A., con sede legale in Venezia, San Marco 5278</li>
                    <li>6) Nuova Discount S.r.l. con sede legale in Venezia (VE), via Santa Maria della Pietà, n. 6</li>
                </ul>
                <p>
                    in qualità di Contitolari del trattamento (di seguito, congiuntamente, i “Contitolari” o le
                    “Società”), sono le società che raccolgono e trattano i Suoi dati personali, ai sensi del
                    Regolamento UE 679/2016 Regolamento generale sulla protezione dei dati (il “Regolamento”).
                </p>
            </div>

            <div style={{ marginBottom: "1rem" }}>
                <p className="text-bold">
                    b) Data Protection Officer (DPO):
                </p>
                <p>
                    Il Responsabile della protezione dei dati designato ai sensi dell'art. 37 del Regolamento è
                    contattabile
                    all'indirizzo di posta <a href="mailto:responsabileprotezionedati@gruppopam.it">responsabileprotezionedati@gruppopam.it</a>.
                </p>
            </div>

            <div style={{ marginBottom: "1rem" }}>
                <p className="text-bold">
                    b) Perché trattiamo i Suoi dati:
                </p>
                <p>
                    I dati che ci sta fornendo mediante la compilazione del presente modulo elettronico, il caricamento del Suo
                    CV
                    ed eventualmente i Suoi dati appartenenti a categorie particolari (es. appartenenza a categorie protette)
                    contenuti in quest' ultimo ci sono necessari per:
                </p>
                <ul>
                    <li>1) Creare il Suo account personale, consentendole di procedere alla candidatura per una posizione lavorativa aperta attualmente e futura mediante il caricamento del Suo Curriculum Vitae</li>
                    <li>2) Consentirci di gestire la sua candidatura nell'ambito dell'attività di attività di ricerca e selezione del personale</li>
                </ul>
                <p>
                    La
                    <span onClick={() => showBaseGiruidicaModal()} style={{ cursor: 'pointer', color: '#0000EE', textDecoration: 'underline' }}>
                        base giuridica
                    </span> del
                    trattamento dei Suoi dati saranno l'esecuzione di misure precontrattuali
                    adottate
                    su sua richiesta, nonché il Suo eventuale consenso (limitatamente al trattamento di eventuali categorie
                    particolari di dati personali contenute nel Suo CV).
                </p>
            </div>

            <div style={{ marginBottom: "1rem" }}>
                <p className="text-bold">
                    c) Il Suo consenso:
                </p>
                <p>
                    Per poter trattare eventuali categorie particolari di dati personali contenute nel Suo CV (es. appartenenza
                    a
                    categorie protette) e dunque consentirci di gestire la sua candidatura nell'ambito
                    dell'attività di
                    attività di ricerca e selezione del personale abbiamo bisogno del Suo esplicito consenso. Il Suo
                    consenso
                    è liberamente revocabile, senza pregiudizio della liceità del trattamento precedente alla
                    revoca,
                    anche semplicemente accedendo alla sua area personale, sezione HR-Jobsite.
                </p>
                <p>
                    Precisiamo che, in caso di revoca del consenso per il trattamento di eventuali categorie particolari di dati
                    personali contenute nel Suo CV, provvederemo a cancellare i relativi dati.
                </p>
            </div>

            <div className="margin-bottom">
                <p className="text-bold">
                    d) I Suoi dati:
                </p>
                <p>
                    Le chiediamo di fornire tutti i dati richiesti come “obbligatori” perché necessari alla registrazione e
                    conseguentemente alla gestione della sua candidatura; in caso di mancata o incompleta compilazione, la
                    registrazione non potrà essere completata. La mancata compilazione dei dati indicati come
                    “facoltativi”
                    non comporterà alcuna conseguenza.
                </p>
                <p>
                    Tra i dati personali da Lei forniti ai suddetti fini mediante il caricamento del Suo CV ve ne potranno
                    essere
                    alcuni qualificati come categorie particolari di dati personali, in quanto idonei a rivelare lo stato di
                    salute,
                    l'appartenenza a particolari categorie protette, l'iscrizione a sindacati, il motivo di esonero dal
                    servizio
                    militare, ecc. In questa fase di ricerca e selezione del personale i Contitolari hanno necessità di
                    raccogliere unicamente il dato relativo all' eventuale appartenenza a categorie protette; pertanto, la
                    invitiamo
                    a non inserire nel Suo Curriculum Vitae altre categorie particolari di dati personali, con l'
                    avvertenza che
                    qualora fossero indicati questi saranno immediatamente cancellati.
                </p>
            </div>

            <div className="margin-bottom">
                <p className="text-bold">
                    e) Come trattiamo i Suoi dati:
                </p>
                I Suoi dati saranno trattati prevalentemente in via informatizzata per gestire la sua registrazione e la sua
                candidatura, nel rispetto della normativa vigente e, comunque, in modo da garantirne la sicurezza e la
                riservatezza
                ed impedirne la divulgazione o l'uso non autorizzati, l'alterazione o la distruzione.
            </div>


            <div className="margin-bottom">
                <p className="text-bold">
                    f) Per quanto tempo conserviamo i Suoi dati:
                </p>
                <p>
                    I Suoi dati saranno trattati per tutta la durata di validità della sua registrazione. Ad ogni modo i
                    Suoi
                    dati personali non verranno conservati per un periodo superiore a 8 anni dalla ricezione o dall'ultimo
                    aggiornamento, dopodiché le verrà inviata una comunicazione in cui le verrà richiesto di
                    aggiornare i Suoi dati. Qualora non dovesse procedere all'aggiornamento, i medesimi dati verranno distrutti
                    o
                    resi anonimi.
                </p>
            </div>

            <div className="margin-bottom">
                <p className="text-bold">
                    g) Da chi sono trattati i Suoi dati:
                </p>
                <p>
                    I Suoi dati non saranno diffusi. Per gestire la sua registrazione alla piattaforma e la sua candidatura i
                    Suoi
                    dati personali potranno essere comunicati a società esterne di selezione del personale della cui
                    collaborazione i Contitolari potranno avvalersi.
                </p>
                <p>
                    I Suoi dati verranno inoltre a conoscenza (limitatamente al rispettivo ambito di competenza): (i) delle
                    persone
                    autorizzate al trattamento della Direzione Risorse Umane di Pam Panorama S.p.A. e della società del
                    Gruppo Pam, (ii) degli Uffici/Aree delle Società di volta in volta interessati alla sua figura
                    professionale, nonché, (iii) della società Gruppo Pam S.p.A., responsabile della gestione dei nostri
                    sistemi informativi.
                </p>
            </div>

            <div className="margin-bottom">
                <p className="text-bold">
                    h) Trasferimenti al di fuori dell'Unione Europea:
                </p>
                <p>
                    Per le finalità sopra indicate i Suoi dati personali non saranno oggetto di trasferimento verso Paesi non appartenenti all'Unione Europea.
                </p>
            </div>


            <div className="margin-bottom">
                <p className="text-bold">
                    i) Quali sono i Suoi diritti:
                </p>
                <p>
                    Mediante comunicazione da inviarsi all'indirizzo e-mail <a href="mailto:direzionerisorseumane@gruppopam.it">direzionerisorseumane@gruppopam.it</a>, Lei
                    potrà in
                    ogni momento esercitare i <span onClick={() => showDirittiModal()} style={{ cursor: 'pointer', color: '#0000EE', textDecoration: 'underline' }}>
                        diritti
                    </span> di cui agli artt. da 15 a 22 del
                    Regolamento, tra cui conoscere
                    quali
                    dati stiamo trattando, con quali modalità e per quali finalità li utilizziamo, modificare i
                    dati
                    che ci ha fornito o cancellarli, chiederci di limitare l'uso dei Suoi dati, richiedere di ricevere o
                    trasmettere
                    ad altro titolare i Suoi dati, ferma restando sempre la possibilità di cambiare o revocare i Suoi
                    consensi.
                </p>
            </div>

            <div className="margin-bottom">
                <p className="text-bold">
                    j) A chi può rivolgersi per proporre un reclamo:
                </p>
                <p>
                    Le ricordiamo che, qualora ritenga che il trattamento che la riguarda violi le disposizioni di cui al
                    Regolamento, può sempre proporre reclamo all'autorità <a href="https:/www.garanteprivacy.it" target="_blank">(www.garanteprivacy.it)</a>,
                    oppure all'autorità Garante del Paese in cui risiede abitualmente, lavora
                    oppure
                    del luogo ove si è verificata la presunta violazione.
                </p>
            </div>
        </div>
    </Modal>);
};

const showBaseGiruidicaModal = () => {
    showModal(<Modal><div className="margin">
        <p className="margin-bottom">
            Il trattamento dei Suoi dati:
        </p>
        <div>
            <li>
                per i dati comuni, avverrà per la gestione della sua candidatura senza necessità del Suo consenso, ai
                sensi dell’art. 6, comma 1, lett. c) del Regolamento
            </li>
            <li>
                per le categorie particolari di dati personali, si baserà sul consenso da Lei fornito, in conformità
                all’art. 9, comma 2, lett. a) del Regolamento
            </li>
        </div>
        <p className="margin-top">
            In ogni caso il trattamento avverrà in conformità alle prescrizioni di cui di cui alla vigente normativa in
            materia di diritto del lavoro e della sicurezza e protezione sociale, medicina del lavoro, in esecuzione del
            contratto di lavoro con Lei concluso, nonché nel rispetto dei provvedimenti vigenti – e delle loro successive
            modifiche e/o integrazioni - emessi in materia dall’Autorità Garante per la Protezione dei dati personali (fra i
            quali Linee guida in materia di trattamento di dati personali di lavoratori per finalità di gestione del
            rapporto di lavoro alle dipendenze di datori di lavoro privati) e dal Gruppo di lavoro costituito ex Articolo 29
            della Direttiva 95/46 CE (tra cui Opinion 2/2017 on data processing at work).
        </p>
    </div></Modal>)
}

const showDirittiModal = () => {
    showModal(<Modal> <div className="margin">
        <p className="margin-bottom">
            Gli artt. da 15 a 22 del Regolamento riconoscono specifici diritti tra cui:
        </p>
        <div>
            <li>ottenere la conferma che sia o meno in corso un trattamento di dati personali che La riguardano</li>
            <li>ottenere l'accesso ai Suoi dati personali ed alle informazioni indicate all’art. 15 del Regolamento</li>
            <li>ottenere la rettifica dei dati personali inesatti che La riguardano senza ingiustificato ritardo o
                l'integrazione dei dati personali incompleti</li>
            <li>ottenere la cancellazione dei dati personali che La riguardano senza ingiustificato ritardo</li>
            <li>ottenere la limitazione del trattamento dei dati personali che La riguardano</li>
            <li>essere informato delle eventuali rettifiche o cancellazioni o limitazioni del trattamento effettuate in
                relazione ai dati personali che La riguardano</li>
            <li>ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che
                La riguardano</li>
            <li>opporsi in qualsiasi momento, per motivi connessi alla sua situazione particolare, al trattamento dei dati
                personali che La riguardano.</li>
        </div>
        <p>
            Resta in particolare fermo il Suo diritto di revocare in qualsiasi momento i consensi al trattamento
            eventualmente forniti.
        </p>
    </div></Modal>)
}

interface EditUserDataProps {
    nudoss: string | number | undefined;
}

interface EditUserDataState {
    loading: boolean;
    user: IUser;
    birthDate: string;
}

const ReadOnlyPrivacyPolicy: FunctionComponent<EditUserDataProps> = ({ nudoss }) => {

    const [privacy, setPrivacy] = useState(false);
    const [consent, setConsent] = useState(false);
    const { setAuthContextState } = useContext(AuthContext);
    const { constants } = useConfig();
    const history = useHistory();

    const [state, setState] = useState<EditUserDataState>({
        loading: false,
        user: { nudoss: nudoss, matcle: "", firstName: "", surname: "", domProvDescription: "", domProv: "", domCity: "", resProvDescription: "", resProv: "", resCity: "", dateOfBirth: 0, gender: "", email: "", location: "", phone: "", description: "", firstJob: true, userPrivacy: { acceptanceDate: "", accepted: false, protectedCategoryIsAccepted: false, application: "" } },
        birthDate: "",
    });

    useEffect(() => {
        setState({ ...state, loading: true });
        if (nudoss) {
            employeeService
                .getProfileInformation(constants, nudoss)
                .then((u: IUser) => {
                    setState({ ...state, loading: false, user: u, birthDate: formatDateToStandard(u.dateOfBirth) });
                })
                .catch(err => {
                    console.log(err);
                    setState({ ...state, loading: false });
                });
        }
    }, [nudoss]);

    const updateUserData = () => {
        const newUser = { ...state.user };
        // newUser.userPrivacy.accepted = privacy;
        // newUser.userPrivacy.protectedCategoryIsAccepted = consent;
        if (state.user && nudoss) {
            employeeService
                .updateUserProfile(constants, nudoss, newUser)
                .then(res => {
                    toastService.throwSuccess();
                    setAuthContextState?.({ checked: true, isAuth: true });
                })
                .catch(err => {
                    toastService.throwError(err.response.data.description);
                    console.log("updateUserData err", err);
                });
        }
    };



    return (
        <React.Fragment>
            <div className="policy-box" >
                <div className="flex-row-space-between ">
                    <div>
                        <div className="margin-bottom flex-row-start">
                            <TextCheckBox label={"note.privacy-policy"}
                                onClick={() => {
                                    setPrivacy(prevState => !prevState);
                                    setState({ ...state, user: { ...state.user, userPrivacy: { ...state.user.userPrivacy, accepted: !state.user.userPrivacy.accepted } } })
                                }}
                                readOnly
                                value={state.user.userPrivacy.accepted} />
                            <p onClick={() => showTermsModal()} style={{ cursor: 'pointer', color: '#0000EE', textDecoration: 'underline' }}>
                                <FormattedMessage id="label.informativa-privacy" />
                            </p>
                        </div>
                        <div className="margin-bottom">
                            <TextCheckBox label={"note.second-privacy-policy"}
                                onClick={() => {
                                    setConsent(prevState => !prevState);
                                    setState({ ...state, user: { ...state.user, userPrivacy: { ...state.user.userPrivacy, protectedCategoryIsAccepted: !state.user.userPrivacy.protectedCategoryIsAccepted } } })
                                }}
                                value={state.user.userPrivacy.protectedCategoryIsAccepted}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ReadOnlyPrivacyPolicy;
