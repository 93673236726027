import React, { ReactNode, useContext, useState } from 'react';
import { WindowSizeContext } from '../../context';
import Icon from '../icon/Icon';
import './Alert.scss';

interface IInffo {
  title: string,
  text?: string
}

interface AlertProps {
  info: IInffo,
  type: string | "warning" | "info" | "success" | "danger",
  position: string | "right" | "left",
  children?: ReactNode,
  fixed?: boolean
}

const Alert = ({ info, type, position, children, fixed }: AlertProps) => {

  const { isMobile } = useContext(WindowSizeContext);
  const [showAlert, setShowAlert] = useState(false);

  const positionClass = position ? `alert-${position}` : "";
  return (
    isMobile ?
      <React.Fragment>
        <div>
          <Icon name="exclamation-circle" style={{ color: `var(--colour-${type})` }} onClick={() => setShowAlert(prev => !prev)} />
        </div>
        <div style={{ position: fixed ? 'absolute' : 'relative', display: showAlert ? 'block' : 'none' }} >
          <div className={`alert alert-${type} ${positionClass}`}>
            <div className="flex-row-center">
              {/* <Icon name="exclamation-circle" /> */}
              <strong>{info.title}</strong>
            </div>
            <hr />
            {children ?
              children :
              <p>{info.text}</p>
            }
          </div>
        </div>
      </React.Fragment>
      :
      <div style={{ position: fixed ? 'absolute' : 'relative' }} >
        <div className={`alert alert-${type} ${positionClass}`}>
          <div className="flex-row-center">
            {/* <Icon name="exclamation-circle" /> */}
            <strong>{info.title}</strong>
          </div>
          <hr />
          {children ?
            children :
            <p>{info.text}</p>
          }
        </div>
      </div>
  )
};

Alert.defaultProps = {
  info: undefined,
  type: "warning",
  position: ""
}

export default Alert;
