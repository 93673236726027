import axios from "axios";
import faker from "faker";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import authHeaders from "../auth/authHeaders";

const getPostionApplicants = async (constants: ConstantsConfig, idPosition: string): Promise<number> => {
  if (constants.useMockData) return faker.random.number();
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/positions/${idPosition}/applicants`, { headers: authHeaders(true) });
  return res.data;
};

export default getPostionApplicants;
