import { REFUSED } from "dns";
import React, { FunctionComponent, ReactElement, Ref, RefObject, useEffect, useRef, useState } from "react";

interface ScrolltoTopProps  {
    scrollRef: any;
}

const ScrollToTop: FunctionComponent<ScrolltoTopProps> = (scrollRef): ReactElement => {
    const scrollToRef = (ref: any) => {
        if (ref && ref.current) {ref.current.scrollTop = 0};
    }   
   
    const executeScroll = () => {
        const currentRef = scrollRef.scrollRef;
        if (currentRef) scrollToRef(currentRef);
    }

    return ( 
        <div className="profile-button" > 
            <div className="flex-row-start text-white cursor-pointer full-width full-height" onClick={executeScroll} >
                <div className="standard-button secondary-button">
                    <i className="fas fa-hand-point-up" />
                </div>
            </div>
        </div>)
}

export default ScrollToTop;
