import React, { ReactElement, FunctionComponent } from "react";
import { ConstantsConfig, defaultConfig } from "../models/ConstantsConfig";


export interface ConfigContextState {
  constants: ConstantsConfig;
}

const initialState: ConfigContextState = {
  constants: defaultConfig,
};

export const ConfigContext: React.Context<ConfigContextState> = React.createContext<ConfigContextState>(initialState);

export const ConfigContextProvider: FunctionComponent<ConfigContextState> = ({ children, constants }): ReactElement => {
  return <ConfigContext.Provider value={{ constants }}>{children}</ConfigContext.Provider>;
};
