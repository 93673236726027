import moment from 'moment';
import { format } from 'date-fns';

const minutesToTimeString = (totalMinutes = undefined) => {
  if (!totalMinutes) return totalMinutes;
  if (totalMinutes > 60) {
    const hoursDiv = Math.floor(totalMinutes / 60).toString();
    const hours = hoursDiv.length !== 2 ? `0${hoursDiv}`: hoursDiv;
    const minutesMod = (totalMinutes % 60).toString();
    const minutes = minutesMod.length !== 2 ? `0${minutesMod}`: minutesMod;
    return `${hours}:${minutes}`;
  }
  const minutes = totalMinutes.length !== 2 ? `0${totalMinutes}`: totalMinutes;
  return `00:${minutes}`;
};

export function minutesToTime(totalMinutes = undefined) {
  if (!totalMinutes) return totalMinutes;
  if (!isNaN(totalMinutes)) { // it is a number
    return minutesToTimeString(totalMinutes);
  }
  const matches = /^\d\d:\d\d/.test(totalMinutes);
  if (matches) {
    const [hours, minutes] = totalMinutes.split(":").map(e => parseInt(e, 0));
    const hourMinutes = hours * 60;
    const tminutes = hourMinutes + minutes;
    return minutesToTimeString(tminutes);
  }
  return totalMinutes;
}

export function dateToLocale(date = undefined, locale = 'es') {
  if (date) {
    return new Date(date).toLocaleDateString(locale);
  }
  return new Date().toLocaleDateString(locale);
}

/**
 * Format or create a date in "DD/MM/YYYY"
 * @param {string | number} date 
 */
export function formatDateToStandard(date = undefined) {
  return moment(new Date(date)).format("DD/MM/YYYY");
}

export function dateToMilliseconds(date = undefined,) {
  if (date) {
    return new Date(date).getTime();
  }
  return new Date().getTime();
}

export function dateToLocaleNumbered(date = undefined) {
  if (date) {
    return format(date, 'yyyy/MM/dd', { awareOfUnicodeTokens: true });
  }
  return format(new Date(), 'yyyy/MM/dd', { awareOfUnicodeTokens: true });
}

export function timeToMinutes(hours) {
  if (hours && hours.match('^(2[0-3]|[01]?[0-9]):([0-5][0-9])$')) {
    const array_hours = hours.split(':');
    const hours_ = parseInt(array_hours[0]);
    const minutes_ = parseInt(array_hours[1]);

    return (hours_ * 60 + minutes_).toString();
  }
  return hours;
}

export function formatDate(value) {
  if (!value) return '';
  if (/^([0-3]?[0-9])\/([0-1]?[0-9])\/\d{4}/.test(value)) {
    return moment(value, 'DD/MM/Y').format('Y-MM-DD');
  }
  return value;
}

export const getYearString = (year = undefined) => {
  const date = new Date();
  if (year) {
    return `01/01/${year}`;
  }
  return `01/01/${date.getFullYear()}/`;
};

export const allYearOptions = (year, lowerMargin = 20, upperMargin = 20) => {
  const yearOptions = [];
  for (let index = year - lowerMargin; index < (year + upperMargin); index++) {
    yearOptions.push({ value: getYearString(index), label: index.toString() });
  }

  return yearOptions;
};

export const getYearMonthString = (month,year = undefined) => {
  const date = new Date();
  if (year) {
    return month<10?`01/0${month}/${year}`:`01/${month}/${year}`;
    
  }
  return `${date.getFullYear()}/${month}/01/`;
};

export const allYearMonthOptions = (year, lowerMargin = 20) => {
  const yearOptions = [];
  
  for (let index = year; index > year - lowerMargin; index--) {
    for (let i = 12; i >= 1; i--) {
    yearOptions.push({ value: getYearMonthString(i,index), label:i<10?`0${i}/${index}`:`${i}/${index}` });
    }
  }

  return yearOptions;
};

export const allMonthOptions = () => {
  return [
    { value: "01", label: "january" },
    { value: "02", label: "february" },
    { value: "03", label: "march" },
    { value: "04", label: "april" },
    { value: "05", label: "may" },
    { value: "06", label: "june" },
    { value: "07", label: "july" },
    { value: "08", label: "august" },
    { value: "09", label: "september" },
    { value: "10", label: "october" },
    { value: "11", label: "november" },
    { value: "12", label: "december" },
  ];

}

