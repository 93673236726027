/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from "react";
import { parse, compareAsc, compareDesc } from "date-fns";
import FeedLayout from "../components/layout/FeedLayout";
import IJob from "../models/IJob";
import Loading from "../components/loading/Loading";
import FeedJobItem from "../components/FeedJobItem/FeedJobItem";
import ButtonDefault from "../components/button/ButtonDefault";
import TextField from '@material-ui/core/TextField';
import useConfig from "../custom-hooks/useConfig";
import positionService from "../services/position";
import { dateToLocale } from "../utils/time/time";
import useReduce from "../custom-hooks/useReduce";
import { SelectOption, SelectOptionDefault } from "../models/SelectOption";
import optionsService from "../services/options";
import SelectAutoComplete from "../components/select/SelectAutoComplete";
import { FormattedMessage } from "react-intl";
import isEmpty from "../validators/isEmpty";

interface FilterState {
  name: string;
  time: string;
  country: string;
  area: string;
}

interface FeedPageState {
  loading: boolean;
  jobs: IJob[];
  filteredJobs: IJob[];
  filter: FilterState;
}

const initialState: FeedPageState = {
  loading: false,
  jobs: [],
  filteredJobs: [],
  filter: {
    name: "",
    time: "",
    area: "",
    country: "",
  },
};

const FeedPage: FunctionComponent = (): ReactElement => {
  const { constants } = useConfig();
  const [state, setState] = useState<FeedPageState>(initialState);
  console.log('jobs:', state.jobs)
  console.log('state:', state.filter.time)

  //Localizations select
  let localizations: SelectOptionDefault[] = [];
  state.jobs.forEach((e: IJob) => {
    if (e.location) localizations.push({ value: e.location, label: e.location });
  });
  localizations = useReduce(localizations);

  //Areas select
  let areas: SelectOptionDefault[] = [];
  state.jobs.forEach((e: IJob) => {
    if (e.areaLabel) areas.push({ value: e.areaLabel, label: e.areaLabel });
  });
  areas = useReduce(areas);

  const onFilterResults = () => {
    let filtered: IJob[] = state.jobs;
    if (state.filter.area || state.filter.country || state.filter.time) {
      if (state.filter.area) {
        filtered = filtered.filter(e => {
          if (e.areaLabel)
            return e.areaLabel.toLowerCase().indexOf(state.filter.area.toLowerCase().trim()) > -1
          else
            return false;
        })
      }
      if (state.filter.country) {
        filtered = filtered.filter(e => {
          if (e.location)
            return e.location.toLowerCase().indexOf(state.filter.country.toLowerCase().trim()) > -1
          else
            return false;
        })
      }
      if (state.filter.time) {
        filtered = filtered.filter(e => {
          if (e.typeTime)
            return e.typeTime.toLowerCase().indexOf(state.filter.time.toLowerCase().trim()) > -1
          else
            return false;
        })
      }
    }
    setState({ ...state, filteredJobs: filtered });
  };

  useEffect(() => {
    setState({ ...state, loading: true });
    positionService
      .getPositions(constants)
      .then((newJobs: IJob[]) => {
        // CodeExtraction Service (Country select codes)
        for (let i = 0; i < newJobs.length; i++) {
          optionsService.getOption(constants, "UIN", newJobs[i].country, newJobs[i].ruleSystem)
            .then((option: SelectOption) => {
              newJobs[i].countryLabel = option.liblon;
              if (i == newJobs.length - 1) {
                setState({ ...state, loading: false, jobs: newJobs, filteredJobs: newJobs });
              }
            }).catch((err) => {
              console.log(err);
              setState({ ...state, loading: false });
            });
        }
      })
      .catch(err => {
        console.log(err);
      });
    setState({ ...state, loading: false });
  }, []);

  return (
    <FeedLayout>
      <div className="grid-filters-container">
        <SelectAutoComplete
          options={areas}
          name="label.area"
          value={state.filter.area}
          onChange={e => setState({ ...state, filter: { ...state.filter, area: !isEmpty(e.label) ? e.label : ""  } })}
          withSearch
          outlined
        />
        <SelectAutoComplete
          options={localizations}
          name="label.workplace"
          value={state.filter.country}
          onChange={e => setState({ ...state, filter: { ...state.filter, country: !isEmpty(e.label) ? e.label : "" } })}
          withSearch
          outlined
        />
        <SelectAutoComplete
          options={[{ value: "F", label: "Tempo pieno" }, { value: "P", label: "Tempo parziale" }]}
          name="label.timetable"
          value={state.filter.time}
          onChange={e => setState({ ...state, filter: { ...state.filter, time: !isEmpty(e.label) ? e.label : "" } })}
          translated
          outlined
        />
        <ButtonDefault text="action.filter" fullWidth marginTop onClick={onFilterResults} color="primary" size="standard" />
      </div>
      {state.loading ? (
        <Loading />
      ) : (
        <div className="grid-card-container">
          {state.filteredJobs.map(e => (
            <FeedJobItem id={e.id} name={e.name} company={e.companyLabel} startDate={e.startDate} endDate={e.endDate} country={e.countryLabel} location={e.location} description={e.description} />
          ))}
        </div>
      )}
    </FeedLayout>
  );
};

export default FeedPage;
