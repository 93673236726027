import React, { FunctionComponent, ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import {
  Link
} from "react-router-dom";
import ButtonDefault from "../components/button/ButtonDefault";
import Card from "../components/card/Card";
import Icon from "../components/icon/Icon";
import TextField from "../components/input/TextField";
import HeaderLogo from "../components/logo/HeaderLogo";
import Modal from "../components/modal/Modal";
import { showModal } from "../components/modal/ModalListener";
import useConfig from "../custom-hooks/useConfig";
import googleLogo from '../media/google-logo.png';
import linkedinLogo from '../media/linkedin-logo.png';
import authService from "../services/auth";
import "./login.scss";


/**
(?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
(?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
(?=.*[0-9])	The string must contain at least 1 numeric character
(?=.*[!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
(?=.{8,})	The string must be eight characters or longer
 **/
interface LoginState {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  loading: boolean;
}



const SignupPage: FunctionComponent = (): ReactElement => {
  const { constants } = useConfig();
  const [state, setState] = useState<LoginState>({ email: "", password: "", confirmPassword: "", firstName: "", lastName: "", loading: false });
  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})");
  const history = useHistory();

  const register = (e: React.FormEvent) => {
    e.preventDefault();
    if (validPasswords(state.password, state.confirmPassword)) {
      setState({ ...state, loading: true });
      authService
        .register(constants, state.email, state.password, state.confirmPassword, state.firstName, state.lastName, "LdapLoginModule")
        .then(() => {
          showModal(<Modal small>{successModal()}</Modal>)
          setTimeout(() => {
            setState({ ...state, loading: false });
            history.push("/login")
          }, 3000);
        })
        .catch(err => {
          console.log(err);
          showModal(<Modal small>{errorModal("error.register")}</Modal>)
          setState({ ...state, loading: false });
        })
    }

  };

  const validPasswords = (password: string, confirmPassword: string) => {
    let valid = false;
    if (password === confirmPassword) {
      if (strongRegex.test(password)) {
        valid = true;
      } else {
        showModal(<Modal small>{errorModal("error.notValidPassword")}</Modal>)
      }
    } else {
      showModal(<Modal small>{errorModal("error.passNotConfirmed")}</Modal>)
    }
    return valid;
  }


  const errorModal = (message: string) => {
    return (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <FormattedMessage id={message} />
        <Icon name="exclamation-circle" big style={{ color: 'red', fontSize: '3rem', textAlign: 'center', margin: '2rem' }} />
      </div>
    )
  }

  const successModal = () => {
    return (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <FormattedMessage id="label.registration-success" />
        <Icon name="check-circle" big style={{ color: '#52c41a', fontSize: '3rem', textAlign: 'center', margin: '2rem' }} />
      </div>
    )
  }

  return (
    <div className="login-background">
      <Card loginCard>
        {/* TODO: Refactorize logo components or add a new one "LoginLogo" */}
        <div className="login-logo-container">
          <HeaderLogo login />
        </div>
        <form onSubmit={register} autoComplete="off" className="form-container">
          <TextField
            label="label.email"
            value={state.email}
            onChange={e => setState({ ...state, email: e })}
            type="email"
            icon="at"
          />
          <TextField
            label="label.password"
            value={state.password}
            onChange={e => setState({ ...state, password: e })}
            type="password"
            icon="lock"
          />
          <TextField
            label="label.confirmpassword"
            value={state.confirmPassword}
            onChange={e => setState({ ...state, confirmPassword: e })}
            type="password"
            icon="lock"
          />
          <TextField
            label="label.firstName"
            value={state.firstName}
            onChange={e => setState({ ...state, firstName: e })}
            type="text"
            icon="lock"
          />
          <TextField
            label="label.lastName"
            value={state.lastName}
            onChange={e => setState({ ...state, lastName: e })}
            type="text"
            icon="lock"
          />
          <div className="login-actions">
            <ButtonDefault
              disabled={state.loading}
              loading={state.loading}
              onClick={register}
              text="action.sign-up"
              color="secondary"
              size="standard"
              style={{ 'marginBottom': '0.5rem' }}
            />
            <Link to="/login"><FormattedMessage id="action.sign-in" /></Link>
            <div className="social-login">
              <a className="btn social-btn google" href={constants.GOOGLE_AUTH_URL}>
                <img src={googleLogo} alt="Google" /><FormattedMessage id="action.sign-up-google" /></a>
              <a className="btn social-btn github" href={constants.LINKEDIN_AUTH_URL}>
                <img src={linkedinLogo} alt="Github" /><FormattedMessage id="action.sign-up-linkedin" /></a>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default SignupPage;
