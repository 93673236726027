import axios from "axios";
import generateFakeExperience from "../../mocked-data/generateFakeExperience";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import IExperience from "../../models/IExperience";
import authHeaders from "../auth/authHeaders";

const getExperience = async (constants: ConstantsConfig, nudoss: string | number, nulign: string | number): Promise<IExperience> => {
  if (constants.useMockData) return generateFakeExperience();
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/experiences/${nulign}`, { headers: authHeaders() });
  return res.data;
};

export default getExperience;
