import axios from "axios";
import fakeData from "../../mocked-data/fakeData";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import IJob from "../../models/IJob";

const getPositionDetails = async (constants: ConstantsConfig, id: string): Promise<IJob> => {
  if (constants.useMockData) {
    const found = fakeData.fakeRandomJobs.find(s => s.id === id);
    if (found) return found;
    else throw new Error("Job not found");
  }

  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/positions/${id}`);
  return res.data;
};

export default getPositionDetails;
