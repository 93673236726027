import { UserChartSerie } from "../interfaces/UserChartSerie";
import { UserChartOptions } from "../interfaces/UserChartOptions";
import ISkill from "../../../models/ISkill";

const generateUserChartSeriesAndOptions = (userSkills: ISkill[]): [UserChartSerie[], UserChartOptions] => {
  const categories: string[] = [];
  const levels: any[] = [];

  userSkills.map((e: ISkill) => {
    categories.push(e.label);
    levels.push(e.level);
  });

  const series: UserChartSerie[] = [{ name: "Skills", data: levels }];

  const options: UserChartOptions = {
    chart: {
      type: "radar",
      toolbar: {
        show: false,
      },
    },
    colors: ["var(--colour-primary)"],
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: "var(--colour-black)",
          fontSize: "0.95rem",
          fontFamily: "Segoe UI",
        },
      },
    },
    yaxis: {
      show: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["var(--colour-black)"],
    },
    markers: {
      size: 0,
    },
    dataLabels: {
      show: false,
    },
  };

  return [series, options];
};

export default generateUserChartSeriesAndOptions;
