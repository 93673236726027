
import classnames from "classnames";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { AuthContext, UserContext } from "../../context";
import useConfig from "../../custom-hooks/useConfig";
import Avatar from "../../media/user.png";
import staffDocumentService from "../../services/staff-document";
import Loading from "../loading/Loading";

interface UserImageProps {
  nudoss?: string | number;
  size?: "big" | "small" | "mini" | undefined;
  borderShape?: "cylindrical" | undefined;
}

interface UserImageState {
  loading: boolean;
  image: string;
}

const UserImage: FunctionComponent<UserImageProps> = ({ nudoss, size, borderShape }) => {
  const { constants } = useConfig();
  const [state, setState] = useState<UserImageState>({ loading: false, image: Avatar });
  const { isAuth } = useContext(AuthContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (nudoss) {
      setState({ ...state, loading: true });
      staffDocumentService
        .getStaffPhoto(constants, nudoss)
        .then(img => {
          setState({ loading: false, image: img });
        })
        .catch(err => {
          setState({ ...state, loading: false });
        });
    } else {
      setState({ ...state, loading: false, image: Avatar })
    }
  }, [nudoss]);

  return <React.Fragment>
    {state.loading ?
      (<Loading type="small" />) :
      (<div className={
        classnames("standard-image-container",
          { "mini-size-image": size == "mini" },
          { "small-size-image": size == "small" },
          { "big-size-image": size == "big" },
          { "round-image-container": borderShape == "cylindrical" })}>
        <img src={state.image} alt="User Avatar" className="full-height width-auto" />
      </div>)}
  </React.Fragment>
};

UserImage.defaultProps = {
  size: undefined,
  borderShape: undefined
};

export default UserImage;
