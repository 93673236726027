import React, { useState, ReactElement, FunctionComponent, useEffect, useContext } from "react";
import authService from "../services/auth";
import useConfig from "../custom-hooks/useConfig";
import { UserContext } from "../context";
import { useHistory } from "react-router";

export interface AuthContextState {
  isAuth: boolean;
  checked: boolean;
  setAuthContextState?: React.Dispatch<React.SetStateAction<AuthContextState>>;
}

const initialState: AuthContextState = {
  isAuth: false,
  checked: false,
  setAuthContextState: e => {},
};

export const AuthContext: React.Context<AuthContextState> = React.createContext<AuthContextState>(initialState);

export const AuthContextProvider: FunctionComponent = ({ children }): ReactElement => {
  const { constants } = useConfig();
  const [state, setAuthContextState] = useState(initialState);
  const { setUserContextState } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    authService.checkLoggedIn(constants).then(result => {
      if (result) {
        setAuthContextState({ isAuth: true, checked: true });
      } else {
        authService.logout().then(() => {
          setAuthContextState?.({ checked: true, isAuth: false });
          setUserContextState?.({ user: undefined });
          history.push("/");
        });
      }
      
    });
  }, []);

  return <AuthContext.Provider value={{ ...state, setAuthContextState }}>{children}</AuthContext.Provider>;
};
