// import { useTranslatedMessagesString } from "../../custom-hooks/useTranslatedMessagesString";
import TOPICS from "../topics";

const throwError = (message: string) => {
  if (message === "Error") {
    message = "error.default";
  }
  TOPICS.TOASTS_TOPIC.next({ message, variant: "error" });
};

export default throwError;
