import React, { FunctionComponent, ReactElement, useContext, useRef } from "react";
import { WindowSizeContext } from "../../context";
import BackdropListener from "../backdrop/BackdropListener";
import BackgroundCardImage from "../background-card/BackgroundCardImage";
import HeaderCardImage from "../background-card/HeaderCardImage";
import Card from "../card/Card";
import FootNote from "../foot-note/FootNote";
import ModalListener from "../modal/ModalListener";
import ScrollToTop from "../scroll-to-top/ScrollToTop";
import Toolbar from "../toolbar/Toolbar";
interface FeedLayoutProps {
  usesRefToScroll?: boolean,
}

const FeedLayout: FunctionComponent<FeedLayoutProps> = (props): ReactElement => {
  const scrollRef = useRef(null);
  const { isMobile } = useContext(WindowSizeContext);
  return (
    <React.Fragment>
      <ModalListener />
      <BackdropListener>
        <div className="standard-background">
          <Toolbar />
          {!isMobile && <HeaderCardImage />}
          {!isMobile && <BackgroundCardImage />}
          <Card feedCard>
            <div className="card-content" ref={props.usesRefToScroll ? scrollRef : null}>
              {props.usesRefToScroll && <ScrollToTop scrollRef={scrollRef} />}
              {props.children}
            </div>
          </Card>
          <FootNote email="servizioclienti@gruppopam.it" />
        </div>
      </BackdropListener>
    </React.Fragment>
  );
};

FeedLayout.defaultProps = {
  usesRefToScroll: false,
};

export default FeedLayout;
