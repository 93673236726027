import faker from "faker";
import IEducation from "../models/IEducation";

const generateFakeEducation = (): IEducation => {
  return {
    nulign: faker.random.number(),
    degree: "",
    degreeLabel: faker.name.jobTitle(),
    titleName: faker.name.firstName(),
    majorDomain: "",
    majorDomainArea: faker.name.firstName(),
    majorDomainAreaLabel: faker.name.firstName(),
    minorDomain: "",
    minorDomainArea: "Mayor",
    minorDomainAreaLabel: "TEST",
    startDate: "${faker.date.past().toLocaleDateString()}",
    state: "CO",
    stateLabel: "",
    endDate: "${faker.date.past().toLocaleDateString()}"
  };
};

export default generateFakeEducation;
