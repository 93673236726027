import storageService from "../services/storage";

/**
 * Creates a string that is valid for the accept language header
 */
const acceptLanguageGenerator = () => {
  const lan: string | undefined = storageService.getFromSession(storageService.sessionKeys.LANGUAGE_KEY);
  if (lan) {
    if (lan === "en") {
      return "en-US";
    }
    return `${lan}-${lan.toLocaleUpperCase()}`;
  }
  return "en-US";
};

export default acceptLanguageGenerator;
