import classnames from "classnames";
import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import useConfig from "../../custom-hooks/useConfig";
import companyLogo from '../../media/company-logo.png';
import positionService from "../../services/position";
import Loading from "../loading/Loading";

interface CompanyLogoProps {
  id: string;
  size?: "big" | "small" | "mini" | undefined;
}

interface CompanyLogoState {
  loading: boolean;
  logo: string;
}

const CompanyLogo: FunctionComponent<CompanyLogoProps> = ({ id, size }): ReactElement => {
  const [state, setState] = useState<CompanyLogoState>({ loading: false, logo: "" });
  const { constants } = useConfig();

  useEffect(() => {
    setState({ ...state, loading: true });
    positionService
      .getPositionLogo(constants, id)
      .then((newLogo: string) => {
        setState({ ...state, loading: false, logo: newLogo });
      })
      .catch(err => {
        console.log(err);
        setState({ ...state, loading: false, logo: companyLogo });
      });
  }, [id]);

  return <React.Fragment>
    {state.loading ?
      (<Loading type="small" />)
      :
      (<div className={
        classnames("standard-image-container",
          { "big-size-image": size == "big" },
          { "small-size-image": size == "small" },
          { "smaller-size-image": size == "mini" }
        )}>
        <img src={state.logo} alt="Company Logo" className="full-max-width full-max-height" />
      </div>)}
  </React.Fragment>;
};

export default CompanyLogo;
