import axios from "axios";
import fakeData from "../../mocked-data/fakeData";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import IJob from "../../models/IJob";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";

const getStaffPositionApplies = async (constants: ConstantsConfig, nudoss: string | number): Promise<IJob[]> => {
  if (constants.useMockData) return fakeData.fakeRandomJobs;
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/positions?role=${userRole()}`, { headers: authHeaders() });
  return res.data;
};

export default getStaffPositionApplies;
