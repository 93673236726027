import { useEffect, useState } from "react";
import { SelectOption } from "../models/SelectOption";
import getOptions from "../services/options/getOptions";
import useConfig from "./useConfig";

const useSelectOptions = (reg: string, dir: string): [SelectOption[], boolean] => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { constants } = useConfig();

  useEffect(() => {
    setLoading(true);
    getOptions(constants, reg, dir)
      .then(opts => {
        setOptions(opts);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, [reg, dir]);

  return [options, loading];
};

export default useSelectOptions;
