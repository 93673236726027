import { FormattedMessage } from "react-intl";
import React, { FunctionComponent, ReactElement, useEffect, useState, Fragment } from "react";
import ISkill from "../../models/ISkill";
import ReactApexChart from "react-apexcharts";
import generateChartSeriesAndOptions from "../../utils/job-chart/utils/generateChartSeriesAndOptions";
import positionService from "../../services/position";
import skillsService from "../../services/skills";
import useConfig from "../../custom-hooks/useConfig";
import useUser from "../../custom-hooks/useUser";
import Subtitle from "../titles/Subtitle";

interface SkillsInfoProps {
  idPosition: string;
  requiredSkills?: boolean;
}

interface SkillsInfoState {
  loading: boolean;
  skills: ISkill[];
  userSkills: ISkill[];
}
const SkillsInfo: FunctionComponent<SkillsInfoProps> = ({ idPosition, requiredSkills }): ReactElement => {
  const { constants } = useConfig();
  const { user } = useUser();
  const [state, setState] = useState<SkillsInfoState>({ loading: false, skills: [], userSkills: [] });
  const skillsMessage = requiredSkills ? "label.skills-required" : "label.desirable-skills";

  useEffect(() => {
    setState({ ...state, loading: true });
    const skillsPromise = positionService.getPositionSkills(constants, idPosition);
    let userSkillsPromise: Promise<ISkill[]> = new Promise<ISkill[]>(resolve => resolve([]));
    if (user) userSkillsPromise = skillsService.getSkills(constants, user.nudoss);

    Promise.all([skillsPromise, userSkillsPromise])
      .then(([skills, userSkills]) => {
        setState({ loading: false, skills: requiredSkills ? skills.requiredSkills : skills.desirableSkills, userSkills });
      })
      .catch(err => {
        setState({ ...state, loading: false });
      });
  }, []);

  const [series, options] = generateChartSeriesAndOptions(state.skills, state.userSkills, requiredSkills ? "primary" : "secondary", requiredSkills);
  return (
    <React.Fragment>
    {state.skills.length > 0 &&  
    <div className="skills-info">
       {state.skills.length > 0 ? (
        <Fragment>
          <Subtitle label={skillsMessage} />
          <div className="skills-chart-container" style={{}} >
            <ReactApexChart type="bar" series={series} options={options} />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <p className="text-standard text-bold text-medium-size">{skillsMessage}</p>
          <p className="full-height full-width flex-column-center">
            <FormattedMessage id={"label.no-additional-skills-needed"} />
          </p>
        </Fragment>
      )}
    </div>} 
  </React.Fragment>
  );
};

export default SkillsInfo;
