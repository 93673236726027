import React from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Providers from "./context/Providers";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import ProfilePage from "./pages/ProfilePage";
import FeedPage from "./pages/FeedPage";
import JobPage from "./pages/JobPage";
import constants from "./constants";
import "@fortawesome/fontawesome-free/css/all.css";
import "./App.scss";
import "./styles/colors.scss";
import "./styles/commons.scss";
import MySubmissionsPage from "./pages/MySubmissionsPage";
import ProfileEditPage from "./pages/ProfileEditPage";
import MyProfilePage from "./pages/MyProfilePage";
import ProtectedRoute from "./components/protected-route/ProtectedRoute";
import OAuth2RedirectHandler from './services/handlers/OAuth2RedirectHandler';
import RegisterHandler from './services/handlers/RegisterHandler';
import ChangePassHandler from './services/handlers/ChangePassHandler';
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfUsePage from "./pages/TemsOfUsePage";
import BackdropListener from "./components/backdrop/BackdropListener";
import ModalListener from "./components/modal/ModalListener";
import ResetPassPage from "./pages/ResetPassPage";
import Toasts from "./components/toasts/Toasts";

const App = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Providers constants={constants}>
          <ModalListener />
          <Toasts />
          <BackdropListener>
            <Route exact path="/" component={FeedPage} />
            <Route exact path="/feed" component={FeedPage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/signup" component={SignupPage} />
            <Route exact path="/reset-password" component={ResetPassPage} />
            <Route exact path="/profile/:id" component={ProfilePage} />
            <Route path="/jobs/:id" component={JobPage} />
            <Route exact path="/privacy-policy/:id" component={PrivacyPolicyPage} />
            <Route exact path="/accept-terms" component={TermsOfUsePage} />
            <Route path="/oauth2/redirect" component={OAuth2RedirectHandler} />
            <Route path="/email-validation" component={RegisterHandler} />
            <Route path="/pass-validation" component={ChangePassHandler} />
            {/* <Route exact path="/privacy-policy/:id" component={PAMPrivacyPolicyPage} /> */}
            <ProtectedRoute redirectPath="/" exact path="/my-profile" component={MyProfilePage} />
            <ProtectedRoute redirectPath="/" exact path="/my-profile-edit" component={ProfileEditPage} />
            <ProtectedRoute redirectPath="/" exact path="/my-submissions" component={MySubmissionsPage} />
          </BackdropListener>
        </Providers>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
