import React, { ReactElement, FunctionComponent } from "react";
import Icon from "../icon/Icon";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";

interface MyProfileButtonProps {
  withText: boolean;
}

const MyProfileButton: FunctionComponent<MyProfileButtonProps> = ({ withText }): ReactElement => {
  const history = useHistory();

  return (
    <div className="flex-row-start text-white cursor-pointer full-width full-height" onClick={() => history.push("/my-profile")}>
      <Icon dropDownType name="user" light />
      {withText && <FormattedMessage id="label.my-profile" />}
    </div>
  );
};

MyProfileButton.defaultProps = {
  withText: false,
};

export default MyProfileButton;
