import axios from "axios";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import ISkill from "../../models/ISkill";
interface SkillsResponse {
  desirableSkills: ISkill[];
  requiredSkills: ISkill[];
}
const getPositionSkills = async (constants: ConstantsConfig, id: string): Promise<SkillsResponse> => {
  // if (constants.useMockData) return fakeData.fakeRandomSkills;

  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/positions/${id}/skills`);
  return res.data;
};

export default getPositionSkills;
