import axios from "axios";
import constants from '../../constants';
import storageService from "../storage";
import authHeaders from "./authHeaders";

const logout = async (): Promise<boolean> => {
  const headers = authHeaders();
  const res = await axios.post(`${constants.REACT_APP_WS_URL}/hr-auth/exit`, "", { headers: authHeaders() });
  if (res.status === 200) {
    // delete local token
    storageService.deleteFromSession(storageService.sessionKeys.AUTH_TOKEN_KEY);
    storageService.deleteFromSession(storageService.sessionKeys.USER_KEY);
  }
  return true;
};

export default logout;
