import React, { useState, useEffect, ReactElement, FunctionComponent } from "react";
import { getLanguage, setLanguage, verifyLanguage } from "../services/language";
import { IntlProvider } from "react-intl";
import * as moment from "moment";
import TOPICS from "../services/topics";
import { messages } from "../translations/combined-messages";
import "moment/locale/fr";
import "moment/locale/es";
import "moment/locale/be";
import "moment/locale/pt";
import "moment/locale/de";
import "moment/locale/it";

export const LanguageContext = React.createContext({
  language: getLanguage(),
  updateLanguage: (lang: string) => setLanguage(lang),
});

export const LanguageProvider: FunctionComponent = ({ children }): ReactElement => {
  verifyLanguage();
  const [language, setLang] = useState(getLanguage());
  const [msg, setMessages] = useState({});
  const checkMoment = (value: string) => (value === "be" ? moment.locale("pt") : moment.locale(value));

  const updateLanguage = (lang: string) => {
    setLanguage(lang);
    checkMoment(lang);
  };

  useEffect(() => {
    checkMoment(getLanguage());
    const subscription = TOPICS.LANGUAGE_TOPIC.subscribe((lang: any) => {
      setLang(lang);
      checkMoment(lang);
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    messages(language).then(m => setMessages(m));
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, updateLanguage }}>
      <IntlProvider locale={language} defaultLocale={language} messages={msg}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
