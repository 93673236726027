import React, { useState, useEffect } from "react";
import * as moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { dateToMilliseconds, formatDateToStandard } from "../../utils/time/time";
import "./Input.scss";

const DatePicker = ({ value, required, onChange, label, withFormat}) => {

  const handleDate = date => {
    withFormat ? onChange(formatDateToStandard(date)) : onChange(date);
  };

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          required={required}
          variant="dialog"
          error={false}
          format="dd/MM/yyyy"
          disableToolbar
          margin="normal"
          id="date-picker-inline"
          label={label ? <FormattedMessage id={label} /> : ""}
          value={value ? moment(value, "DDMMYYYY") : null}
          onChange={handleDate}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  aligned: PropTypes.bool,
  smallSize: PropTypes.bool,
  MarginLeft: PropTypes.bool,
  mediumSize: PropTypes.bool,
  bigSize: PropTypes.bool,
  bold: PropTypes.bool,
  first: PropTypes.bool,
  readOnly: PropTypes.bool,
  isModal: PropTypes.bool,
  onChange: PropTypes.func,
  withFormat: PropTypes.bool
};

DatePicker.defaultProps = {
  label: undefined,
  name: undefined,
  readOnly: false,
  required: false,
  aligned: false,
  first: false,
  smallSize: false,
  MarginLeft: false,
  withFormat: false,
  mediumSize: false,
  bigSize: false,
  bold: false,
  isModal: false
};

export default DatePicker;
