import axios from "axios";
import constants from '../../constants';
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";

const updateUserInterest = async (nudoss: string | number, nulign: string | number, data: Object): Promise<Object> => {
  const res = await axios.put(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/interests/${nulign}?role=${userRole()}`, data, { headers: authHeaders() });
  return res.data;
};

export default updateUserInterest;
