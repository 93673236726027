import classnames from "classnames";
import React, { CSSProperties, FunctionComponent } from "react";
import { v4 as uuid } from "uuid";
import "./Icon.scss";

interface IconProps {
  id?: string;
  name: string;
  onClick?: Function;
  disabled?: boolean;
  style?: CSSProperties;
  light?: boolean;
  small?: boolean;
  solid?: boolean;
  regular?: boolean;
  inline?: boolean;
  headerType?: boolean;
  dropDownType?: boolean;
  big?: boolean;
  withoutContainer?: boolean;
}

const Icon: FunctionComponent<IconProps> = ({ id, style, name, onClick, disabled, light, small, solid, regular, headerType, dropDownType, big, inline, withoutContainer, }) => {
  return (
    <React.Fragment>
      <div
        className={classnames({ "dropdown-icon-container": dropDownType && !withoutContainer }, { "standard-icon-container": !dropDownType && onClick && !withoutContainer }, { "header-icon-container": headerType && !withoutContainer }, { "inline": inline }, { "without-container": withoutContainer })}
        id={id}
        onClick={e => onClick ? onClick(e) : null}
      >
        <i
          className={classnames(
            "icon-default",
            { fas: solid && !regular },
            { far: regular },
            `fas fa-${name}`,
            { "text-white": light && !disabled },
            { "text-black": !disabled && !light },
            { "cursor-pointer": onClick },
            { "icon-small": small },
            { "icon-big": big },
            { "padding-left-icon": name === "edit" }
          )}
          style={style}
        />
      </div>
    </React.Fragment>
  );
};

Icon.defaultProps = {
  id: uuid(),
  style: {},
  onClick: undefined,
  disabled: false,
  solid: true,
  regular: false,
  light: false,
  small: false,
  headerType: false,
  dropDownType: false,
  big: false,
  inline: false,
};

export default Icon;
