import { SelectOptionDefault } from "../models/SelectOption";

const useReduce = (array: SelectOptionDefault[]) => {
  return array.reduce((arr: SelectOptionDefault[], current: SelectOptionDefault) => {
    const x = arr.find(item => item.value === current.value);
    if (!x) {
      return arr.concat([current]);
    } else {
      return arr;
    }
  }, []);
};

export default useReduce;
