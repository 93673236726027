import React from "react";
import classNames from "classnames";
import "./loading.scss";

interface LoadingProps {
  type: "default" | "button" | "badge" | "small" | "big";
}

const Loading = ({ type }: LoadingProps) => (
  <div
    className={classNames(
      { "spinner-loading spinner-type-default": type === "default" },
      { "spinner-loading spinner-type-big": type === "big" },
      { "spinner-loading spinner-type-default-small": type === "small" },
      { "spinner-loading spinner-type-button": type === "button" },
      { "spinner-loading spinner-type-badge": type === "badge" }
    )}
  />
);

Loading.defaultProps = {
  type: "default",
};

export default Loading;
