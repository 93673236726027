import React, { FunctionComponent, ReactElement, useContext } from "react";
import CustomLayout from "../components/layout/CustomLayout";
import Loading from "../components/loading/Loading";
import MySubmissionsJobList from "../components/MySubmissionsJobList/MySubmissionsJobList";
import ProfileCard from "../components/ProfileCard/ProfileCard";
import { WindowSizeContext } from "../context";
import useUser from "../custom-hooks/useUser";

const MyProfilePage: FunctionComponent = (): ReactElement => {
  const { user } = useUser();
  const { isMobile } = useContext(WindowSizeContext);

  return (
    <CustomLayout>
      <div className="cards-container  profile-card-container">
        {user ? <ProfileCard nudoss={user.nudoss} /> : <Loading />}
        {!isMobile && <MySubmissionsJobList />}
      </div>
    </CustomLayout>
  );
};

export default MyProfilePage;
