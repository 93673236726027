import React, { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import useConfig from "../../custom-hooks/useConfig";
import IExperience from "../../models/IExperience";
import IUser from "../../models/IUser";
import employeeService from "../../services/employees";
import experiencesService from "../../services/experiences";
import { formatDateToStandard } from "../../utils/time/time";
import TextCheckBox from "../input/TextCheckBox";
import Subtitle from "../titles/Subtitle";

interface UserExperiencesProps {
  nudoss: string | number;
}

interface UserExperiencesState {
  loading: boolean;
  experiences: IExperience[];
  user?: IUser;
}

const UserExperiences: FunctionComponent<UserExperiencesProps> = ({ nudoss }) => {
  const [state, setState] = useState<UserExperiencesState>({ loading: false, experiences: [] });
  const { constants } = useConfig();

  useEffect(() => {
    setState({ ...state, loading: true });
    // experiencesService
    //   .getExperiences(constants, nudoss)
    //   .then((newExperiences: IExperience[]) => {
    //     setState({ ...state, loading: false, experiences: newExperiences });
    //   })
    //   .catch(err => {
    //     console.log(err);
    //     setState({ ...state, loading: false });
    //   });


    const p1 = employeeService.getProfileInformation(constants, nudoss);
    const p2 = experiencesService.getExperiences(constants, nudoss);

    Promise.all([p1, p2])
      .then(values => {
        setState({ ...state, loading: false, user: values[0], experiences: values[1] });
      })
      .catch(err => {
        console.log(err);
        setState({ ...state, loading: false });
      });

  }, [nudoss]);

  return (
    <React.Fragment>
      <div className="profile-card-data-sub-container">
        {state.experiences.length > 0 ? state.experiences.map((e: IExperience) => (
          <React.Fragment>
            <div>
              <Subtitle label="label.job-experiences" />
              <div className="experience-item">
                <div className="text-double">
                  <p className="text-standard">{e.name}</p>
                </div>
                <div className="text-double">
                  <p className="text-standard text-grey text-italic">
                    {formatDateToStandard(e.startDate)} - {e.endDate === "2999-12-31" ? <FormattedMessage id="label.present" /> : formatDateToStandard(e.endDate)}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <Subtitle label="label.azienda" />
              <p className="text-standard">{e.company}</p>
            </div>
          </React.Fragment>
        ))
          :
          <TextCheckBox value={state.user ? state.user.firstJob : false} label="label.first-job" readOnly light />
        }
      </div>
    </React.Fragment>

  );
};

export default UserExperiences;
