
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FunctionComponent, ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { SelectOptionDefault } from "../../models/SelectOption";
import "./Autocomplete.scss";

interface SelectAutoCompleteProps {
  name?: string;
  value: string | string[] | number;
  onChange: (e: SelectOptionDefault) => void;
  options: SelectOptionDefault[];
  translated?: boolean;
  withSearch?: boolean;
  outlined?: boolean;
  required?: boolean;
}

const SelectAutoComplete: FunctionComponent<SelectAutoCompleteProps> = ({ name, onChange, options, translated, withSearch, outlined, value, required }): ReactElement => {

  const label = (name: string = '', required: boolean = false) => <React.Fragment><FormattedMessage id={name} />{required && '*'}</React.Fragment>;

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.label}
      id="id"
      size="small"
      value={{
        label: options.find(v => v.label === value)?.label!,
        value: options.find(v => v.value === value)?.value!
      }}
      onChange={(event: any, newValue: any) => {
        onChange(newValue !== null ? newValue : "")
      }}
      style={{ width: '100%' }}
      renderInput={(params) => outlined ?
        <TextField {...params} margin="normal" variant="outlined" required={required} label={<FormattedMessage id={name} />} />
        :
        <TextField {...params} margin="normal" variant="standard" required={required} label={<FormattedMessage id={name} />} />}
    />
  );
};

export default SelectAutoComplete;


SelectAutoComplete.defaultProps = {
  outlined: false
};
