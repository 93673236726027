import deleteExperience from "./deleteExperience";
import getExperience from "./getExperience";
import getExperiences from "./getExperiences";
import postExperience from "./postExperience";
import updateExperience from "./updateExperience";

const experiencesService = {
  getExperiences,
  getExperience,
  postExperience,
  updateExperience,
  deleteExperience,
};

export default experiencesService;
