import React, { useContext, useCallback, ReactElement, FunctionComponent } from "react";
import Icon from "../icon/Icon";
import { FormattedMessage } from "react-intl";
import { AuthContext, UserContext } from "../../context";
import { useHistory } from "react-router";
import authService from "../../services/auth";

interface LogoutButtonProps {
  withText: boolean;
}

const LogoutButton: FunctionComponent<LogoutButtonProps> = ({ withText }): ReactElement => {
  const { setUserContextState } = useContext(UserContext);
  const { isAuth, setAuthContextState } = useContext(AuthContext);
  const history = useHistory();

  const onClick = useCallback(() => {
    if (isAuth) {
      authService.logout().then(() => {
        setAuthContextState?.({ checked: true, isAuth: false });
        setUserContextState?.({ user: undefined });
        history.push("/");
      });
    } else {
      history.push("/");
    }
  }, [isAuth]);

  return (
    <div className="flex-row-start text-white cursor-pointer" onClick={onClick}>
      <Icon dropDownType name="power-off" light />
      {isAuth ? withText && <FormattedMessage id="action.logout" /> : withText && <FormattedMessage id="action.sign-in" />}
    </div>
  );
};

LogoutButton.defaultProps = {
  withText: false,
};

export default LogoutButton;
