const APP_CONFIG = () => {
  let config = {};

  try {
    if (sessionStorage) {
      const appConfig = JSON.parse(sessionStorage.getItem('APP_CONFIG') || {});
      config = { ...appConfig };
    }
  } catch (e) {
    return {};
  }
  return config;
};

export default APP_CONFIG;