import axios from "axios";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import ISkill from "../../models/ISkill";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";

const postSkill = async (constants: ConstantsConfig, nudoss: string | number, data: ISkill): Promise<ISkill> => {
  if (constants.useMockData) return data;
  const res = await axios.post(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/skills?role=${userRole()}`, data, { headers: authHeaders() });
  return res.data;
};

export default postSkill;
