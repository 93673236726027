import faker from "faker";
import IJob from "../models/IJob";
import ISkill from "../models/ISkill";

interface Company {
  name: string;
  photo: string;
}

interface Job {
  name: string;
  description: string;
  requiredSkills: ISkill[];
  desirableSkills: ISkill[];
}

const randomCompanies: Company[] = [
  { name: "Sopra Steria", photo: `${process.env.PUBLIC_URL}/img/soprasteria-logo.png` },
  { name: "SopraHR Software", photo: `${process.env.PUBLIC_URL}/img/soprahr-logo.png` },
  { name: "Sopra Banking Software", photo: `${process.env.PUBLIC_URL}/img/soprabanking-logo.png` },
  { name: "Axway", photo: `${process.env.PUBLIC_URL}/img/axway-logo.png` },
];
const randomOffers: Job[] = [
  {
    name: "React Developer",
    description:
      "We are looking for a smart, passionate and results-driven web guru with knowledge and skills in building highly interactive web interfaces. We need your experience and expertise to deliver robust software in the most engaging and professional manner possible. We’re looking for someone with superior user experience and graphic design skills along with strong technical knowledge in current web development technologies like JavaScript (NodeJS, TypeScript, React, Vue etc.), HTML and CSS based on profound software development mechanisms (deployment, TDD).",
    requiredSkills: [
      { nulign: 1, code: "", label: "React", level: "4" },
      { nulign: 2, code: "", label: "HTML", level: "4" },
      { nulign: 3, code: "", label: "CSS", level: "4" },
      { nulign: 4, code: "", label: "NodeJS", level: "2" },
    ],
    desirableSkills: [
      { nulign: 5, code: "", label: "Java", level: "3" },
      { nulign: 3, code: "", label: "REST", level: "3" },
      { nulign: 6, code: "", label: "Communication", level: "3" },
    ],
  },
  {
    name: "Java Developer",
    description:
      "The ideal candidate will have solid experience in managing multi-unit operations, new location operational start-ups, team development, and business/community relations. An ability to identify, hire, and motivate talented employees is necessary to lead the growth of our organization.",
    requiredSkills: [
      { nulign: 1, code: "", label: "Java", level: "5" },
      { nulign: 2, code: "", label: "Multithreading", level: "3" },
      { nulign: 3, code: "", label: "REST", level: "4" },
    ],
    desirableSkills: [
      { nulign: 6, code: "", label: "Communication", level: "3" },
      { nulign: 7, code: "", label: "Team Work", level: "3" },
      { nulign: 4, code: "", label: "NodeJS", level: "2" },
      { nulign: 2, code: "", label: "HTML", level: "3" },
      { nulign: 3, code: "", label: "CSS", level: "3" },
    ],
  },
  {
    name: "HR Expert",
    description:
      "The HR Expert will be in charge of our most precise and cutting edge HR algorithms. Our ideal candidate will have solid experience in managing business/community relations and profound work ethics, team development, and commitment.",
    requiredSkills: [
      { nulign: 1, code: "", label: "SQL", level: "5" },
      { nulign: 2, code: "", label: "COBOL", level: "4" },
      { nulign: 3, code: "", label: "Bash", level: "4" },
    ],
    desirableSkills: [
      { nulign: 6, code: "", label: "Communication", level: "3" },
      { nulign: 7, code: "", label: "Team Work", level: "3" },
      { nulign: 7, code: "", label: "File System", level: "3" },
    ],
  },
  {
    name: "Cobol Developer",
    description:
      "This is an incredible opportunity to bring your development skills to drive demand for multiple products in multiple European markets. You will work with our account managers across Europe to define and shape the experience we use to engage with, and influence, our target markets, and you will have to adapt your work to each product and each market.",
    requiredSkills: [
      { nulign: 1, code: "", label: "SQL", level: "4" },
      { nulign: 2, code: "", label: "COBOL", level: "5" },
    ],
    desirableSkills: [
      { nulign: 6, code: "", label: "Communication", level: "3" },
      { nulign: 3, code: "", label: "Bash", level: "4" },
      { nulign: 1, code: "", label: "Java", level: "2" },
    ],
  },
  {
    name: "Project Manager",
    description:
      "This position is based in one of our biggest software factories where most of our developments are created. This role is for someone that is searching for a new and exciting step up in his/her career.",
    requiredSkills: [
      { nulign: 1, code: "", label: "Java", level: "4" },
      { nulign: 6, code: "", label: "Communication", level: "5" },
      { nulign: 7, code: "", label: "Team Work", level: "3" },
    ],
    desirableSkills: [
      { nulign: 6, code: "", label: "Communication", level: "3" },
      { nulign: 3, code: "", label: "Bash", level: "4" },
      { nulign: 1, code: "", label: "SQL", level: "4" },
    ],
  },
];

const generateFakeJob = (): IJob => {
  const randomCompany = faker.helpers.shuffle(randomCompanies)[0];
  const randomOffer = faker.helpers.shuffle(randomOffers)[0];

  return {
    id: faker.random.uuid(),
    name: randomOffer.name,
    company: `${faker.random.number()}`,
    companyLabel: randomCompany.name,
    country: faker.address.country(),
    areaLabel: faker.address.country(),
    countryLabel: faker.address.country(),
    description: randomOffer.description,
    startDate: `${faker.date.past().toLocaleDateString()}`,
    endDate: `${faker.date.past().toLocaleDateString()}`,
    position: faker.random.words(),
    positionLabel: randomOffer.name,
    location: faker.address.city(),
    photo: randomCompany.photo,
    experienceRequired: faker.helpers.shuffle(["Not required", "1-2 years", "2-3 years", "5+ years"])[0],
    published: faker.date.past().toLocaleDateString(),
    minSalary: faker.helpers.shuffle([24, 30])[0],
    maxSalary: faker.helpers.shuffle([30, 40, 50])[0],
    responsability: faker.helpers.shuffle(["Low", "Medium", "Medium-High", "High"])[0],
    skillsRequired: randomOffer.requiredSkills,
    desirableSkills: randomOffer.desirableSkills,
    currency: "€",
    job: randomOffer.name,
    jobLabel: randomOffer.name,
    date: faker.date.past().toLocaleDateString(),
    applicants: faker.random.number(),
    ruleSystem: faker.random.words(),
    typeTime: faker.helpers.shuffle(["Tempo pleno", "Tempo parziale"])[0],
  };
};

export default generateFakeJob;
