import axios from "axios";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import { SelectOption } from "../../models/SelectOption";
import authHeaders from "../auth/authHeaders";

const getOption = async (constants: ConstantsConfig, dir: string, code: string, ruleSystem: string): Promise<SelectOption> => {
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/detail/${dir}/${code}/${ruleSystem}`, { headers: authHeaders(true) });
  return res.data;
};


export default getOption;
