// import React, {  } from "../../../node_modules/react";
import classnames from "classnames";
import "./floatingMenu.scss";
import React, { ReactElement, CSSProperties, Fragment, FunctionComponent } from "react";

interface FloatingMenuProps {
  showing: boolean;
  black?: boolean;
  scroll?: boolean;
  onClose: Function;
  children: ReactElement | ReactElement[];
  style?: CSSProperties;
}

const FloatingMenu: FunctionComponent<FloatingMenuProps> = ({ showing, black, onClose, children, scroll, style }): ReactElement => {
  const childrenWithProps = React.Children.map(children, (child: ReactElement) => React.cloneElement(child, { ...child.props, onClose }));

  return (
    <React.Fragment>
      <div className={classnames("floating-menu", { "floating-menu-black": black }, { "display-none": !showing }, { "floating-menu-scroll": scroll })} style={style}>
        <div>{childrenWithProps}</div>
      </div>
      {/* <div className={classnames("transparent-backdrop", { "display-block": showing }, { "display-none": !showing })} onClick={() => onClose()} /> */}
    </React.Fragment>
  );
};

FloatingMenu.defaultProps = {
  showing: false,
  black: false,
  scroll: false,
  onClose: () => {},
  children: <Fragment />,
  style: {},
};

export default FloatingMenu;
