import React, { FunctionComponent, useEffect, useState } from "react";
import useConfig from "../../custom-hooks/useConfig";
import IEducation from "../../models/IEducation";
import educationsService from "../../services/educations";
import { formatDateToStandard } from "../../utils/time/time";
import Subtitle from "../titles/Subtitle";

interface UserEducationsProps {
  nudoss: string | number;
}

interface UserEducationsState {
  loading: boolean;
  educations: IEducation[];
}

const UserEducations: FunctionComponent<UserEducationsProps> = ({ nudoss }) => {
  const [state, setState] = useState<UserEducationsState>({ loading: false, educations: [] });
  const { constants } = useConfig();

  useEffect(() => {
    setState({ ...state, loading: true });
    educationsService
      .getEducations(constants, nudoss)
      .then((newEducations: IEducation[]) => {
        setState({ ...state, loading: false, educations: newEducations });
      })
      .catch(err => {
        console.log(err);
        setState({ ...state, loading: false });
      });
  }, [nudoss]);

  return (
    <React.Fragment>
      <div className="profile-card-data-sub-container">
        <div>
          <Subtitle label="label.education" />
          {state.educations.length > 0 ? state.educations.map(e => (
            <div className="experience-item">
              <p className="text-standard">
                {e.degreeLabel} {e.titleName} {e.majorDomainAreaLabel ? " in " + e.majorDomainAreaLabel : " "} {e.minorDomainAreaLabel}
              </p>
              <p className="text-standard text-grey text-italic">
                {formatDateToStandard(e.startDate)} - {e.state === "CO" ? formatDateToStandard(e.endDate) : "Present"}
              </p>
            </div>
          ))
            : <p className="text-standard text-grey text-italic">No data</p>}
        </div>
      </div>
    </React.Fragment>

  );

};

export default UserEducations;
