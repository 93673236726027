import { Subject } from "rxjs";
import { AppNotification } from "../models/AppNotification";

const TOPICS = {
  LOGGEDIN_TOPIC: new Subject(),
  LANGUAGE_TOPIC: new Subject(),
  TOASTS_TOPIC: new Subject<AppNotification>(),
  ROLE_TOPIC: new Subject(),
  SIDEBAR_TOPIC: new Subject(),
  MODAL_TOPIC: new Subject(),
  MODAL_TOPIC_CLOSE: new Subject(),
  BACKDROP_TOPIC: new Subject(),
  DIAGNOSTIC_TOPIC: new Subject(),
  SET_DIAGNOSTIC_TOPIC: new Subject(),
  SET_DIAGNOSTIC_CONTENT_TOPIC: new Subject(),
  FETCH_DATA_TOPIC: new Subject(),
  CLEAR_TOPIC_VALUES: { DELETE: "DELETE", CANCEL: "CANCEL" },
  CLEAR_LOCAL_DATA_TOPIC: new Subject(),
};

export default TOPICS;
