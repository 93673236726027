import axios from "axios";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import IEducation from "../../models/IEducation";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";

const postEducation = async (constants: ConstantsConfig, nudoss: string | number, data: IEducation): Promise<IEducation> => {
  if (constants.useMockData) return data;
  const res = await axios.post(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/educations?role=${userRole()}`, data, { headers: authHeaders() });
  return res.data;
};

export default postEducation;
