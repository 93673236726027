import axios from "axios";
import fakeUser from "../../mocked-data/fakeUser";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import IUser from "../../models/IUser";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";
import storageService from "../storage";

const getProfileInformation = async (constants: ConstantsConfig, nudoss: string | number): Promise<IUser> => {
  if (constants.useMockData) return fakeUser;
  const userKey = storageService.getFromSession(storageService.sessionKeys.USER_KEY);

  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/employees/${nudoss}?role=${userRole()}`, { headers: authHeaders() });
  return res.data;
};

export default getProfileInformation;
