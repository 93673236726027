import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import useConfig from "../../custom-hooks/useConfig";
import IEducation from "../../models/IEducation";
import { SelectOption } from "../../models/SelectOption";
import optionsService from "../../services/options";
import toastService from "../../services/toasts";
import DatePicker from "../input/DatePicker";
import SelectSource from "../select-source/SelectSource";
import "./editable-education-card.scss";
import { IEditableEducation } from "./interfaces/IEditable";

interface EditableEducationCardProps {
  education: IEditableEducation;
  onSave: (edu: IEducation) => void;
  onDelete?: (nulign: number) => void;
}

const EditableEducationCard: FunctionComponent<EditableEducationCardProps> = ({ education, onSave, onDelete }) => {
  const [localState, setState] = useState<IEditableEducation>(education);
  const [hovering, setHovering] = useState(false);
  const [hideEndDate, setHideEndDate] = useState(false);
  const { constants } = useConfig();
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (education) {
      let data: IEditableEducation = education;
      if (education.state === "CO") {
        setHideEndDate(false)
      }
      else {
        education.endDate = "";
        setHideEndDate(true)
      };
      setState(data);
    }
  }, [education])

  useEffect(() => {
    optionsService
      .getOption(constants, "UHT", localState.degree, "PAM")
      .then((codeExtraction: SelectOption) => {
        setState({ ...localState, degreeLabel: codeExtraction.liblon });
      })
      .catch(err => {
        console.log(err);
      });

  }, [localState.degree])

  const validateForm = () => {
    if (formRef.current?.reportValidity()) {
      if (hideEndDate) localState.endDate = "";
      const newEdu: IEducation = {
        nulign: localState.nulign,
        degree: localState.degree,
        degreeLabel: localState.degreeLabel,
        titleName: localState.titleName,
        majorDomain: localState.majorDomain,
        majorDomainArea: localState.majorDomainArea,
        majorDomainAreaLabel: localState.majorDomainAreaLabel,
        minorDomain: localState.minorDomain,
        minorDomainArea: localState.minorDomainArea,
        minorDomainAreaLabel: localState.minorDomainAreaLabel,
        startDate: localState.startDate,
        state: localState.state,
        stateLabel: localState.stateLabel,
        endDate: localState.endDate,
      };
      onSave(newEdu);
    } else {
      toastService.throwError("error.validation-error");
    }
  }

  if (localState.editing) {
    return (
      <div className="edit-edu-card">
        <form ref={formRef}>
          <div className="flex-row-end">
            <div className="edit-education-card-section full-width">
              <p className="text-medium-size text-standard text-bold">
                <FormattedMessage id="label.degree" />*
              </p>
              <SelectSource value={localState.degree} reg={"PAM"} dir={"UHT"} onChange={e => setState({ ...localState, degree: e })} required />
            </div>

            <div className="edit-action">
              <div className="flex-row-start text-white cursor-pointer full-width full-height" onClick={validateForm}>
                <label className="standard-button primary-button flex-row-space-between">
                  <FormattedMessage id="action.save" />
                </label>
              </div>
              {onDelete && <i className="fas fa-trash cursor-pointer" onClick={() => (localState.nulign ? onDelete(localState.nulign) : null)} />}
            </div>
          </div>

          {/* <div className="edit-education-card-section full-width">
            <p className="text-medium-size text-standard text-bold">
              <FormattedMessage id="label.school" />*
            </p>
            <TextField inputProps={{ maxLength: 25 }} id="standard-basic" value={localState.titleName} onChange={e => setState({ ...localState, titleName: e.target.value })} required />
          </div> */}

          <div className="flex-row-space-between">
            <div className="edit-education-card-section full-width">
              <p className="text-medium-size text-standard text-bold">
                <FormattedMessage id="label.major-domain-area" />
              </p>
              <SelectSource value={localState.majorDomainArea} reg={"PAM"} dir={"UHS"} onChange={e => setState({ ...localState, majorDomainArea: e })} withEmptyOption />
            </div>

            {/*<div className="edit-education-card-section full-width">
            <p className="text-medium-size text-standard text-bold">
              <FormattedMessage id="label.minor-domain-area" />
            </p>
            <SelectSource value={localState.minorDomainArea} reg={"PAM"} dir={"UHS"} onChange={e => setState({ ...localState, minorDomainArea: e })} withEmptyOption />
          </div>*/}
          </div>

          <div className="flex-row-space-between">
            <div className="edit-education-card-section full-width">
              <p className="text-medium-size text-standard text-bold">
                <FormattedMessage id="label.state" />
              </p>
              <SelectSource value={localState.state} reg={"PAM"} dir={"U5S"} onChange={e => {
                if (e === "CO" || e === "") setHideEndDate(false)
                else setHideEndDate(true);
                setState({ ...localState, state: e })
              }}
              />
            </div>
          </div>
          <div className="flex-row-space-between">
            <div className="edit-education-card-section full-width">
              <p className="text-medium-size text-standard text-bold">
                <FormattedMessage id="label.start-date" />*
              </p>
              <DatePicker withFormat value={localState.startDate} onChange={e => setState({ ...localState, startDate: e })} required />
            </div>

            {!hideEndDate &&
              <div className="edit-education-card-section full-width">
                <p className="text-medium-size text-standard text-bold">
                  <FormattedMessage id="label.end-date" />*
                </p>
                <DatePicker withFormat value={localState.endDate} onChange={e => setState({ ...localState, endDate: e })} required />
              </div>
            }
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className="edit-edu-card" onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <div className="flex-row-end">
        <div className="edit-education-card-section full-width">
          <p className="text-medium-size text-standard text-bold">
            <FormattedMessage id="label.degree" />
          </p>
          <p className="text-medium-size text-standard">{localState.degreeLabel}</p>
        </div>

        <div className="edit-action">
          {hovering && <i className="fas fa-pen cursor-pointer" onClick={() => setState({ ...localState, editing: true })} />}
          {hovering && <i className="fas fa-trash cursor-pointer" onClick={() => (localState.nulign ? (localState.nulign < 0 ? localState.editing = false : onDelete && onDelete(localState.nulign)) : (localState.editing = false))} />}
        </div>
      </div>

      {/* <div className="flex-row-space-between">
        <div className="edit-education-card-section full-width">
          <p className="text-medium-size text-standard text-bold">
            <FormattedMessage id="label.school" />
          </p>
          <p className="text-medium-size text-standard">{localState.titleName}</p>
        </div>
      </div> */}

      <div className="flex-row-space-between">
        {localState.majorDomainArea &&
          <div className="edit-education-card-section full-width">
            <p className="text-medium-size text-standard text-bold">
              <FormattedMessage id="label.major-domain-area" />
            </p>
            <p className="text-medium-size text-standard">{localState.majorDomainAreaLabel}</p>
          </div>}

        {localState.minorDomainArea &&
          <div className="edit-education-card-section full-width">
            <p className="text-medium-size text-standard text-bold">
              <FormattedMessage id="label.minor-domain-area" />
            </p>
            <p className="text-medium-size text-standard">{localState.minorDomainAreaLabel}</p>
          </div>}
      </div>

      {localState.stateLabel &&
        <div className="flex-row-space-between">
          <div className="edit-education-card-section full-width">
            <p className="text-medium-size text-standard text-bold">
              <FormattedMessage id="label.state" />
            </p>
            <p className="text-medium-size text-standard">{localState.stateLabel}</p>
          </div>
        </div>}

      <div className="flex-row-space-between">
        <div className="edit-education-card-section full-width">
          <p className="text-medium-size text-standard text-bold">
            <FormattedMessage id="label.start-date" />
          </p>
          <p className="text-medium-size text-standard">{localState.startDate}</p>
        </div>

        {localState.state === "CO" && (
          <div className="edit-education-card-section full-width">
            <p className="text-medium-size text-standard text-bold">
              <FormattedMessage id="label.end-date" />
            </p>
            <p className="text-medium-size text-standard">{localState.endDate}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditableEducationCard;
