import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classnames from "classnames";
import React, { FunctionComponent, ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import useSelectOptions from "../../custom-hooks/useSelectOptions";

interface SelectSourceProps {
  onChange: (e: string) => void;
  name?: string;
  withEmptyOption?: boolean;
  value: string;
  reg: string;
  dir: string;
  required?: boolean
}
const SelectSource: FunctionComponent<SelectSourceProps> = ({ onChange, value, name, withEmptyOption, reg, dir, required }): ReactElement => {
  const [options, loading] = useSelectOptions(reg, dir);

  return (
    <div>
      {name && (
        <label className={classnames("input-label")}>
          <FormattedMessage id={name} />
        </label>
      )}
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.liblon}
        id="id"
        value={{
          liblon: options.find(v => v.code === value)?.liblon!,
          code: options.find(v => v.code === value)?.code!,
          society: "PAM",
          nudoss: options.find(v => v.code === value)?.nudoss!,
          libabr: options.find(v => v.code === value)?.libabr!
        }}
        loading={loading}
        noOptionsText={withEmptyOption && ""}
        onChange={(event: any, newValue: any) => {
          onChange(newValue?.code)
        }}
        style={{ width: 250 }}
        renderInput={(params) => <TextField {...params} margin="normal" required={required} />}
      />
    </div>
  );
};

export default SelectSource;
