import axios from "axios";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";

interface AreaStatus {
  code: string,
  complete: boolean,
  description: string
}
export interface CompletedProfileData {
  complete: boolean,
  areaStatus?: AreaStatus[]
}

const getProfileCompleted = async (constants: ConstantsConfig, nudoss: string | number): Promise<CompletedProfileData> => {
  if (constants.useMockData) {
    const mock: CompletedProfileData = {
      complete: true
    }
    return mock;
  };

  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/employees/${nudoss}/isProfileComplete?role=${userRole()}`, { headers: authHeaders() });
  return res.data;
};

export default getProfileCompleted;