import { ConstantsConfig } from "../../models/ConstantsConfig";
import storageService from "../storage";

const checkLoggedIn = (constants: ConstantsConfig): Promise<boolean> =>
  new Promise(resolve => {
    const token: string | undefined = storageService.getFromSession(storageService.sessionKeys.AUTH_TOKEN_KEY);

    if (token) return resolve(true);

    // TODO: implement token validation
    return resolve(false);
  });

export default checkLoggedIn;
