import axios from "axios";
import fakeData from "../../mocked-data/fakeData";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import IEducation from "../../models/IEducation";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";

const getEducations = async (constants: ConstantsConfig, nudoss: string | number): Promise<IEducation[]> => {
  if (constants.useMockData) return fakeData.fakeRandomEducations;
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/educations?role=${userRole()}`, { headers: authHeaders() });
  return res.data;
};

export default getEducations;
