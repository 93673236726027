import deleteEducation from "./deleteEducation";
import getEducation from "./getEducation";
import getEducations from "./getEducations";
import postEducation from "./postEducation";
import updateEducation from "./updateEducation";

const educationsService = {
  getEducations,
  getEducation,
  postEducation,
  updateEducation,
  deleteEducation,
};

export default educationsService;
