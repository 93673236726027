import IUser from "../models/IUser";

const fakeUser: IUser = {
  nudoss: "1",
  description:
    "Soy desarrollador de software especializado en servicios web de nueva generación con experiencia en proyectos de transformación digital. Curioso por las nuevas técnicas y " +
    "tecnologías, me gusta formarme y enseñar lo que se siempre que puedo. Tecnologías que uso y conozco: API, Zuul, Hystrix, Eureka, Netflix OSS, Docker, Google Cloud Stack," +
    "Amazon AWS, Microsoft Azure, Java 1.7 y 1.8, Spring, Spring Web, Spring MVC, JUnit, Mockito, Maven, Tomcat, Jenkins, Nexus, Sonar, Jira, JavaScript, NodeJs, Express," +
    "React, Python, Django, Swagger, MongoDB, Oracle, MySQL, PostgreSQL, Git, SVN Subversion, Scrum, Kanban, JIRA",
  email: "alexander.trent@soprahr.com",
  firstName: "Alexander",
  matcle: "SOP1234",
  gender: "Male",
  dateOfBirth: "20/03/1976",
  domProvDescription: "", domProv: "", domCity: "", resProvDescription: "", resProv: "", resCity: "",
  phone: "+34 699545676",
  location: "Madrid",
  firstJob: true,
  userPrivacy: {
    accepted: true,
    protectedCategoryIsAccepted: true,
    acceptanceDate: "",
    application: ""
  }
};

export default fakeUser;
