import React, { ReactElement, FunctionComponent, useState, useEffect } from "react";
import TOPICS from "../../services/topics";
import SnackBarToast from "./SnackBarToast";
import { AppNotification } from "../../models/AppNotification";

interface ToastState {
  hasToast: boolean;
  notification?: AppNotification;
}

const initialState: ToastState = {
  hasToast: false,
  notification: undefined,
};

const FETCH_ERROR = "Unexpected token < in JSON at position 0";

const Toasts: FunctionComponent = (): ReactElement => {
  const [state, setState] = useState<ToastState>(initialState);

  useEffect(() => {
    const subscription = TOPICS.TOASTS_TOPIC.subscribe({
      next: (error: AppNotification) => {
        if (!error.message) setState({ hasToast: true, notification: { variant: error.variant, message: "error.default" } });
        else setState({ hasToast: true, notification: error });
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const clearError = () => {
    setState({ ...state, hasToast: false });
  };

  return <SnackBarToast open={state.hasToast} notification={state.notification} onClose={() => clearError()} />;
};

export default Toasts;
