import React, { FunctionComponent, ReactElement } from "react";
import { FormattedMessage } from "react-intl";

interface MainTitleProps {
  label: string;
}

const MainTitle: FunctionComponent<MainTitleProps> = ({ label}): ReactElement => {
  return (
    <p className="text-standard text-big-size text-bold flex-row-center">
      <FormattedMessage id={label} />
    </p>
  )
};

export default MainTitle;
