import React, { useState, ReactElement, FunctionComponent, useEffect, useContext } from "react";
import storageService from "../services/storage";
import ILoggingUser from "../models/ILoggingUser";
import employeeService from "../services/employees";
import authService from "../services/auth";
import useConfig from "../custom-hooks/useConfig";
import { AuthContext } from "../context";
import { showModal } from "../components/modal/ModalListener";
import { useHistory } from "react-router";
import IUser from "../models/IUser";

export interface UserContextState {
  user?: ILoggingUser;
  setUserContextState?: React.Dispatch<React.SetStateAction<UserContextState>>;
}

const initialContext: UserContextState = {};

export const UserContext: React.Context<UserContextState> = React.createContext<UserContextState>(initialContext);

export const UserContextProvider: FunctionComponent = ({ children }): ReactElement => {
  const [state, setUserContextState] = useState<UserContextState>(initialContext);
  const { setAuthContextState } = useContext(AuthContext);
  const { constants } = useConfig();
  const history = useHistory();

  useEffect(() => {
    employeeService.getMyInfo(constants)
    .then(user => {
      employeeService
      .getProfileInformation(constants, user.nudoss)
      .then((u: IUser) => {
        if (u.userPrivacy.accepted) {
          setAuthContextState?.({ checked: true, isAuth: true });
        } else {
          setAuthContextState?.({ checked: true, isAuth: false });
          history.push("/privacy-policy/info");
        }
      })
      .catch(err => {
        console.log(err);
      });
      setUserContextState?.({ user })
    });

  }, []);

  const updateLocalUser = (u: ILoggingUser) => {
    storageService.saveToSession(storageService.sessionKeys.USER_KEY, u);
    setUserContextState({ user: u });
  };

  return <UserContext.Provider value={{ ...state, setUserContextState }}>{children}</UserContext.Provider>;
};
