// import { useTranslatedMessagesString } from "../../custom-hooks/useTranslatedMessagesString";
import TOPICS from "../topics";

const throwSuccess = (message: string = "label.operation-success") => {
  // const newMessage: string = useTranslatedMessagesString(message);

  TOPICS.TOASTS_TOPIC.next({ message: message, variant: "success" });
};

export default throwSuccess;
