
import getInterestsList from "./getInterestsList";
import getUserInterest from "./getUserInterest";
import addUserInterest from "./addUserInterest";
import deleteUserInterest from "./deleteUserInterest";
import updateUserInterest from "./updateUserInterest";

const interestsService = {
    getInterestsList,
    addUserInterest,
    getUserInterest,
    deleteUserInterest,
    updateUserInterest
};

export default interestsService;
