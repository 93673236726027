import React, { FunctionComponent, useEffect, useState } from "react";
import useConfig from "../../custom-hooks/useConfig";
import IExperience from "../../models/IExperience";
import experiencesService from "../../services/experiences";
import toastService from "../../services/toasts";
import { formatDate, formatDateToStandard } from "../../utils/time/time";
import ButtonDefault from "../button/ButtonDefault";
import Subtitle from "../titles/Subtitle";
import EditableExperienceCard from "./EditableExperienceCard";
import { EditSectionProps } from "./interfaces/EditSectionProps";
import { IEditableExperience } from "./interfaces/IEditable";

interface ExperiencesEditSectionState {
  experiences: IEditableExperience[];
}

const ExperiencesEditSection: FunctionComponent<EditSectionProps<IExperience>> = ({ nudoss, updated, userData, experiences }) => {
  const { constants } = useConfig();
  const [state, setState] = useState<ExperiencesEditSectionState>({ experiences: [] });
  const [loading, setLoading] = useState(false);
  const [newExperience, setNewExperience] = useState<ExperiencesEditSectionState>({ experiences: [] });

  useEffect(() => {
    if (nudoss) {
      experiencesService
        .getExperiences(constants, nudoss)
        .then((newExperiences: IExperience[]) => {
          // newExperiences = _.orderBy(newExperiences, ['nulign'], ['desc']);
          if (experiences) experiences(newExperiences);
          setState({ ...state, experiences: newExperiences.map(e => ({ ...e, editing: false, startDate: formatDateToStandard(e.startDate), endDate: formatDateToStandard(e.endDate) })) });
          if (newExperiences.length === 0)
            addExperience();
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [nudoss, loading]);

  const addExperience = () => {
    const newExp: IEditableExperience = {
      nulign: -1,
      name: "",
      company: "",
      startDate: "",
      endDate: "",
      description: "",
      editing: true,
      sector: "",
      sectorLabel: ""
    };
    setNewExperience({ experiences: [...newExperience.experiences, newExp] });
  };

  const onDeleteExp = (nulign: number) => {
    setLoading(true)
    if (nulign === -1) {
      setNewExperience({ experiences: [] });
    } else {
      if (nudoss) {
        const deletedExperience: IEditableExperience | undefined = state.experiences.find(e => e.nulign === nulign);
        if (deletedExperience) {
          experiencesService
            .deleteExperience(constants, nudoss, deletedExperience.nulign)
            .then(res => {
              console.log("deleteExperience res", res);
              toastService.throwSuccess();
              updated(true);
              setLoading(false);
            })
            .catch(err => {
              console.log("deleteExperience err", err);
              toastService.throwError(err.response.data.description);
            });
        }
      }
    }

  };

  const onSaveExperience = (exp: IExperience) => {
    if (nudoss) {
      setLoading(true)
      const editedExperience: IExperience | undefined = state.experiences.find(e => e.nulign === exp.nulign);
      if (editedExperience) {
        editedExperience.nulign = exp.nulign;
        editedExperience.name = exp.name;
        editedExperience.company = exp.company;
        editedExperience.startDate = formatDate(exp.startDate);
        editedExperience.endDate = formatDate(exp.endDate);
        editedExperience.description = exp.description;
        editedExperience.sector = exp.sector;
        editedExperience.sectorLabel = exp.sectorLabel;

        experiencesService
          .updateExperience(constants, nudoss, editedExperience.nulign, editedExperience)
          .then(res => {
            console.log("updateExperience res", res);
            toastService.throwSuccess();
            updated(true);
            setLoading(false);
          })
          .catch(err => {
            console.log("updateExperience err", err);
            toastService.throwError(err.response.data.description);
          });
      }
    }
  }

  const onSaveNewExperience = (exp: IExperience) => {
    if (nudoss) {
      setLoading(true)
      const newExp: IExperience | undefined = newExperience.experiences.find(e => e.nulign === exp.nulign);
      if (newExp) {
        newExp.nulign = exp.nulign;
        newExp.name = exp.name;
        newExp.company = exp.company;
        newExp.startDate = formatDate(exp.startDate);
        newExp.endDate = formatDate(exp.endDate);
        newExp.description = exp.description;
        newExp.sector = exp.sector;
        newExp.sectorLabel = exp.sectorLabel;
        experiencesService
          .postExperience(constants, nudoss, newExp)
          .then((res: IExperience) => {
            console.log("createExperience res", res);
            toastService.throwSuccess();
            updated(true);
            setLoading(false);
            setTimeout(() => {
              setNewExperience({ experiences: [] });
            }, 500);

          })
          .catch(err => {
            console.log("createExperience err", err);
            toastService.throwError(err.response.data.description);
          });

      }
    }
  }
  if (userData.firstJob) return <React.Fragment />
  return (
    <div className="edit-section-container">
      <div className="first-subsection">
        <Subtitle label="label.job-experiences" mandatory />
        {userData && <ButtonDefault icon="plus" disabled={newExperience.experiences.length > 0} onClick={addExperience} color="secondary" size="small" />}
      </div>
      {newExperience.experiences.map(e => (
        <EditableExperienceCard experience={e} onSave={onSaveNewExperience} onDelete={onDeleteExp} />
      ))}
      {state.experiences.map(e => (
        <EditableExperienceCard experience={e} onSave={onSaveExperience} onDelete={onDeleteExp} />
      ))}
    </div>
  );
};

export default ExperiencesEditSection;
