import axios from "axios";
import Avatar from "../../media/user.png";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";

const getStaffPhoto = async (constants: ConstantsConfig, nudoss: string | number): Promise<string> => {
  if (constants.useMockData) return Avatar;
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/photo?role=${userRole()}`, { headers: authHeaders(false, true) });
  return res.data;
};
export default getStaffPhoto;
