import React, { useContext, useState, ReactElement, FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { LanguageContext } from "../../context/LanguageContext";
import constants from "../../constants";
import Icon from "../icon/Icon";
import FloatingMenu from "../floating-menu/FloatingMenu";
import FloatingMenuItem from "../floating-menu/FloatingMenuItem";
import { SelectOptionDefault } from "../../models/SelectOption";

interface SwitchLanguageProps {
  withText?: boolean;
  options?: SelectOptionDefault[];
}

const SwitchLanguage: FunctionComponent<SwitchLanguageProps> = ({ withText, options }): ReactElement => {
  const [open, setOpen] = useState(false);
  const { updateLanguage } = useContext(LanguageContext);
  return (
    <React.Fragment>
      <div className="flex-row-start text-white cursor-pointer" onClick={() => setOpen(prev => !prev)}>
        <Icon dropDownType name="flag" light />
        {withText && <FormattedMessage id="label.language" />}
      </div>
      <FloatingMenu showing={open} black onClose={() => setOpen(false)}>
        {options ? (
          options.map(option => (
            <FloatingMenuItem whiteText key={option.value} onClick={() => updateLanguage(option.value)}>
              <FormattedMessage id={option.label} />
            </FloatingMenuItem>
          ))
        ) : (
          <React.Fragment />
        )}
      </FloatingMenu>
    </React.Fragment>
  );
};

SwitchLanguage.defaultProps = {
  options: constants.SUPPORTED_LANGUAGES.map((lang: string) => ({
    label: `lang-${lang}`,
    value: lang,
  })),
};

export default SwitchLanguage;
