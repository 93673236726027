import axios from "axios";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import authHeaders from "../auth/authHeaders";

const getOptions = async (constants: ConstantsConfig, reg: string, dir: string): Promise<[]> => {
  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/detail/${dir}/${reg}`, { headers: authHeaders(true) });
  return res.data;
};

export default getOptions;
