import throwError from "./throwError";
import throwSuccess from "./throwSuccess";
import throwInfo from "./throwInfo";
import throwWarning from "./throwWarning";

const toastService = {
  throwError,
  throwSuccess,
  throwInfo,
  throwWarning,
};

export default toastService;
