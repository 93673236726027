import storageService from "../storage";
interface role {
  canonical: string;
  template: string;
  category: string;
}
const userRole = (): string | undefined => {
  const userKey = storageService.getFromSession(storageService.sessionKeys.USER_KEY);
  if (userKey.roles) {
    const roles: [role] = userKey.roles;
    const filteredRoles = roles.filter(e => e.template == "CANDIDAT");
    return filteredRoles[0].canonical;
  }
  return undefined;
};

export default userRole;
