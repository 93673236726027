import faker from "faker";
import IExperience from "../models/IExperience";

interface FakeExperience {
  company: string;
  name: string;
  description: string;
  location: string;
  sector: string;
  sectorLabel: string
}

const fakeExperiences: FakeExperience[] = [
  {
    company: "SopraHR Software",
    name: "Cobol Developer",
    description: "I developed very important business related procedures using Cobol and SQL",
    location: "Madrid, Spain",
    sector: "",
    sectorLabel: ""
  },
  {
    company: "Microsoft",
    name: "Software Developer",
    description: "I developed C# programs for the newest company products",
    location: "Chicago, USA",
    sector: "",
    sectorLabel: ""
  },
  {
    company: "Google",
    name: "Software Developer",
    description: "I developed microservices using the Go programming language while working for the Firebase team",
    location: "Berlin, Germany",
    sector: "",
    sectorLabel: ""
  },
];

const generateFakeExperience = (): IExperience => {
  const fakeExperience = faker.helpers.shuffle(fakeExperiences)[0];
  return {
    nulign: faker.random.number(),
    company: fakeExperience.company,
    description: fakeExperience.description,
    name: fakeExperience.name,
    startDate: faker.date.past().toLocaleDateString(),
    endDate: faker.date.past().toLocaleDateString(),
    sector: fakeExperience.sector,
    sectorLabel: fakeExperience.sectorLabel,
  };
};

export default generateFakeExperience;
