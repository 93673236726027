import React, { FunctionComponent } from "react";
import { useHistory } from "react-router";
import IJob from "../../models/IJob";
import CompanyLogo from "../logo/CompanyLogo";
import "./jobList.scss";

const JobListItem: FunctionComponent<IJob> = props => {
  const history = useHistory();
  return (
    <div className="joblist-item-container" onClick={() => history.push("/jobs/" + props.id)}>
      <div className="job-data-container">
        <div className="job-logo-container">
          <CompanyLogo id={props.id} />
        </div>
        <div className="job-info-container">
          <p className="text-standard cursor-pointer text-medium-size text-bold">{props.name}</p>
          <div className="job-info-subcontainer">
            <p className="text-standard cursor-pointer">{props.companyLabel}</p>
            <p className="text-standard cursor-pointer">{props.location ? props.location : props.countryLabel}</p>
          </div>
        </div>
      </div>

      {/* <p className="text-standard text-double padding-left cursor-pointer text-italic text-grey"> */}
      {/*<AmountApplicants idPosition={props.id}/>*/}
      {/* </p> */}
    </div>
  );
};

export default JobListItem;
