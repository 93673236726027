import faker from "faker";
import IEducation from "../models/IEducation";
import IExperience from "../models/IExperience";
import ISkill from "../models/ISkill";
import IUser from "../models/IUser";
import generateFakeEducation from "./generateFakeEducation";
import generateFakeExperience from "./generateFakeExperience";
import generateFakeSkill from "./generateFakeSkill";

const generateFakeUser = (): IUser => {
  const fakeEducations: IEducation[] = [1, 2, 3].map(() => generateFakeEducation());
  const fakeJobs: IExperience[] = [1, 2, 3].map(() => generateFakeExperience());
  const fakeSkills: ISkill[] = [1, 2, 3].map(() => generateFakeSkill());

  return {
    nudoss: faker.random.uuid(),
    dateOfBirth: faker.date.past().toLocaleDateString(),
    description: faker.random.words(125),
    email: faker.internet.email(),
    location: `${faker.address.city()}, ${faker.address.country()}`,
    firstName: faker.name.findName(),
    matcle: faker.random.uuid(),
    gender: faker.random.word(),
    phone: faker.phone.phoneNumber(),
    firstJob: true,
    domProvDescription: "", domProv: "", domCity: "", resProvDescription: "", resProv: "", resCity: "",
    userPrivacy: {
      accepted: true,
      protectedCategoryIsAccepted: true,
      acceptanceDate: "",
      application: "JOBS"
    }
  };
};

export default generateFakeUser;
