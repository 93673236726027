import classnames from "classnames";
import React, { Fragment, ReactElement, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import Icon from "../icon/Icon";
import Loading from "../loading/Loading";
import "./Button.scss";

interface ButtonProps {
  text: string;
  bubble: ReactNode;
  disabled: boolean;
  loading: boolean;
  fullWidth: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  style: Object;
  className: string;
  icon: string;
  iconAtTheEnd?: boolean;
  dropdown: boolean;
  color: "none" | "primary" | "secondary" | "third" | "toolbar";
  size: "standard" | "super-small" | "small" | "big" | "full";
  marginTop: boolean;
  children: ReactElement;
}

const ButtonDefault = ({ text, bubble, fullWidth, loading, disabled, onClick, style, className, icon, dropdown, color, size, marginTop, iconAtTheEnd, children }: ButtonProps) => {
  const space = String.fromCharCode(160);

  return (
    <button
      className={classnames(
        "flex-row-center",
        { "standard-button": !disabled },
        "padding-button",
        { "full-width": fullWidth },
        { "standard-button-disabled": disabled },
        { "margin-top": marginTop },
        { "transparent-button": color === "none" },
        { "primary-button": color === "primary" },
        { "secondary-button": color === "secondary" },
        { "third-button": color === "third" },
        { "header-button": color === "toolbar" },
        { "standard-size-button": size === "standard" },
        { "super-small-size-button": size === "super-small" },
        { "small-size-button": size === "small" },
        { "big-size-button": size === "big" },
        { "full-size-button": size === "full" },
        className
      )}
      style={style}
      onClick={e => (!loading && !disabled && onClick ? onClick(e) : undefined)}
    >
      {loading ? (
        <Loading type="button" />
      ) : (
        <Fragment>
          {icon && !iconAtTheEnd && <Icon name={icon} light />}
          {bubble && <div className="circular-button-badge">{bubble}</div>}
          {bubble && icon && `${space}${space}`}
          {text && icon && `${space}${space}`}
          {text && <FormattedMessage id={text} />}
          {dropdown && text && `${space}${space}`}
          {dropdown && <i className="fas fa-sort-down" />}
          {icon && iconAtTheEnd && <Icon name={icon} light />}
          {children != null ? children : <Fragment />}
        </Fragment>
      )}
    </button>
  );
};

ButtonDefault.defaultProps = {
  text: "",
  bubble: undefined,
  disabled: false,
  loading: false,
  fullWidth: false,
  onClick: () => { },
  style: {},
  icon: "",
  className: "",
  success: false,
  dropdown: false,
  iconAtTheEnd: false,
  headerType: false,
  jobListType: false,
  children: null,
  marginTop: false,
};

export default ButtonDefault;
