import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { LanguageContext } from "../../context";
import useConfig from "../../custom-hooks/useConfig";
import useSecurity from "../../custom-hooks/useSecurity";
import useUser from "../../custom-hooks/useUser";
import staffService from "../../services/staff";
import SelectedPosition from "../job-card/SelectedPosition";
import CompanyLogo from "../logo/CompanyLogo";
import "./feedItem.scss";

interface FeedJobItemProps {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  company: string;
  country: string;
  location: string;
  description: string;
}

const FeedJobItem: FunctionComponent<FeedJobItemProps> = ({ id, name, startDate, endDate, company, country, location, description }) => {
  const [state, setstate] = useState({ loading: false, isApplied: false });
  const { user } = useUser();
  const { isAuth } = useSecurity();
  const { constants } = useConfig();
  const { language } = useContext(LanguageContext);
  const history = useHistory();

  useEffect(() => {
    if (user) {
      setstate({ ...state, loading: true });
      staffService
        .getPositionApplied(constants, id, user?.nudoss)
        .then((res: boolean) => {
          setstate({ ...state, loading: false, isApplied: res });
        })
        .catch(err => {
          console.log(err);
          setstate({ ...state, loading: false });
        });
    }
  }, [id]);

  const isSpontanea = (id: string): boolean => {
    return id === "CSPONTANEA";
  }

  return (
    <div className={`feed-item-container ${isSpontanea(id) ? 'special-position' : ''}`} onClick={() => history.push(`/jobs/${id}`)}>
      <div className="feed-info-first-container">
        {!isSpontanea(id) && <CompanyLogo id={id} size="small" />}
        {isSpontanea(id) && <p className="text-standard">Non trovi tra gli annunci un profilo corrispondente al tuo? Invia qui la tua</p>}
        {id && <SelectedPosition isApplied={state.isApplied} />}
        <div className="feed-info-second-sub-container">
          <p className="text-standard text-bold">{name}</p>
          <p className="text-standard text-grey text-italic">{location}</p>

        </div>
      </div>
      <div className="feed-actions-container">
        {/* Sustituir por horario        */}
        {/* {id && <AmountApplicants idPosition={id} withoutText />} */}
        {/* <p className="text-standard text-grey text-italic margin-top-auto">
          <FormattedMessage id="label.end-date" />: {formatDateToStandard(endDate)}
        </p> */}
      </div>
    </div>
  );
};

export default FeedJobItem;
