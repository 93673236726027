import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import useConfig from "../../custom-hooks/useConfig";
import Avatar from "../../media/user.png";
import staffDocumentService from "../../services/staff-document";
import toastService from "../../services/toasts";
import Icon from "../icon/Icon";
import Loading from "../loading/Loading";
import { Tooltip } from "../tooltip/Tooltip";

interface UserImageProps {
  nudoss?: string | number;
}

interface UserImageState {
  loading: boolean;
  image: string;
}

const EditUserImage: FunctionComponent<UserImageProps> = ({ nudoss }) => {
  const { constants } = useConfig();
  const [state, setState] = useState<UserImageState>({ loading: false, image: Avatar });
  const updateImage = (photo: File) => {
    if (nudoss && photo) {
      setState({ ...state, loading: true });
      staffDocumentService
        .updateStaffPhoto(constants, nudoss, photo)
        .then(res => {
          console.log("photo", res);
          loadImage();
        })
        .catch(err => {
          console.log("error photo", err);
          toastService.throwError(err.response.data.description);
          setState({ ...state, loading: false });
        });
    }
  };

  const loadImage = () => {
    if (nudoss) {
      setState({ ...state, loading: true });
      staffDocumentService
        .getStaffPhoto(constants, nudoss)
        .then(img => {
          setState({ loading: false, image: img });
        })
        .catch(err => {
          setState({ ...state, loading: false });
        });
    }
  };

  const deleteImage = () => {
    if (nudoss) {
      setState({ ...state, loading: true });
      staffDocumentService
        .deleteStaffPhoto(constants, nudoss)
        .then(img => {
          setState({ loading: false, image: Avatar });
        })
        .catch(err => {
          setState({ ...state, loading: false });
        });
    }
  };

  useEffect(() => {
    loadImage();
  }, [nudoss]);

  return (
    state.loading ?
      <div className="user-edit-image-container">
        <Loading />
      </div>
      :
      <Fragment>
        <div className="user-edit-image-container">
          <div className="user-edit-image-delete">
            <label className="cursor-pointer" onClick={deleteImage}>
              <Tooltip text="action.delete-photo">
                <Icon name="times-circle" />
              </Tooltip>
            </label>
          </div>
          <img src={state.image} alt="User Avatar" className="user-edit-image" />
          <div className="user-edit-image-input">
            <label className="cursor-pointer">
              <input
                type="file"
                accept="image/png,image/jpeg"
                style={{ display: "none" }}
                onChange={e => {
                  if (e.target.files !== null) {
                    updateImage(e.target.files[0]);
                  }
                }}
              />
              <Icon name="edit" />
            </label>
          </div>
        </div>
      </Fragment>
  );
};

export default EditUserImage;
