import React, { ReactElement, FunctionComponent } from "react";
import classnames from "classnames";
import "./backgroundCardImage.scss";

interface HeaderCardImageProps {
  children?: ReactElement | ReactElement[];
}

const HeaderCardImage: FunctionComponent<HeaderCardImageProps> = (props): ReactElement => {
  return <div className={classnames("header-card-image")}>{props.children}</div>;
};


export default HeaderCardImage;
