import axios from "axios";
import fakeToken from "../../mocked-data/fakeToken";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import storageService from "../storage";

const login = async (constants: ConstantsConfig, username: string, password: string, loginModule: "UC10LoginModule" | "LdapLoginModule"): Promise<void> => {
  if (constants.useMockData) {
    storageService.saveToSession(storageService.sessionKeys.AUTH_TOKEN_KEY, fakeToken);
    return;
  }

  const res = await axios.post(`${constants.REACT_APP_WS_URL}/hr-auth/authenticate`, { username, password, loginModule });
  const { jwt } = res.data;
  storageService.saveToSession(storageService.sessionKeys.AUTH_TOKEN_KEY, jwt);
};

export default login;
