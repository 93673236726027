import ISkill from "../models/ISkill";
import faker from "faker";

const generateFakeSkill = (): ISkill => {
  const randomNames: string[] = ["HTML", "CSS", "JavaScript", "Cobol", "Java", "C#"];
  return {
    code: "",
    nulign: faker.random.number(10),
    label: faker.helpers.shuffle(randomNames)[0],
    level: `${faker.random.number(10)}`,
  };
};

export default generateFakeSkill;
