// import { Tooltip } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from "react-router";
import useConfig from "../../custom-hooks/useConfig";
import IUser from "../../models/IUser";
import employeeService from "../../services/employees";
import { CompletedProfileData } from '../../services/employees/getProfileCompleted';
import interestsService from "../../services/interests";
import "../card-header/cardHeader.scss";
import Icon from "../icon/Icon";
import Subtitle from "../titles/Subtitle";
import { Tooltip } from "./../tooltip/Tooltip";
import DownloadCVBtn from "./DownloadCVBtn";
import UserImage from "./UserImage";

interface UserHeaderProps {
  nudoss: string | number;
}

interface UserHeaderState {
  loading: boolean;
  user?: IUser;
  completedProfile: CompletedProfileData;
}

interface ChipData {
  priority: number;
  label: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'start',
      width: '50%',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }),
);

const UserHeader: FunctionComponent<UserHeaderProps> = ({ nudoss }): ReactElement => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { constants } = useConfig();
  const [state, setState] = useState<UserHeaderState>({ loading: false, completedProfile: { complete: false } });
  const [chipData, setChipData] = useState<ChipData[]>([]);

  useEffect(() => {

    const p1 = employeeService.getProfileInformation(constants, nudoss);
    const p2 = interestsService.getUserInterest(nudoss);
    const p3 = employeeService.getProfileCompleted(constants, nudoss);

    Promise.all([p1, p2, p3])
      .then(values => {
        let list: ChipData[] = [];
        values[1].forEach((e: any) => {
          if (e.nudoss) list.push({ priority: e.priority, label: e.areaLabel })
        });
        setChipData(list);
        setState({ ...state, user: values[0], completedProfile: values[2], loading: false });
      })
      .catch(err => {
        console.log(err);
        setState({ ...state, loading: false });
      });

  }, [nudoss]);

  const completedProfileValues = (completedProfile: CompletedProfileData) => {
    if (completedProfile.complete) {
      return { color: "#179957", icon: "user-check", label: "label.profilecompleted" };
    }
    else {
      return { color: "#c42030", icon: "user-shield", label: "label.profileincompleted" };
    }
  }

  return (
    <div className="card-header-container">
      <div className="card-header-first-container">
        <div className="user-info">
          <UserImage nudoss={nudoss} size="big" borderShape="cylindrical" />
          <div className="card-header-data-container">
            <div className="card-header-first-data">
              <p className="text-standard text-bold text-big-size">{state.user?.firstName} {state.user?.surname}</p>
              <p className="text-standard text-bold text-medium-size" style={{ display: 'flex', alignItems: 'baseline' }}><Icon name="phone" withoutContainer />{state.user?.phone}</p>
              <p className="text-standard text-bold text-medium-size" style={{ display: 'flex', alignItems: 'baseline' }}><Icon name="envelope" withoutContainer />{state.user?.email}</p>
              <p className="text-standard text-bold text-medium-size" style={{ display: 'flex', alignItems: 'baseline', color: completedProfileValues(state.completedProfile).color }}>
                <Icon name={completedProfileValues(state.completedProfile).icon} withoutContainer />
                <FormattedMessage id={completedProfileValues(state.completedProfile).label} />
              </p>
            </div>
          </div>
        </div>

        {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
        <div className="user-buttons">
          <div className="icon-container" /*data-tip="Modifica Profilo"*/>
            {location.pathname === "/my-profile" &&
              <Tooltip text='complete.the.profile' visible color={state.completedProfile.complete ? 'info' : 'danger'}>
                <Icon big name="edit" onClick={() => history.push("my-profile-edit")} />
              </Tooltip>}
          </div>
          {state.user?.cv ?
            (<div className="icon-container" data-tip="Scarica CV">
              <DownloadCVBtn nudoss={nudoss} />
            </div>) :
            (<div className="icon-container" data-tip="In attesa di caricamento CV">
              <Icon big name="file-excel" />
            </div>)
          }
          {/* <ReactTooltip place="top" type="dark" effect="solid" /> */}
        </div>
      </div>

      <div className="card-header-second-container">
        <Subtitle label="label.interesse" />
        {chipData.length > 0 ? chipData.map((data) =>
          <Chip
            label={data.label}
            className={classes.chip}
          />
        )
          :
          <p className="text-standard text-grey text-italic">No data</p>
        }
        {/*<Subtitle label="label.aboutyou" />
        <ShowMoreText
          numberOfLines={5}
          longText={state.user?.description ? state.user.description : "No data"}
          classes={"text-standard text-large text-grey text-ellipsis padding-top-small"}
      />*/}
      </div>
    </div>
  );
};

export default UserHeader;
