import React, { FunctionComponent, ReactElement } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import Icon from "../icon/Icon";
import "./Input.scss";

interface TextFieldProps {
  id?: string;
  readOnly?: boolean;
  required?: boolean;
  type: "text" | "email" | "password" | "number" | "date" | "time" | "range" | string;
  value: string | number;
  onChange?: (e: string) => void;
  onClick?: (id?: string) => void;
  step?: number;
  min?: number;
  max?: number;
  label?: string;
  icon?: string;
  backgroundDark?: boolean;
  aligned?: boolean;
  size?: "small" | "medium" | "big" | "full-width",
  textStyle?: "bold" | "italic",
  placeholder?: string;
}

const TextField: FunctionComponent<TextFieldProps> = ({
  id,
  type,
  value,
  icon,
  readOnly,
  onChange,
  step,
  max,
  min,
  required,
  onClick,
  label,
  backgroundDark,
  aligned,
  size,
  textStyle,
  placeholder,
}): ReactElement => {
  return (
    <div>
      {label ? (
        <label className={classnames("input-label", { "text-white": backgroundDark })} htmlFor={id}>
          <FormattedMessage id={label} />
        </label>
      ) : (
        <React.Fragment />
      )}
      <div
        className={classnames(
          "input-container",
          { inline: aligned || size === "medium" },
          { "full-width": size === "full-width" && !aligned },
          { "third-width": size === "small" || aligned },
          { "half-width": size === "medium" },
          { "doble-third-width": size === "big" },
        )}
        onClick={onClick && !readOnly ? () => onClick(id) : () => {}}
      >
        <input
          className={classnames(
            "text-input",
            { "text-standard": readOnly },
            { "cursor-pointer": onClick && value !== undefined && value !== "" },
            { "text-bold": textStyle === "bold" },
            { "text-big-size": size === "big" },
            { "text-medium-size": size === "medium" },
            { "text-italic": textStyle === "italic" }
          )}
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={readOnly}
          type={type}
          value={value || ""}
          onChange={e => (onChange ? onChange(e.target.value) : () => {})}
          id={id}
          step={step}
          max={max}
          min={min}
          required={required}
          autoComplete="off"
        />
        {icon ? <Icon name={icon} onClick={onClick} light={backgroundDark} withoutContainer/> : <React.Fragment />}
      </div>
    </div>
  );
};

export default TextField;
