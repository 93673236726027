import React, { FunctionComponent, useEffect, useState } from "react";
import ISkill from "../../models/ISkill";
import ReactApexChart from "react-apexcharts";
import generateUserChartSeriesAndOptions from "../../utils/user-chart/utils/generateUserChartSeriesAndOptions";
import useConfig from "../../custom-hooks/useConfig";
import skillsService from "../../services/skills";
import Subtitle from "../titles/Subtitle";

interface UserSkillsProps {
  nudoss: string | number;
}

interface UserSkillsState {
  loading: boolean;
  skills: ISkill[];
}

const UserSkills: FunctionComponent<UserSkillsProps> = ({ nudoss }) => {
  const [state, setState] = useState<UserSkillsState>({ loading: false, skills: [] });
  const { constants } = useConfig();
  useEffect(() => {
    setState({ ...state, loading: true });
    skillsService
      .getSkills(constants, nudoss)
      .then((newSkills: ISkill[]) => {
        setState({ ...state, loading: false, skills: newSkills });
      })
      .catch(err => {
        console.log(err);
        setState({ ...state, loading: false });
      });
  }, [nudoss]);

  const [series, options] = generateUserChartSeriesAndOptions(state.skills);
  return (
    <React.Fragment>
      <Subtitle label="label.my-skills" />
      <div style={{ position: "relative", width: "100%", top: "-2rem", height: "11rem"}}>
        <ReactApexChart series={series} options={options} type="radar" />
      </div>
    </React.Fragment>
  );
};

export default UserSkills;
