/* eslint-disable no-restricted-globals */
import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import ViewDetection from '../infrastructure/view-detection';


export const WindowSizeContext = React.createContext({
  windowSize: screen.width,
  view: ViewDetection.getCurrentView(screen.width),
  isMobile: ViewDetection.isMobileView(screen.width),
  isTablet: ViewDetection.isTabletView(screen.width),
  isDesktop: !ViewDetection.isMobileView(screen.width) && !ViewDetection.isTabletView(screen.width),
});

export const WindowSizeContextProvider: FunctionComponent = ({ children  }): ReactElement => {
  const [state, setState] = useState({
    windowSize: screen.width,
    view: ViewDetection.getCurrentView(screen.width),
    isMobile: ViewDetection.isMobileView(screen.width),
    isTablet: ViewDetection.isTabletView(screen.width),
    isDesktop: !ViewDetection.isMobileView(screen.width) && !ViewDetection.isTabletView(screen.width),
  });

  const updateWindowDimensions = () => {
    const { width } = screen;
    const newState = {
      windowSize: width,
      view: ViewDetection.getCurrentView(width),
      isMobile: ViewDetection.isMobileView(width),
      isTablet: ViewDetection.isTabletView(width),
      isDesktop: !ViewDetection.isMobileView(width) && !ViewDetection.isTabletView(width),
    };
    setState(newState);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  });

  return (
    <WindowSizeContext.Provider value={state}>
      {children}
    </WindowSizeContext.Provider>
  );
};
