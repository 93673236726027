import React, { Fragment, FunctionComponent, useState } from "react";
import { FormattedMessage } from "react-intl";
import useConfig from "../../custom-hooks/useConfig";
import staffDocumentService from "../../services/staff-document";
import getStaffCV from "../../services/staff-document/getStaffCV";
import toastService from "../../services/toasts";
import ButtonDefault from "../button/ButtonDefault";
import Loading from "../loading/Loading";
import { Tooltip } from "../tooltip/Tooltip";

interface EditUserCVProps {
  nudoss?: string | number;
  value?: string;
  updated: Function;
}

const EditUserImage: FunctionComponent<EditUserCVProps> = ({ nudoss, updated }) => {
  const { constants } = useConfig();
  const [loading, setLoading] = useState(false);

  const updateCV = (cv: File) => {
    setLoading(true);
    if (nudoss && cv) {
      staffDocumentService
        .updateStaffCV(constants, nudoss, cv)
        .then(res => {
          console.log("updateStaffCV res", res);
          toastService.throwSuccess();
          setLoading(false);
          updated(true);
        })
        .catch(err => {
          console.log("updateStaffCV err", err);
          toastService.throwError(err.response.data.description);
          setLoading(false);
        });
    }
  };

  const deleteCV = () => {
    if (nudoss) {
      staffDocumentService
        .deleteStaffCV(constants, nudoss)
        .then(res => {
          toastService.throwSuccess();
          updated(true);
        })
        .catch(err => {

          toastService.throwError(err.response.data.description);
        });
    }
  };

  const downloadCV = () => {
    if (nudoss)
      getStaffCV(constants, nudoss).then(res => console.log(res));
  }

  //TODO: Check if this function has to be removed !!
  const handleChange = (e: any) => {
    if (e.target.files !== null) {
      updateCV(e.target.files[0]);
    }
  };

  return (
    <Fragment>
      <div style={{ display: "none" }}>
        <input
          name="fileInput"
          id="fileInput"
          type="file"
          accept="application/pdf"
          disabled={false}
          onChange={e => {
            if (e.target.files !== null) {
              updateCV(e.target.files[0]);
            }
          }}
          value=""
        />
      </div>
      <div className="flex-row-space-between">
        <label className="standard-button primary-button flex-row-space-between" htmlFor="fileInput">
          {loading ? <Loading type="small" />
            : <React.Fragment>
              <FormattedMessage id="action.upload-cv" />*
              <i className="fas fa-upload margin-left" />
            </React.Fragment>
          }
        </label>
        <Tooltip text="action.downloadCV" >
          <ButtonDefault icon="file-download" size="super-small" color="primary" style={{ padding: 0 }} onClick={downloadCV} />
        </Tooltip>
        <Tooltip text="action.deleteCV" >
          <ButtonDefault icon="file-excel" size="super-small" color="primary" style={{ padding: 0 }} onClick={deleteCV} />
        </Tooltip>
      </div>
      <i className="text-alert margin-top">*<FormattedMessage id="warning.maxsizefile" values={{ size: constants.MAX_CV_SIZE }} /></i>
    </Fragment>
  );
};

export default EditUserImage;
