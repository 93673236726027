import React, { FunctionComponent, useState } from "react";
import { FormattedMessage } from "react-intl";
import ISkill from "../../models/ISkill";
import ButtonDefault from "../button/ButtonDefault";
import SelectSource from "../select-source/SelectSource";
import "./editable-skill-card.scss";
import { IEditableSkill } from "./interfaces/IEditable";

interface EditableSkillCardProps {
  skill: IEditableSkill;
  onSave: (skill: ISkill) => void;
  onDelete: (nulign: number) => void;
}

const EditableSkillCard: FunctionComponent<EditableSkillCardProps> = ({ skill, onSave, onDelete }) => {
  const [localState, setState] = useState<IEditableSkill>(skill);
  const [hovering, setHovering] = useState(false);

  const saveSkill = () => {
    const newSkill: ISkill = { nulign: localState.nulign, code: localState.code, label: localState.label, level: localState.level };
    onSave(newSkill);
  };

  if (localState.editing) {
    return (
      <div className="edit-skill-card">
        <div className="flex-row-space-between">
          <div className="edit-skill-card-section">
            <p className="text-medium-size text-standard text-bold">
              <FormattedMessage id="label.name" />
            </p>
            <SelectSource value={localState.code} reg={"PAM"} dir={"U25"} onChange={e => setState({ ...localState, code: e })} />
            {/* <TextField type="text" value={localState.label} onChange={e => setState({ ...localState, label: e })} /> */}
          </div>

          <div className="edit-action">
            <ButtonDefault onClick={saveSkill} text="action.save" color="primary" size="super-small" />
            <i className="fas fa-trash cursor-pointer" onClick={() => (localState.nulign ? onDelete(localState.nulign) : null)} />
          </div>
        </div>

        <div className="edit-skill-card-section">
          <p className="text-medium-size text-standard text-bold">
            <FormattedMessage id="label.level" />
          </p>
          {/* TODO: Check logic of numeric/string editing of the level field */}
          <SelectSource value={localState.level} reg={"PAM"} dir={"U26"} onChange={e => setState({ ...localState, level: e })} />
          {/* <TextField type="number" min={0} max={5} value={localState.level} onChange={e => setState({ ...localState, level: e })} /> */}
        </div>
      </div>
    );
  }

  return (
    <div className="edit-skill-card" onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <div className="flex-row-space-between">
        <div className="edit-skill-card-section">
          <p className="text-medium-size text-standard text-bold">
            <FormattedMessage id="label.name" />
          </p>
          <p className="text-medium-size text-standard">{skill.label}</p>
        </div>

        <div className="edit-action">
          {hovering && <i className="fas fa-pen cursor-pointer" onClick={() => setState({ ...localState, editing: true })} />}
          {hovering && <i className="fas fa-trash cursor-pointer" onClick={() => (localState.nulign ? onDelete(localState.nulign) : null)} />}
        </div>
      </div>

      <div className="edit-skill-card-section">
        <p className="text-medium-size text-standard text-bold">
          <FormattedMessage id="label.level" />
        </p>
        <p className="text-medium-size text-standard">{skill.levelLabel}</p>
      </div>
    </div>
  );
};

export default EditableSkillCard;
