import ILoggingUser from "../models/ILoggingUser";

const fakeLoggingUser: ILoggingUser = {
  nudoss: 1234,
  label: "Pepe Mel",
  language: "es",
  languageIso: "es",
  userID: "PEPE",
  roles: [
    { canonical: "EMPLOYEE", category: "EMPLOYEE", template: "EMPLOYEE" }
  ]
};

export default fakeLoggingUser;
