import classNames from "classnames";
import React, { FunctionComponent, ReactElement, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { WindowSizeContext } from "../../context";
import useConfig from "../../custom-hooks/useConfig";
import useSecurity from "../../custom-hooks/useSecurity";
import LoginButton from "../button/LoginButton";
import LogoutButton from "../button/LogoutButton";
import MyProfileButton from "../button/MyProfileButton";
import FloatingMenu from "../floating-menu/FloatingMenu";
import FloatingMenuItem from "../floating-menu/FloatingMenuItem";
import UserImage from "../ProfileCard/UserImage";
import SwitchLanguage from "../switch-language/SwitchLanguage";
import "./userCard.scss";

interface UserCardProps {
  nudoss?: string | number;
  label?: string;
}

const UserCard: FunctionComponent<UserCardProps> = ({ nudoss, label }): ReactElement => {
  const { isAuth } = useSecurity();
  const [isOpen, setOpen] = useState(false);
  const { constants } = useConfig();
  const { isMobile } = useContext(WindowSizeContext);

  const onClick = () => {
    setOpen(previous => !previous);
  };

  return (
    <React.Fragment>
      <div className={classNames("user-card",
        "standard-button-menu", "user-card-toolbar", "cursor-pointer", { "user-card-opened": isOpen })} onClick={() => onClick()}>
        <UserImage nudoss={isAuth ? nudoss : ""} size="mini" borderShape="cylindrical" />
        {!isMobile && <div className="user-header-description">{isAuth ? label : <FormattedMessage id="label.guest" />}</div>}
        <i className="fas fa-sort-down text-white" />
      </div>
      <FloatingMenu showing={isOpen} black onClose={() => onClick()}>

        {isAuth && isMobile ? (
          <FloatingMenuItem whiteText preventClose>
            <p>{label}</p>
          </FloatingMenuItem>
        ) : (
          <React.Fragment />
        )}

        {isAuth ? (
          <FloatingMenuItem whiteText preventClose>
            <MyProfileButton withText />
          </FloatingMenuItem>
        ) : (
          <React.Fragment />
        )}

        {constants.isLanguageSelectorEnabled ?
          <FloatingMenuItem whiteText preventClose>
            <SwitchLanguage withText />
          </FloatingMenuItem>
          :
          <React.Fragment />
        }


        <FloatingMenuItem whiteText preventClose>
          {isAuth ? <LogoutButton withText /> : <LoginButton withText />}
        </FloatingMenuItem>
      </FloatingMenu>
    </React.Fragment>
  );
};

export default UserCard;
