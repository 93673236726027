import axios from "axios";
import constants from '../../constants';
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";

const addUserInterest = async (nudoss: string | number, data: Object): Promise<Object> => {
  const res = await axios.post(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/interests?role=${userRole()}`, data, { headers: authHeaders() });
  return res.data;
};

export default addUserInterest;
