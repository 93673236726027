import React, { CSSProperties, FunctionComponent, ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import ButtonDefault from "../button/ButtonDefault";
import Modal from "../modal/Modal";
import { showModal } from "../modal/ModalListener";
import './FootNote.scss';

interface FootNoteProps {
    email?: string,
    style?: CSSProperties
}


const modal = <Modal style={{ paddingRight: 0, overflow: "hidden", height: "100%" }}>
    <iframe id="myiFrame" className="iframeclass" src="https://www.socialpampanorama.com/helpcenter/portale-lavora-con-noi.html#Candidati" style={{ width: "100%", height: "100%", border: 0 }} />
</Modal>

//TODO: ADD MORE FUNCTIONALITY AND REFACTOR 
const FootNote: FunctionComponent<FootNoteProps> = ({ email, style }): ReactElement => {
    return (
        <div className="footer" style={style}>
            <p>© 2021 Gruppo PAM S.p.A - P.IVA 01534730278</p>
            <ButtonDefault color="none" size="small" text="Contattaci"
                // onClick={(e) => { window.open(`mailto:${email ? email : "no-reply@example.com"}`, "_blank") }} 
                onClick={(e) => showModal(modal)}
                style={{ height: "60%", minHeight: "0", backgroundColor: "var(--colour-primary)", color: "white", margin: "0 0.5rem 0" }} />
            <p><FormattedMessage id="label.all-rights-reserved" /></p>
        </div>
    )
}

export default FootNote;