/* eslint-disable no-use-before-define */
import React, { FunctionComponent, ReactElement, useEffect, useRef, useState } from "react";
import IInterest from "../../models/IInterest";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import _, { isEmpty } from "lodash";

interface MultipleAutoCompleteProps {
  name?: string;
  value: IInterest[];
  onUpdate: (e: IInterest[]) => void;
  options: IInterest[];
  translated?: boolean;
  withSearch?: boolean;
  outlined?: boolean;
  priority?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 465,
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
    },
  }),
);

const MultipleAutoComplete: FunctionComponent<MultipleAutoCompleteProps> = ({ onUpdate, options, value, priority }): ReactElement => {

  const classes = useStyles();

  const onChangeTags = (event: any, data: any) => {
    onUpdate(data);
  }

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="tags-standard"
        options={options}
        getOptionLabel={(option) => option.areaLabel}
        defaultValue={value}
        value={value}
        onChange={onChangeTags}
        freeSolo={value.length > 2 ? false : true}
        getOptionDisabled={() => (value.length > 2 ? true : false)}
        limitTags={3}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Aree di interesse"
          />
        )}
      />
    </div>
  );
}
export default MultipleAutoComplete;

