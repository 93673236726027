import React, { ReactElement, FunctionComponent } from "react";
import UserHeader from "./UserHeader";
import UserEducations from "./UserEducations";
import UserExperiences from "./UserExperiences";
import UserSkills from "./UserSkills";
import useConfig from "../../custom-hooks/useConfig";

interface ProfileCardProps {
  nudoss: string | number;
}

const ProfileCard: FunctionComponent<ProfileCardProps> = ({ nudoss }): ReactElement => {
  const { constants } = useConfig();
  return (
    <div className="custom-card">
      <UserHeader nudoss={nudoss} />
      <div className="profile-card-info-container">
        <div className="profile-card-data-container">
          <UserExperiences nudoss={nudoss}/>
        </div>

        <div className="profile-card-data-container">
          <UserEducations nudoss={nudoss}/>
        </div>

        { constants.employeeSkillsModuleEnabled && 
        <div className="profile-card-data-container">
          <UserSkills nudoss={nudoss}/>
        </div>}
      </div>
    </div>
  );
};

export default ProfileCard;
