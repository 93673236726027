import React, { FunctionComponent, ReactElement } from "react";
import {
LanguageProvider,
AuthContextProvider,
ConfigContextProvider,
UserContextProvider,
WindowSizeContextProvider,
} from './index';
import { ConstantsConfig } from "../models/ConstantsConfig";


interface ProvidersProps {
  constants: ConstantsConfig;
}

const Providers: FunctionComponent<ProvidersProps> = ({ children, constants }): ReactElement => {
  return (
    <ConfigContextProvider constants={constants}>
      <LanguageProvider>
          <AuthContextProvider>
            <UserContextProvider>
              <WindowSizeContextProvider>
                {children}
              </WindowSizeContextProvider>
            </UserContextProvider>
          </AuthContextProvider>
      </LanguageProvider>
    </ConfigContextProvider>
  );
};

export default Providers;