import axios from "axios";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import authHeaders from "../auth/authHeaders";

const deactivateUser = async (constants: ConstantsConfig, nudoss: string | number): Promise<boolean> => {
  if (constants.useMockData) return true;
  const res = await axios.delete(`${constants.REACT_APP_WS_URL}/hr-talent/employees/${nudoss}`, { headers: authHeaders() });
  return res.status === 200;
};

export default deactivateUser;
