
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AuthContext } from "../../context";
import useConfig from "../../custom-hooks/useConfig";
import IUser from "../../models/IUser";
import employeeService from "../../services/employees/";
import toastService from "../../services/toasts";
import { formatDateToStandard } from "../../utils/time/time";
import ButtonDefault from "../button/ButtonDefault";
import TextCheckBox from "../input/TextCheckBox";
import Subtitle from "../titles/Subtitle";


interface EditUserDataProps {
    nudoss: string | number | undefined;
}

interface EditUserDataState {
    loading: boolean;
    user: IUser;
    birthDate: string;
}

const PrivacyPolicyEditSection: FunctionComponent<EditUserDataProps> = ({ nudoss }) => {

    const [privacy, setPrivacy] = useState(false);
    const [consent, setConsent] = useState(false);
    const { setAuthContextState } = useContext(AuthContext);
    const { constants } = useConfig();
    const history = useHistory();

    const [state, setState] = useState<EditUserDataState>({
        loading: false,
        user: { nudoss: nudoss, matcle: "", firstName: "", surname: "", domProvDescription: "", domProv: "", domCity: "", resProvDescription: "", resProv: "", resCity: "", dateOfBirth: 0, gender: "", email: "", location: "", phone: "", description: "", firstJob: true, userPrivacy: { acceptanceDate: "", accepted: false, protectedCategoryIsAccepted: false, application: "" } },
        birthDate: "",
    });
    useEffect(() => {
        setState({ ...state, loading: true });
        if (nudoss) {
            employeeService
                .getProfileInformation(constants, nudoss)
                .then((u: IUser) => {
                    setState({ ...state, loading: false, user: u, birthDate: formatDateToStandard(u.dateOfBirth) });
                })
                .catch(err => {
                    console.log(err);
                    setState({ ...state, loading: false });
                });
        }
    }, [nudoss]);

    const updateUserData = () => {
        const newUser = { ...state.user };
        newUser.userPrivacy.accepted = privacy;
        newUser.userPrivacy.protectedCategoryIsAccepted = consent;
        if (state.user && nudoss) {
            employeeService
                .updateUserProfile(constants, nudoss, newUser)
                .then(res => {
                    console.log("updateUserData res", res);
                    toastService.throwSuccess();
                    setAuthContextState?.({ checked: true, isAuth: true });
                    setTimeout(() => {
                        history.push("/my-profile-edit")
                    }, 2000);
                })
                .catch(err => {
                    toastService.throwError(err.response.data.description);
                    console.log("updateUserData err", err);
                });
        }
    };



    return (
        <React.Fragment>
            <div className="first-subsection">
                <Subtitle label="label.privacy-policy" />
            </div>
            <div style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-around",
                flexDirection: "column",
                width: "70%",
                border: "2px solid black",
                padding: "1rem",
                borderRadius: "0.3rem",
                height: "7rem",
            }}>
                <div className="flex-row-space-between ">
                    <div>
                        <div className="margin-bottom">
                            <TextCheckBox label={"note.privacy-policy"} onClick={() => setPrivacy(prevState => !prevState)} readOnly={state.user.userPrivacy.accepted} value={state.user.userPrivacy.accepted} />
                        </div>
                        <div className="margin-bottom">
                            <TextCheckBox label={"note.second-privacy-policy"} onClick={() => setConsent(prevState => !prevState)} readOnly={state.user.userPrivacy.protectedCategoryIsAccepted} value={state.user.userPrivacy.protectedCategoryIsAccepted} />
                        </div>
                    </div>
                    <div className="padding-top-big">
                        <ButtonDefault text={"action.applicare"} onClick={updateUserData} color={"primary"} size="standard" disabled={!privacy} />

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PrivacyPolicyEditSection;
