import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Loading from "../loading/Loading";
import IJob from "../../models/IJob";
import JobListItem from "../JobListItem/JobListItem";
import useConfig from "../../custom-hooks/useConfig";
import useUser from "../../custom-hooks/useUser";
import staffService from "../../services/staff";

interface MySubmissionsJobListState {
  loading: boolean;
  submissions: IJob[];
}

const MySubmissionsJobList = () => {
  const { constants } = useConfig();
  const { user } = useUser();
  const [state, setState] = useState<MySubmissionsJobListState>({ loading: false, submissions: [] });

  useEffect(() => {
    if (user) {
      setState({ ...state, loading: true });
      staffService
        .getStaffPositionApplies(constants, user.nudoss)
        .then(jobs => {
          setState({ loading: false, submissions: jobs });
        })
        .catch(err => {
          setState({ ...state, loading: false });
        });
    }
  }, []);

  return (
    <div className="standard-small-container">
      <div className="joblist-title">
        <FormattedMessage id="label.my-job-requests" />
      </div>
      <div className="joblist-container">{state.loading ? <Loading /> : state.submissions.map((j: IJob) => <JobListItem {...j} />)}</div>
    </div>
  );
};

export default MySubmissionsJobList;
