import axios from "axios";
import { ConstantsConfig } from "../../models/ConstantsConfig";
import IEducation from "../../models/IEducation";
import authHeaders from "../auth/authHeaders";
import userRole from "../auth/userRole";

const updateEducation = async (constants: ConstantsConfig, nudoss: string | number, nulign: string | number, data: IEducation): Promise<IEducation> => {
  if (constants.useMockData) return data;
  const res = await axios.put(`${constants.REACT_APP_WS_URL}/hr-talent/staff/${nudoss}/educations/${nulign}?role=${userRole()}`, data, { headers: authHeaders() });
  return res.data;
};

export default updateEducation;
