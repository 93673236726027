import sessionKeys from "./sessionKeys";
import deleteAllSession from "./deleteAllSession";
import deleteFromSession from "./deleteFromSession";
import getFromSession from "./getFromSession";
import saveToSession from "./saveToSession";
import deleteAllLocal from "./deleteAllLocal";
import deleteFromLocal from "./deleteFromLocal";
import getFromLocal from "./getFromLocal";
import saveToLocal from "./saveToLocal";

const storageService = {
  sessionKeys,
  deleteAllSession,
  deleteFromSession,
  getFromSession,
  saveToSession,
  deleteAllLocal,
  deleteFromLocal,
  getFromLocal,
  saveToLocal
};

export default storageService;
