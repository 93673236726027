import axios from "axios";
import constants from '../../constants';
import ILocations from "../../models/ILocations";
import authHeaders from "../auth/authHeaders";

const getProvinces = async (): Promise<ILocations[]> => {

  const res = await axios.get(`${constants.REACT_APP_WS_URL}/hr-talent/rules/itProvinces`, { headers: authHeaders(true) });
  return res.data;
};

export default getProvinces;
