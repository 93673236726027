import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import TOPICS from "../../services/topics";
import Backdrop from './Backdrop';

interface BackdropListenerProps {
  children: ReactNode,
}

const BackdropListener = ({ children }: BackdropListenerProps) => {
  const [showing, setShowing] = useState(false);
  useEffect(() => {
    const subscription2 = TOPICS.BACKDROP_TOPIC.subscribe((payload: any) => {
      setShowing(payload);
    });
    return () => {
      subscription2.unsubscribe();
    };
  }, []);

  const onBackdropClick = () => {
    TOPICS.MODAL_TOPIC_CLOSE.next(false);
    TOPICS.SIDEBAR_TOPIC.next(false);
  };

  return (
    <Fragment>
      <Backdrop showing={showing} onClick={onBackdropClick}>
        {children}
      </Backdrop>
    </Fragment>
  );
};

export default BackdropListener;
