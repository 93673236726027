import React, { Fragment, FunctionComponent, ReactElement, ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Alert from "../components/Alert/Alert";
import ButtonDefault from "../components/button/ButtonDefault";
import Icon from "../components/icon/Icon";
import MainLayout from "../components/layout/MainLayout";
import EditUserData from "../components/ProfileCard/EditUserData";
import MainTitle from "../components/titles/MainTitle";
import EducationsEditSection from "../components/user-edit-sections/EducationsEditSection";
import ExperiencesEditSection from "../components/user-edit-sections/ExperiencesEditSection";
import ReadOnlyPrivacyPolicy from "../components/user-edit-sections/ReadOnlyPrivacyPolicy";
import SkillsEditSection from "../components/user-edit-sections/SkillsEditSection";
import useConfig from "../custom-hooks/useConfig";
import useUser from "../custom-hooks/useUser";
import IUser from "../models/IUser";
import getProfileCompleted, { CompletedProfileData } from "../services/employees/getProfileCompleted";
import throwError from "../services/toasts/throwError";
import throwSuccess from "../services/toasts/throwSuccess";
import "./profile-edit-page.scss";
interface ProfileEditPageState {
  loading: boolean;
  profileCompleted: boolean;
  alertContent: ReactNode;
  updated: boolean;
  ref: string;
  // userData: IUser;
}

const ProfileEditPage: FunctionComponent = (): ReactElement => {
  const { user } = useUser();
  const history = useHistory();
  const { constants } = useConfig();
  const [state, setState] = useState<ProfileEditPageState>({
    loading: false,
    profileCompleted: false,
    alertContent: <Fragment />,
    updated: false,
    ref: '',
    // userData: 
  });
  const [userData, setUserData] = useState({});
  const [ref, setRef] = useState({ current: null });
  const [experiences, setExperiences] = useState([]);

  const validateProfile = (redirect?: string) => {
    if (user) {
      getProfileCompleted(constants, user?.nudoss)
        .then((data: CompletedProfileData) => {
          const areas: ReactElement[] = [];
          data.areaStatus?.forEach(a => {
            areas.push(<div className="flex-row-start">
              {!a.complete && <Icon name="exclamation-circle" style={{ color: 'red' }} />}
              {a.complete && <Icon name="check-circle" style={{ color: 'green' }} />}
              <p>{a.description}</p>
            </div>)
          })
          if (data.complete) {
            setState({ ...state, profileCompleted: true, alertContent: areas, updated: false });
            if (redirect) {
              throwSuccess("profilo.completo");
              setTimeout(() => {
                history.push(`/${redirect}`);
              }, 1000);
            }
          }
          else {
            setState({ ...state, profileCompleted: false, alertContent: areas, updated: false });
            if (redirect) throwError("profilo.incompleto");
          }
          executeScroll();
        })
        .catch((err) => throwError(err));
    }
  }

  const scrollToTop = (ref: any) => {
    if (ref) { ref.scrollTop = 0 };
  }

  const executeScroll = () => {

    if (ref && ref.current) scrollToTop(ref.current);
  }

  useEffect(() => {
    validateProfile();
  }, [user, state.updated === true]);

  const getUpdated = (updated: boolean) => {
    setState({ ...state, updated });
  }

  const getUserData = (userData: IUser) => {
    setUserData(userData);
  };

  const getRef = (ref: any) => {
    setRef(ref);
  }

  const getExperiences = (experiences: any) => {
    setExperiences(experiences);
  }

  return (
    <div>
      <MainLayout usesRefToScroll withCustomBackground reference={getRef}>
        <Alert type={state.profileCompleted ? "success" : "danger"} info={state.profileCompleted ? { title: "Profilo completo" } : { title: "Completa il tuo profilo per candidarti" }}>
          {state.alertContent}
        </Alert>
        <div style={{ position: 'relative' }}>
          {user && <ButtonDefault color="primary" size="standard" text="action.validateprofilo" onClick={() => validateProfile("feed")} className='button-validate' disabled={!state.profileCompleted}
          />}
        </div>
        <div className="cards-container flex-column-space-between align-center">
          {user && (
            <div className="standard-card padding-top padding-bottom full-height">
              <MainTitle label="label.edit-profile" />
              <EditUserData nudoss={user?.nudoss} updated={getUpdated} userData={(e: any) => getUserData(e)} experiences={experiences} />
            </div>
          )}

          <ExperiencesEditSection nudoss={user?.nudoss} updated={getUpdated} userData={userData} experiences={getExperiences} />
          {constants.employeeSkillsModuleEnabled && <SkillsEditSection nudoss={user?.nudoss} updated={getUpdated} userData={userData} />}
          <EducationsEditSection nudoss={user?.nudoss} updated={getUpdated} userData={userData} />
          <ReadOnlyPrivacyPolicy nudoss={user?.nudoss} />

        </div>
      </MainLayout>
    </div>
  );
};

export default ProfileEditPage;
