import VIEW_NAMES from "./viewNames";
import isMobileView from "./isMobileView";
import isTabletView from "./isTabletView";

const getCurrentView = (width: number): string => {
  if (isMobileView(width)) return VIEW_NAMES.MOBILE;
  if (isTabletView(width)) return VIEW_NAMES.TABLET;
  return VIEW_NAMES.DESKTOP;
};

export default getCurrentView;
