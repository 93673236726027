import React, { FunctionComponent, ReactElement } from "react";
import { FormattedMessage } from "react-intl";

interface SubtitleProps {
  label: string;
  mandatory?: boolean;
  withoutMargin?: boolean;
}

const Subtitle: FunctionComponent<SubtitleProps> = ({ label, mandatory, withoutMargin, }): ReactElement => {
  return (
    <p className="text-standard text-bold text-medium-size ">
      <FormattedMessage id={label} />{mandatory && '*'}
    </p>
  )
};

Subtitle.defaultProps = {
  mandatory: false
}

export default Subtitle;
