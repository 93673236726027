import getPositionApplicants from "./getPositionApplicants";
import getPositionDetails from "./getPositionDetails";
import getPositionLogo from "./getPositionLogo";
import getPositions from "./getPositions";
import getPositionSkills from "./getPositionSkills";
import getRelatedPositions from "./getRelatedPositions";
import savePosition from "./savePosition";

const positionService = {
  getPositions,
  getPositionApplicants,
  getPositionDetails,
  getPositionLogo,
  getPositionSkills,
  getRelatedPositions,
  savePosition
};

export default positionService;
