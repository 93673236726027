import React, { ReactElement, FunctionComponent } from "react";
import getStaffCV from "../../services/staff-document/getStaffCV";
import useConfig from "../../custom-hooks/useConfig";
import Icon from "../icon/Icon";

interface DownloadCVBtnProps {
  nudoss: string | number;
}

const DownloadCVBtn: FunctionComponent<DownloadCVBtnProps> = ({ nudoss }): ReactElement => {
  const { constants } = useConfig();
  const getCV = () => {
    getStaffCV(constants, nudoss).then(res => console.log(res));
  };

  return (
    <div>
      <Icon big name="file-download" onClick={() => getCV()} />
    </div>
  );
};

export default DownloadCVBtn;
